// Styles used for the Alternate Homepage

#video img {
	width:auto;
	border:none;
}

#generator.homepage-generator {
	background: url(/images/signup/signup-background.jpg) no-repeat;
	background-size: cover;
	font-family: $sans-serif;
	h1 {
		color:#fff;
	}
	p {
		color:#fff;
		a {
			color:#fff;
			text-decoration:underline;
			}
	}
	.extra-options {
		background:#F6F6F6;
		border-radius:4px;
		margin:25px auto;
		padding: 25px;
		p {
			color:#333;
		}
	}
	fieldset, .fieldset {
		width: 100%;
	}
}
h2.subheadline {
	color:#585757;
	text-align: center;
	font-weight: 700;
	font-size: 30px;
	margin-bottom:35px;
}

.headline-underline {
	background: url(/images/signup/underline.svg) no-repeat bottom;
	padding: 0 0 7px;
}

.home-native-apps-container {
	background: #f5f5f5;
	text-align:left;
	.home-native-apps-inner {
		max-width:1000px;
	}
	#mobile-callout {
		padding: 40px 0;
		.mobile-callout-image {
			max-width:400px;
		}
	}
	h2, p, li {
		text-align:left;
	}
	ul {
		text-align:left;
		margin:15px 0;
		padding:10px 0;
		li {
			padding-right:35px;
			display:inline-block;
		}
		.bullet-icon-marker {
			width:15px;
			border:none;
			outline:none;
			margin-right:5px;
			position:relative;
			top:5px;
		}
	}
	p {
		text-align:left;
		a {
			text-decoration:underline;
		}
	}
}


a.play-video-btn {
	display:inline-block;
	background: #FF8700;
	border-radius: 4px;
	font-size: 16px;
	color: #FFFFFF;
	padding:8px 15px;
	margin-left:5px;
	position:relative;
	top:-2px;

	.play-video-btn-icon {
		width:12px;
		margin-right:4px;
	}
}

/*Reusable Logo Footer*/
.logo-footer {
	background: #444343;
	color: #fff;
	padding: 50px;

	p {
		margin: 0;
		text-align: center;
		font-weight: bold;
		font-size: 1.8em;
	}
	h2 {
		color:#fff;
		text-align:center;
		font-weight:bold;
		font-size:1.8em;
		max-width:1000px;
		margin:0 auto;
	}

	.signup-footer-logos-list {
		max-width:1000px;
		margin:30px auto;
		@include flexbox;
		@include justify-content(space-between);
		@include align-items(center);
		list-style: none;
	}

	.signup-footer-logos-list-item {
		padding: 0 10px;
	}
}


@media screen and (max-width: 767px) {

	.logo-footer .signup-footer-logos-list {
		display:inline-block;
		text-align:center;
	}
	.logo-footer .signup-footer-logos-list-item {
		display:inline-block;
		margin-bottom:25px;
	}

}
