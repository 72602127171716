@import "../src/batchgeo-search";
@import "../src/admin-map-table-loader";
@import "../src/admin-map-table-header";

.admin-map-table-title-wrap {
  font-size: inherit !important;
}

.admin-map-table-title-wrap-inner {
  @include flexbox;
  @include align-items(center);
}

.admin-map-table-wrap {
  margin-top: 10px;
  overflow: scroll;
  position: relative;
  -webkit-overflow-scrolling: touch;
}

[data-sort-key] {
  cursor: pointer;
}

.admin-map-table {
  .even td {
    background: #eee;
  }
  .odd td {
    background: #fff;
  }
  .toolbar-icon-archive:before {
    font-size: 1.6rem;
    color: #666666;
    position: relative;
    top: 6px;
    left: 5px;
  }
}

.admin-map-table-search {
  @include flex-basis(280px);
}

.admin-map-table-sort-arrow {
  font-size: 10px;
  position: absolute;
  right: 0px;
  padding-left: 5px;
}

.admin-map-table-no-results {
  text-align: center;
  padding: 15px 0;
  color: #616161;
  border: 1px solid #ddd;
  border-top: 0;
  font-style: italic;
  font-size: 14px;
}

.admin-map-bulk-actions-outer-wrapper {
  @include flexbox;
  @include flex-grow(1);
  @include justify-content(space-between);
  margin: 0 15px 0 0;
}

.admin-map-showing-dropdown-wrapper {
  @include media("screen", "<=tablet") {
    margin-top: -22px;
    margin-left: 15px;
  }
  label {
    font-weight: bold;
  }
}

.admin-map-bulk-checkbox {
  margin-right: 7px;
}

@media only screen and (max-width: 930px) {
  .admin-map-table-header-wrapper {
    @include flex-direction(column);
  }
  .admin-map-table-search {
    @include flex-basis(0);
  }

  .admin-map-bulk-actions-outer-wrapper {
    @include flexbox;
    align-items: flex-end;
    margin-bottom: 20px;
  }
}
