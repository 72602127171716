/******************************************************************
BatchGeo

Stylesheet: Buttons

TODO: Get rid of all textured button looks, convert to flat - commenting out gradients seems to break iOS Safari
	 $button-color - doesn't actually get set?
******************************************************************/

.button, .button:visited {
  font-family: $sans-serif;
  /*
  border: 1px solid darken($button-color, 13%);
  border-top-color: darken($button-color, 7%);
  border-left-color: darken($button-color, 7%);
  */
  @include border-radius(3em);
  cursor: pointer;
  display: inline-block;
  font-size: 1.25rem;
  font-weight: bold;
  line-height: 1;
  margin-bottom: 20px;
  padding: 18px 32px;
	text-decoration: none;
	transition: all 0.2s ease-in-out;
  //text-shadow: 0 1px darken($button-color, 10%);
  //@include css-gradient($button-color, darken($button-color, 5%));
  zoom: 1; /* zoom and *display = ie7 hack for display:inline-block */

  /*
  &:hover, &:focus {
    // color: $black;
      border: 1px solid darken($button-color, 13%);
      border-top-color: darken($button-color, 20%);
    border-left-color: darken($button-color, 20%);
    @include css-gradient(darken($button-color, 5%), darken($button-color, 10%));
  }
  */

  /*
  &:active {
    @include css-gradient(darken($button-color, 5%), $button-color);
  }
  */
}
@include media ("screen", "<phone") {
	.button {
		font-size: 1rem;
	}
}
input.button, 
button.button {
	appearance: none;
  -moz-appearance: none;
	-webkit-appearance: none;
}

button.button:focus {
	outline: #000 auto 1px;
	outline: -webkit-focus-ring-color auto 1px;
}

.button-cta, .button-cta:visited {
  border-color: darken($button-cta, 5%);
  color: #fff;
  text-shadow: 0 1px 1px darken($button-cta, 10%);
  @include css-gradient( $button-cta, darken($button-cta, 5%) );

  &:hover, &:focus {
    border-color: darken($button-cta, 15%);
    color: #fff;
    @include css-gradient( $button-cta, darken($button-cta, 10%) );
  }
  &:active {
    @include css-gradient( darken($button-cta, 5%), $button-cta );
  }
}

.buttonsmall {
	font-size: 16px;
  padding: 17px 26px;
}

.buttonmedium {
	font-size: 14px;
	padding: 1em;
}
.get_started_button {
  font-size: 22px;
  margin-bottom: 1em;
  padding: .6em 1em;
  width: 100%;
}
.get_started_button em {
  font-size: 14px;
  font-style: normal;
}


/* social buttons */

#socialButtons {
	width:400px;
	margin:auto;
	clear: both;
}

#facebookButton {
	border:none;
	overflow:hidden;
	width:400px;
	height:25px;
}

#twitterButton {
	height:25px;
	float:right;
	padding-top:4px;
}

#mapDirections {
	text-align: center;
	padding: 0 4px;
}

.adp-directions {
	margin: auto;
}

#mobile-map {
	margin: 2px 0 6px 10px;
}
.clear {
	clear:both;
	margin-top:-1px;
	height:1px;
	overflow:hidden;
}
.directions-link {
	font-size: 12px;
}
a.directions-link {
	text-decoration: none;
	color: #000;
	font-size: 12px;
}
.postmetadata {
	font-size: 11px;
}


/* red */
.red {
	color: #fff;
	border: 1px solid #980c10;
	background: #d81b21;
}
.red:hover {
	background: #bf171d;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
}
.red:active {
	color: #de898c;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
}

/* green */
.green {
	color: #fff;
	border: 1px solid $green;
	background: $green;
}
.green:hover {
	background: #096f36;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
}
.green:active {
	color: #a9c08c;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
}


/* white */
.button.white, 
.button.white:visited {
	color: $color-primary;
	border: 1px solid $color-primary;
	background: $white;
	@include transition(all 0.2s ease-in-out)
}
.button.white:hover {
	color: $color-primary;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
}

/* green */
.button.green:first-child, 
.button.green:visited {
	color: $white;
	background: $green;
	border: 1px solid $green;
	box-shadow: inset 0px -3px 0px #096f36;
	@include transition(all 0.2s ease-in-out)
}
.button.green:hover {
	color: $white;
	background: $green;
	box-shadow: 
		inset 0px -3px 0px #096f36, 
	  0px 4px 4px rgba(0, 0, 0, 0.15);
}

/* blue */
.button.blue, 
.button.blue:visited {
	color: $white;
	background: $color-primary;
	border: 1px solid $color-primary;
	box-shadow: inset 0px -3px 0px #022a3a;
	@include transition(all 0.2s ease-in-out)
}
.button.blue:hover {
	color: $white;
	background: $color-primary-dark;
	box-shadow: 
		inset 0px -3px 0px #012635, 
	  0px 4px 4px rgba(0, 0, 0, 0.15);
}

/* gold */
.button.gold, 
.button.gold:visited {
	color: $black;
	background: $gold;
	border: 1px solid $gold;
	box-shadow: inset 0px -3px 0px $gold-dark;
	@include transition(all 0.2s ease-in-out)
}
.button.gold:hover {
	color: $black;
	background: $gold-dark;
	box-shadow: 
		inset 0px -3px 0px #b18a01, 
		0px 4px 4px rgba(0, 0, 0, 0.1);
}

.disabled{
	background: #eeeeee;
	border: 1px solid #eeeeee;
	color: #555;
}
.disabled:hover{
	background: #eeeeee;
	border: 1px solid #eeeeee;
	color: #555;
	box-shadow: none;
}


.button.wide {
	min-width: 200px;
}

@include media ("screen", "<phone") {
	.button-wrap-mobile {
		white-space: initial;
		line-height: 1.2;
		padding-top: 12px;
		padding-bottom: 12px;
	}
}
