.batchgeo-inline-notification {
  border-radius: 3px;
  color: #fff;
  font-size: 14px;
  display: flex;

  &.batchgeo-inline-notification-info {
    background: #EF7700;
  }
  &.batchgeo-inline-notification-success {
    background: #37B900;
  }
  &.batchgeo-inline-notification-error {
    background: #EF5C50;
  }

  .batchgeo-inline-notification-icon {
    padding: 5px 8px;
    border-right: 1px solid white;
  }

  .batchgeo-inline-notification-message {
    padding: 5px 8px;
  }
}