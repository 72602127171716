/******************************************************************

BatchGeo

Stylesheet: Captions

******************************************************************/

.aligncenter,
div.aligncenter {
	display: block;
	margin-left: auto;
	margin-right: auto;
}

.wp-caption {
	border: 1px solid #ddd;
	text-align: center;
	background-color: #f6f6f6;
	padding-top: 4px;
	margin-bottom: 35px;
    @include border-radius(3px);
}

.wp-caption img {
	margin: 0;
	padding: 0;
	border: 0 none;
}

.wp-caption p.wp-caption-text {
	font-size: 12px;
	line-height: 17px;
	padding: 0 4px 5px;
	margin: 0;
}