/******************************************************************
BatchGeo
Stylesheet: Site Meta
******************************************************************/

.all-content-wrap {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
}
.main-content-wrap {
	flex: 1 0 auto;
}
#footer {
	flex-shrink: 0;
}

.wrap {
	width: 90%;
	margin: 0 auto;
}

.row {
	margin: 45px 0 0;
}
.column {
	margin-left: 20px;
	float: left;
}
.column.left {
	width: 620px;
}
.column.right {
	width: 290px;
}

.content:after, .row:after {
	visibility: hidden;
	display: block;
	font-size: 0;
	content: " ";
	clear: both;
	height: 0;
}

h1.home span {
	color: #369;
}
h1.home em {
	font-style: normal;
	font-weight: bold;
	color: #666;
}
h1.home b {
	font-size: 12px;
	color: #999;
}
h2.home {
	font-size: 16px;
	margin: 2px 20px 0 0;
	width: 500px;
}

/******************************************************************
Footer Styles
******************************************************************/
#footer {
	margin: 4em 0 0;

	a {
		display: block;
		color: #fff;
		text-decoration: none;

		&:hover {
			border: none;
			color: #ddd;
		}
	}
}
.flush-with-footer + #footer {
	margin-top: 0;
}
.footer-main {
	color: #eee;
	font-size: .75em;
	padding: 2rem 0;
	/* background-color: $color-primary;
	background-image:	url(/images/step-by-step-bg.svg);
	background-repeat: no-repeat;
	background-position: center center;
	background-blend-mode: multiply; */
	background: url(/images/blue-map-bg.svg) no-repeat center center;
	background-size: cover;


	h2 {
		font-size: 1.25em;
		margin-bottom: 1em;
		text-align: left;
	}

	li {
		list-style-type: none;
	}

	p {
		line-height: 32px;
	}

	.nav {
		@include media ("screen", "<phone") {
			margin-bottom: 2.5rem;
		}
	}

}
.footer-legal {
	background: $color-primary-dark;
	padding: 1rem;
	font-size: 13px;
	color: #fff;

}
.footer-legal-item {
	display: inline-block;
	margin-right: 2rem;
}
.apple-store-footer-logo {
	width: 152px;
}
