/******************************************************************

BatchGeo

Stylesheet: Generator

******************************************************************/

#map_title,
#map_email,
#map_description {
  width: 350px;
  border: 1px solid #c0c0c0;
}

#inline_save {
  margin: 0 0 -6px 0;
  padding: 6px 6px 0 6px;
  max-width: 100%;
}
#inline_embed {
  margin: 0;
  padding: 0 10px;
}
#inline_save label {
  float: left;
  text-align: right;
  margin-right: 10px;
  width: 114px;
  padding: 2px 0 0 0;
  display: block;
}
#inline_save label.share {
  float: none;
  margin: 0;
  padding: 0;
  display: inline;
}
#inline_save div.input_desc {
  text-align: left;
}
#inline_save div.input_desc.small {
  font-size: 14px;
}
#inline_save div.input_desc span.small {
  display: block;
  margin: 4px 0 0 124px;
}
#inline_save .line {
  margin: 8px;
}
#inline_save #fields label {
  width: 40%;
}
#inline_save .line .button-cta {
  margin-bottom: 0px;
}
@media only screen and (max-width: 572px) {
  #inline_save label {
    text-align: left;
    width: 100%;
  }
  #inline_save div.input_desc {
    width: 100%;
  }
  #inline_save div.input_desc span.small {
    margin: 4px 4px 4px 0;
    width: 100%;
  }
  #map_title,
  #map_email,
  #map_description {
    width: 100%;
  }
  #inline_save label.share {
    display: inline-block;
    width: 100%;
  }
  #inline_save .inline_logo {
    display: none;
  }
}
.inline_logo {
  position: relative;
}
.inline_logo img {
  position: absolute;
  top: -50px;
  left: 30px;
  width: 60px;
  height: 60px;
}
#inline_message {
  padding: 20px;
  text-align: justify;
}
#validate_status {
  display: none;
  margin-top: 14px;
}
#geocode_button {
  margin: 0 0 0 0;
  position: relative;
  display: grid;
  margin: 0px auto;
}
#geocoding .geocoder_status_wrapper {
  margin-top: 18px;
}
@include media("screen", ">phone") {
  #geocoding .geocoder_status_2 {
    padding-left: 4%;
    padding-right: 4%;
  }
}
#geocoder_status {
  display: none;
  margin: 14px 0 0 0;
}
#dist_sel {
  width: auto;
}
#view_sel {
  width: auto;
}
#mainText {
  margin-top: 10px;
}
#mapFields {
  height: 245px;
}
#locFields,
#mapOptions,
#mapFields,
.validateFields {
  font-size: 12px;
  width: 500px;
  margin: 0 auto;
}
#locFields,
#mapFields,
.validateFields {
  text-align: right;
}
#mapOptions {
  text-align: center;
}
#advanced_button {
  margin: 12px auto 0px auto;
}
#locFields label,
#mapFields label,
.validateFields label {
  display: block;
  float: left;
  width: 40%;
  margin: 4px 0 0 0;
  line-height: 25px;
  clear: both;
}
#locFields select,
#mapFields select,
.validateFields select {
  display: block;
  float: right;
  width: 38%;
  margin: 4px 20% 0 0;
  height: 25px;
  padding: 2px 0 0 0;
}
#mapOptions label {
  display: block;
  margin-bottom: 5px;
}
#mapOptions input {
  margin: 0;
  padding: 0;
}
#locFields + label {
  font-weight: bold;
}
#mapDiv {
  height: 600px;
  margin: 0 auto;
  border-width: 1px;
  border-style: solid;
  border-color: #aaa;
}
#save_map {
  margin-top: 14px;
}
#preview {
  background-color: #fff;
  border: 1px solid #8b8b8b;
  margin: 8px auto;
  padding: 0px;
  width: 352px;
  border-radius: 6px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  max-width: 250px;
}
#markerBoxPreview {
  overflow: hidden;
  .markerLabel {
    width: 100%;
    position: relative;
  }
}
#markerBoxPreview div.markerLabelExtras {
  display: none;
}
#buttonsDiv.fieldset {
  margin-top: 10px;
}
#mapheader {
  margin-bottom: 14px;
}
#dis_buttonsDiv button,
#dis_buttonsDiv input[type="submit"] {
  width: 23%;
  margin: 0 3px;
}
#sourceData,
#destData {
  height: 180px;
}
.mapBorder {
  border: 0px;
}

/* Tableize =================== */

div.tableize {
  position: relative;
  box-sizing: content-box;
  height: 180px;
  overflow: auto;
  overflow-y: hidden;
  overflow-x: hidden;
  margin: 1px;
  padding: 4px;
  border: 1px dashed #8b8b8b;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  background-color: #fff;
  -webkit-box-shadow:
    0 1px 0 #fff,
    inset 0 2px 10px rgba(0, 0, 0, 0.2);
  -moz-box-shadow:
    0 1px 0 #fff,
    inset 0 2px 10px rgba(0, 0, 0, 0.2);
  box-shadow:
    0 1px 0 #fff,
    inset 0 2px 10px rgba(0, 0, 0, 0.2);

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.9) 80%
    );
    z-index: 1;
  }

  em.tableize {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    width: auto;
    white-space: nowrap;
    height: 2em;
    line-height: 2em;
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    font-style: normal;
    color: $black;
    -moz-text-shadow: 0 -1px 0 #fff;
    -webkit-text-shadow: 0 -1px 0 #fff;
    text-shadow: 0 -1px 0 #fff;
    opacity: 1;
    filter: alpha(opacity=1);
    padding-left: 2.5em;
    background: url(/images/icon-copy-paste.svg) no-repeat left center;

    @include media("screen", "<phone") {
      width: 75%;
      white-space: normal;
      height: auto;
      line-height: 1.5;
      padding-top: 2.5rem;
      padding-left: 0;
      transform: translate(-50%, -46%);
      background: url(/images/icon-copy-paste.svg) no-repeat center top;
    }
  }

  table.tableize {
    overflow: auto;
    text-align: left;
    white-space: nowrap;
    width: 100%;
    color: #000;
    border-collapse: collapse;

    tr {
      margin: 0 0 2px 0;
      border-bottom: 1px solid #ddd;
    }

    th,
    td {
      font-size: 13px;
      padding: 4px;
      max-width: 170px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    th {
      font-weight: bold;
      border-bottom: 1px solid #f63;
    }

    td {
      border-right: 1px solid #aaa;
    }

    td:last-child {
      border-right-width: 0;
    }
  }

  textarea {
    color: #000;
  }
}

div.tableize-example {
  table.tableize {
    opacity: 0.3;
    filter: alpha(opacity=33);
  }
}

div.tableize:hover,
div.tableize-drag,
div.tableize-drop {
  margin: 0;
  border: 2px solid #090;

  em.tableize {
    opacity: 1;
    filter: alpha(opacity=100);
  }

  table.tableize {
    opacity: 0.2;
    filter: alpha(opacity=20);
  }
}

div.tableize-unhover {
  margin: 1px !important;
  border: 1px solid #bebebe !important;

  &:after {
    opacity: 0;
  }

  em.tableize {
    display: none !important;
  }

  table.tableize {
    opacity: 1 !important;
    filter: alpha(opacity=100) !important;
  }
}

p.drag-drop-error {
  margin-bottom: 9px;
  color: #c00;
  font-weight: bold;
}

.l {
  color: #369;
}

.small {
  font-size: 12px;
  line-height: 18px;
}

.vS {
  height: 5px;
}

.status {
  font-size: 1rem;
  color: #090;
}

#rmMarker,
#zoom {
  cursor: pointer;
}

#generator #zoom,
#preview #zoom {
  display: none;
}

img.descIMG,
div.tabRow img {
  max-width: 300px;
  border: 0;
  margin: 10px;
}
div.tabRow img {
  float: right;
}

.tabRow {
  cursor: pointer;
}

a.marker:link,
a.marker:visited {
  display: block;
  margin: 2px 0;
  padding: 0;
}

a.goog:link,
a.goog:visited {
  text-decoration: none;
  color: #000;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-size: 14px;
  font-family: $sans-serif;
  box-sizing: border-box;
  border: 1px solid #aaa;
  background: #fff url(/images/icon-select-down-arrow.svg) no-repeat 96.5% 51.5%;
  background-position-x: calc(100% - 0.5rem);
  border-radius: 4px;
  padding: 0.75rem 1.85rem 0.75rem 1rem;
  min-height: 45px;
}
@media screen and (max-width: 600px) {
  select {
    min-height: 36px;
  }
}
select:focus {
  color: $green;
  border-color: $green;
  outline-color: $green;
  box-shadow: 0px 4px 4px 0px rgba(50, 50, 71, 0.2);
}
select.error {
  color: $red;
  border-color: $red;
  background-color: $red-lite;
  background-image: url(/images/icon-select-down-arrow-red.svg);
}
select[disabled] {
  color: $gray;
  border-color: $gray;
  background-color: $gray-lite;
}

.small-select-options select {
  min-height: 25px;
  padding: 0.25rem 1rem;
  padding-right: 2rem;
}
.small-select-options .option-toggle select {
  margin: 2px 0;
}

/* Base for label styling */

.map-options {
  [type="checkbox"]:not(:checked),
  [type="checkbox"]:checked {
    position: absolute;
    left: -9999px;
  }
  [type="checkbox"]:not(:checked) + .checkbox-layout,
  [type="checkbox"]:checked + .checkbox-layout {
    position: relative;
    top: -2px;
    padding-left: 1.95em;
    cursor: pointer;
  }

  /* checkbox aspect */
  [type="checkbox"]:not(:checked) + .checkbox-layout:before,
  [type="checkbox"]:checked + .checkbox-layout:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 1.4em;
    height: 1.4em;
    border: 1px solid #8b8b8b;
    background: #fff;
    border-radius: 4px;
  }
  /* checked mark aspect */
  [type="checkbox"]:not(:checked) + .checkbox-layout:after,
  [type="checkbox"]:checked + .checkbox-layout:after {
    content: "\2713\0020";
    position: absolute;
    top: 0.2em;
    left: 0.22em;
    font-size: 1.3em;
    line-height: 0.8;
    color: $black;
    transition: all 0.2s;
    font-family: $sans-serif;
  }
  /* checked mark aspect changes */
  [type="checkbox"]:not(:checked) + .checkbox-layout:after {
    opacity: 0;
    transform: scale(0);
  }
  [type="checkbox"]:checked + .checkbox-layout:after {
    opacity: 1;
    transform: scale(1);
  }
  /* disabled checkbox */
  [type="checkbox"]:disabled:not(:checked) + .checkbox-layout:before,
  [type="checkbox"]:disabled:checked + .checkbox-layout:before {
    box-shadow: none;
    border-color: #bbb;
    background-color: #ddd;
  }
  [type="checkbox"]:disabled:checked + .checkbox-layout:after {
    color: #999;
  }
  [type="checkbox"]:disabled + .checkbox-layout {
    color: #aaa;
  }
  /* accessibility */
  [type="checkbox"]:checked:focus + .checkbox-layout:before,
  [type="checkbox"]:not(:checked):focus + .checkbox-layout:before {
    border: 1px solid $green;
    color: $green;
    box-shadow: 0px 4px 4px 0px rgba(50, 50, 71, 0.2);
  }

  /* hover style just for information */
  .checkbox-layout:hover:before {
    border: 1px solid $green;
    box-shadow: 0px 4px 4px 0px rgba(50, 50, 71, 0.2);
  }
}

.label-text {
  display: inline-block;
  vertical-align: top;
  width: calc(100% - 2.5rem);
}

.error {
  [type="checkbox"]:checked + .checkbox-layout:before,
  [type="checkbox"]:not(:checked) + .checkbox-layout:before,
  [type="checkbox"]:checked:focus + .checkbox-layout:before,
  [type="checkbox"]:not(:checked):focus + .checkbox-layout:before {
    border-color: $red;
    color: $red;
    background-color: $red-lite;
  }
}

form {
  display: inline;
}

#fields {
  max-width: 810px;
  margin-top: 1rem;
}

.intro {
  margin: 0px auto;
  max-width: 760px;
  padding: 0px;
}

/* Map options =================== */

.map-options {
  max-width: 900px;
  margin: 1rem 0;
  font-size: 14px;
  text-align: left;
  margin: auto;
}

.map-options h3 {
  font-size: 1.25rem;
  font-weight: bold;
}

.map-options-adv-btn {
  max-width: 760px;
  margin: 0px auto;
}

.map-options #preview {
  margin: 0 0 5px 0;
  width: auto;
  @media screen and (min-width: 922px) {
    min-width: 350px;
  }
}

.basic-options {
  justify-content: center;
}

.map-options-row {
  /* margin-right: -20px; */
  justify-content: center;
  margin-bottom: 24px;
  display: flex;

  @media screen and (min-width: 923px) {
    &.basic-options {
      align-items: flex-end;
    }
  }

  @media screen and (max-width: 922px) {
    flex-direction: column;
  }
}

.map-options-1-col .map-options-col {
  width: auto;
}
@media screen and (min-width: 600px) {
  .map-options-2-col .map-options-col,
  .map-options-col {
    width: 360px;
  }
  .map-options-col + .map-options-col {
    width: 400px;
  }
  .map-options-3-col .map-options-col {
    width: 280px;
  }
}
@media screen and (max-width: 599px) {
  #dist_sel {
    margin-top: 2px;
  }
}

@media screen and (min-width: 923px) {
  .map-options-3-col .map-options-col {
    padding-right: 12px;
  }
}

.option-field {
  margin-bottom: 5px;
}

.option-field label {
  display: block;
  padding-top: 4px;
}

.option-field select {
  width: 92%;
}

.option-toggle {
  display: block;
  margin: 0 0 8px 0;
  padding: 2px 0 0 2px;
  line-height: 18px;

  select {
    height: 26px;
    padding-left: 0.35rem;
  }
}

.option-toggle input[type="checkbox"] {
  margin: 0 2px 0 0px;
  padding: 0;
}

.option-toggle select {
  display: inline-block;
  margin: -2px 0;
}

.option-scrollable {
  max-height: 128px;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}
.marker-color-table {
  max-width: 170px;
  border: 1px solid #ddd;
  background: #fff;
}

/* Visible scrollbar in webkit on OSX Lion and above. */
.option-scrollable::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

.option-scrollable::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.option-images {
  margin: 0 -10px -10px 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.option-image {
  float: left;
  position: relative;
  width: 78px;
  height: 58px;
  margin: 0 10px 10px 0;
  border: 1px solid #ddd;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  cursor: pointer;
}
@media screen and (min-width: 923px) {
  .marker-shapes .option-image {
    float: none;
  }
}

.map-options-2-col .option-images {
  margin-right: -12px;
}

.map-options-2-col .option-image {
  width: 89px;
  margin-right: 12px;
}

.option-image-selected {
  border: 1px solid #666;
}

.option-image-selected:after {
  content: "\2714";
  position: absolute;
  right: 7px;
  bottom: 5px;
  color: #333;
  font-size: 14px;
  line-height: 1;
  text-shadow:
    -1px -1px 0 #fff,
    1px -1px 0 #fff,
    -1px 1px 0 #fff,
    1px 1px 0 #fff;
}

.option-upload {
  float: left;
}

.option-upload .bttn {
  position: relative;
  margin-bottom: 7px;
}

.option-upload .text {
  font-size: 10px;
  line-height: 13px;
  color: #999;
  margin-bottom: 0;
}

.option-upload .button {
  font-size: 12px;
  color: #333;
  width: 100%;
  margin: 0;
}

.option-upload-step {
  display: none;
}

.option-upload-step-1 label {
  display: block;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.option-upload-step-1 input[type="file"] {
  position: fixed;
  top: 0px;
  left: -99999px;
}

.option-upload-step-2 .load {
  margin: 20px 0 0 0;
  text-align: center;
}

.option-upload-step-2 .load span {
  display: inline-block;
  height: 22px;
  line-height: 22px;
  padding: 0 14px 0 28px;
  background-image: url($baseURL + "/images/options/loading.gif");
  background-repeat: no-repeat;
  max-width: 80%;
  white-space: nowrap;
  overflow: hidden;
}

.option-upload-step-3 {
  text-align: center;
}

.option-upload-step-1,
.option-upload-step-2 {
  width: 260px;
}

.option-upload-step-3 {
  width: 160px;
}

.map-options-2-col .option-upload-step-1,
.map-options-2-col .option-upload-step-2 {
  width: 297px;
}

.map-options-2-col .option-upload-step-3 {
  width: 194px;
}

.marker-colors table {
  width: 100%;
  font-size: 11px;
  border-collapse: collapse;
}

.marker-colors th,
.marker-colors td {
  padding: 3px 6px;
  border-left: 1px solid #ddd;
}

.marker-colors th {
  font-weight: bold;
  text-align: left;
}

.marker-colors td {
  border-top: 1px solid #ddd;
}

.marker-colors .color {
  width: 10%;
  padding: 3px;
  border-left: 0;
}

.marker-colors .color div {
  width: 100%;
  height: 100%;
  background: #eee;
  cursor: pointer;
}

.color-picker-wrapper {
  position: absolute;
  width: 100px;
  padding-bottom: 10px;
  display: none;
}

.color-picker {
  position: relative;
  padding: 10px 5px 5px 10px;
  background: #fff;
  border: 1px solid #666;
}

.color-picker-arrow {
  position: absolute;
  left: 50%;
  bottom: -10px;
  height: 10px;
  margin-left: -10px;
}

.color-picker-arrow:before,
.color-picker-arrow:after {
  position: absolute;
  display: block;
  content: " ";
  border: 10px solid transparent;
  border-top-color: #333;
  border-bottom-width: 0;
}

.color-picker-arrow:after {
  top: -1px;
  border-top-color: #fff;
}

.color-choice {
  float: left;
  width: 15px;
  height: 15px;
  margin: 0 5px 5px 0;
  background: #eee;
  cursor: pointer;
}

/* Clear fixes */
.map-options-row:before,
.map-options-row:after,
.map-options-col:before,
.map-options-col:after,
.option-field:before,
.option-field:after,
.option-images:before,
.option-images:after,
.color-picker:before,
.color-picker:after {
  content: " ";
  display: table;
}

.map-options-row:after,
.map-options-col:after,
.option-field:after,
.option-images:after,
.color-picker:after {
  clear: both;
}

.map-options-row,
.map-options-col,
.option-field,
.option-images,
.color-picker {
  zoom: 1; /* for IE */
}

.generator_final_cta {
  margin-top: 2.5rem;
}

@include media("screen", "<phone") {
  .or-text {
    display: none;
  }

  #save button,
  #save input,
  .option-field label,
  .option-field select,
  #geocode_button {
    width: 100%;
  }
  #generator #validate_button,
  #generator #mapnow_button,
  .generator_final_cta .button {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }

  .map-options-col,
  .map-options-row {
    margin-right: 0;
  }

  .option-field label {
    padding: 20px 0 10px;
  }

  .map-options-col #preview {
    width: initial;
    max-width: 100%;
  }
}
@media screen and (max-width: 345px) {
  #generator #validate_button,
  #generator #mapnow_button {
    padding-right: 1rem;
    padding-left: 1rem;
  }
}

/* Index page */
@media screen and (max-width: 922px) {
  .map-options-col {
    margin-bottom: 6px;
    width: 100%;
  }
}

.post-map-create-modal {
  text-align: center;

  input {
    display: block;
    width: calc(100% - 50px);
    margin: 0 auto;
  }

  a.button.green {
    color: #fff;
  }
}

#generator fieldset,
#generator .fieldset {
  text-align: left;
}

#geocoding,
#mapheader {
  max-width: 736px;
  margin-right: auto;
  margin-left: auto;
}

#fields {
  margin-right: auto;
  margin-left: auto;
  h3 {
    margin: 1rem auto;
  }
}

#stylingOptions {
  justify-content: flex-end;
}

#validate_status {
  max-width: 760px;
  margin: 2rem auto 0;
  padding: 0.9rem 1rem 0.9rem 2.5rem;
  border-left: $green solid 8px;
  background: #b9d7c6 url(/images/icon-success.svg) no-repeat 0.5rem center;
  background-size: 1.5rem;
  line-height: 1.2;
}
/*
.section_hdr {
  max-width: 760px;
  margin-bottom: 10px;
}
*/
#feature .intro {
  max-width: unset;
}

.center-content {
  margin: 0px auto;
}

#advancedOptions > h3 {
  padding-left: 25px;
}

@media screen and (min-width: 767px) {
  .content-flex-align-center {
    display: flex;
    align-items: center;
  }

  .pad-right-40 {
    padding-right: 40px;
  }
  .content-flex-align-center h2 {
    text-align: left;
  }
  .even-height-cols,
  .even-height-cols .sixcol {
    display: flex;
    align-items: stretch;
  }
}

.img-corner-radius-12 img {
  border-radius: 12px;
}

.padding-bottom-30 {
  padding-bottom: 30px;
}
.padding-top-30 {
  padding-top: 30px;
}
.padding-right-20 {
  padding-right: 20px;
}
.padding-left-20 {
  padding-left: 20px;
}

/***** Privacy Poicy style fix ****/
.privacy-policy h2.smaller {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
  line-height: 1.1;
}
.privacy-policy h3.smaller {
  font-size: 1.25rem;
  font-weight: 700;
  margin-top: 9px;
  margin-bottom: 18px;
  line-height: 1.1;
}
.privacy-policy h2,
.privacy-policy h3 {
  padding-top: 20px;
}
