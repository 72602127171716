/******************************************************************
Sidebar Navigation Styles
******************************************************************/
/******************************************************************
Sidebar Navigation Styles
******************************************************************/
*, ::before, :after {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

.hide-navbar{
	display: none;
}

.scroller,
.scroller-inner {
	position: relative;
}

.container {
	overflow: hidden;
	&.map-page-container.data-view-open{
		position: absolute;
		// NOTE: this height:100% will break printing! Currently this is overridden
		//  in the print stylesheet `_print.scss`. Remove this comment or code once
		// these mobile styles only apply to mobile.
		height:100%;
		width:100%;
	}
}

.menu-trigger {
	display:none;
	position: relative;
	padding-left: 20px;
	padding-right:10px;
	font-size: 0.9em;

	&:before {
		position: absolute;
		top: 2px;
		left: 0;
		width: 40px;
		height: 6px;
		background: #ccc;
		content: '';

		@include box-shadow(0 6px #fff, 0 12px #ccc, 0 18px #fff, 0 24px #ccc);
	}
}

.menu-trigger:hover,
.menu-trigger:active,
.menu-trigger:visited  {
	border:0;
	text-decoration:none;
}

.menu-trigger:hover{
	opacity:.8;
}

.mp-pusher {
	position: relative;
	left: 0;
	display: block;
	z-index: 9999;
	&.mp-pushed {
		position:fixed;
		top:0;
		bottom:0;
		right:0;
		left:0;
	}
}

.mp-menu {
	position: absolute;
	top: 0;
	right: 0;
	z-index: 1;
	width: 300px;
	height: 100vh;
	background-color: #ccc;
	overflow: auto;
	background: #FFF;
	width: 320px;
	z-index: 99999;
	@include transform(translate3d(100%, 0, 0));

	@media screen and (max-width: 768px) {
		width: 86%;
	}
}

.mp-level {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	@include flexbox();
	@include flex-direction(column);
	@include transform(translate3d(100%, 0, 0));
}

/* overlays for pusher and for level that gets covered */
.mp-pusher::after,
.mp-level::after,
.mp-level::before {
	position: absolute;
	top: 0;
	right: 0;
	width: 0;
	height: 0;
	content: '';
	opacity: 0;
}

.mp-pusher::after,
.mp-level::after {
	background: rgba(0,0,0,0.3);
	@include transition(opacity 0.3s, width 0.1s 0.3s, height 0.1s 0.3s);
}

.mp-level::after {
	z-index: -1;
}

.mp-pusher.mp-pushed::after,
.mp-level.mp-level-overlay::after {
	width: 100%;
	height: 100%;
	opacity: 1;
	@include transition(opacity 0.3s);
}

.mp-level.mp-level-overlay {
	cursor: pointer;
}

.mp-level.mp-level-overlay.mp-level::before {
	width: 100%;
	height: 100%;
	background: transparent;
	opacity: 1;
}

.mp-pusher,
.mp-level {
	@include transition(all .3s);
}

/* overlap */
.mp-overlap .mp-level.mp-level-open {
	@include box-shadow(1px 0 2px rgba(0,0,0,0.2));
	@include transform(translate3d(0, 0, 0));
}

/* First level */
.mp-menu > .mp-level,
.mp-menu > .mp-level.mp-level-open,
.mp-menu.mp-overlap > .mp-level,
.mp-menu.mp-overlap > .mp-level.mp-level-open {
	box-shadow: none;
	@include transform(translate3d(0, 0, 0));
}

/* cover */
.mp-cover .mp-level.mp-levelen {
	@include transform(translate3d(0, 0, 0));
}

.mp-cover .mp-level.mp-level-open > ul > li > .mp-level:not(.mp-level-open) {
	@include transform(translate3d(-100%, 0, 0));
}

div#sidebar-recently-viewed .mp-back {
	position: relative;
	display: flex;
	align-items: center;
	padding: 1em;
	outline: none;
	-webkit-transition: background 0.3s;
	transition: background 0.3s;
	font-size: 14px;
	color: #FF8A00;
	letter-spacing: 0.5px;
	line-height: 16px;
	&:before{
		font-family: 'batchgeotoolbaricons';
		content: '\e923';
		font-size: 1.8em;
		width:22px;
	}
}

@media all and (min-width: 100px) and (max-width: 918px) {
	.menu-trigger {
		display: inline;
		font-size: 0.9em;
		height: 32px;
		top:8px;
		float:right;
		position:relative;

		&:before {
			position: absolute;
			top: 2px;
			left: 0;
			width: 30px;
			height: 4px;
			background: #ccc;
			@include box-shadow(0 4px #fff, 0 8px #ccc, 0 12px #fff, 0 16px #ccc);
			content: '';
		}
	}
}

/*Some styling for the sidebar menu*/
#mp-menu #login_box input[type="text"],
#mp-menu #login_box input[type="password"] {
	border: 1px solid rgba(252,189,66,0.6);
}
#mp-menu #login_box {
	background-color: rgba(255,255,255,0.2);
	padding: 20px;
}

/* Sidebar Login Box */

#sidebar-utility{
	background: #fff;
}

#sidebar_login_box {
	width: 90%;
	margin: 1rem auto 2rem;
	padding: .8rem 1rem 1rem;
	font-size:1.2em;
	background-color: #FFD449;
	@include flexbox();
	@include flex-direction(column);
}

#sidebar_login_box .error {
	color: #B51D09;
	font-weight: normal;
	padding-top: 10px;
}

#sidebar_login_box h3 {
	width: 100%;
	font-size: 14px;
	margin-top: 0;
	margin-bottom: .5rem;
}
#sidebar_login_box form {
	width: 100%;
}

#sidebar_login_box label {
	display:none;
}

#sidebar_login_box input[type="text"],
#sidebar_login_box input[type="password"] {
	box-sizing: border-box;
	margin-bottom: 10px;
	border-radius: 2px;
	border-color: #8b8b8b;
	border: none;
	padding: .9em .75em;
	width:100%;
	font-size:14px !important;
}

#sidebar_login_box form p{
	padding:0;
	margin-top: .25rem;
	font-size: 14px;

	@media screen and (max-width: 350px) {
		font-size: 12px;
	}
}

#sidebar_login_box p{
	padding: 15px 0;
	margin:0;
	font-size: 15px;
	&:last-child{
		box-shadow:none;
	}
	a{
		color: #7F6E35;
	}
	#sidebar_login_box span {
		font-size: 12px;
		margin-left: 15px;
	}
	.forgot_link {
		position:relative;
		top:-2px;
		font-weight: normal;
		color: $color-primary-dark;
		
		&:hover {
			border: none;
			color: $color-primary-dark;
		}
	}
	.avatar-icon{
		display: inline-block;
		width: 20px;
		margin-right: 8px;
		margin-bottom: -6px;
	}
	.lock-icon{
		display: inline-block;
		width: 16px;
		margin-right: 8px;
		margin-bottom: -3px;
		margin-top:-4px;
	}
	#login_button{/* 
		background: rgba(0,0,0,.1);
		font-size:14px !important;
		border:none;
		text-transform:uppercase;
		font-weight:700;
		padding:.75rem 2rem;
		color: #FFF;
		 */
		padding: 14px 20px;
		font-size: 20px !important;
		margin: 0 .25rem 0 0;
		@media screen and (max-width: 350px) {
			font-size: 16px !important;
			padding: 12px 16px;
		}
	}
}

#sidebar-navigation {
	margin: 0;
	padding: 1rem;
	list-style: none;
	@include flexbox();
	@include flex-direction(column);
	li {
		font-size: 1.25rem;
		font-weight: bold;
		text-align: center;
		margin: 0;
		padding: 0;
		a {
			display: inline-block;
			width: 100%;
			color: $black;
			padding: .25rem 0;
			margin: .5rem 0;
			text-decoration: none;
			&:hover {
				border: none;
				text-decoration: none;
				color: #333;
			}
		}
	}
}
/* @media all and (max-height: 699px) {
	#sidebar-navigation {
		padding: .5rem 1rem;
		li{
			a{
				padding: .5rem 0;
			}
		}
	}
} */

// Disable sidebar navigation on site ONLY
.site-page-container {
	#sidebar-navigation {
		@include media('>918px') {
			display: none;
		}
	}
}
div ul li #secondary-menu-trigger {
	display: flex;
	align-items: center;
	justify-content: space-between;
	&:after {
		font-family: 'batchgeotoolbaricons';
		content: '\e924';
		font-size: 2em;
	}
}

#sidebar-recently-viewed {
	display: none;
	background: #fff;
	height: 100vh
}

#sidebar-recently-viewed h3{
	color: #777;
	line-height: 1.5em;
	text-decoration: none;
	font-weight:normal;
	background: #ffd449;
	margin: 0;
	padding: 1rem;
	font-size: 1em;
	cursor: auto;
}

#recently-viewed-list {
	background: #fff;
	padding: .5rem 1rem 1rem;
	margin: 0;
	list-style: none;
}

#sidebar-account-navigation, 
#sidebar-navigation.logged-in {
	margin:0;
	padding:1rem;
	list-style: none;
	background-color: #FFD449;
	li{
		font-size:14px;
		border-bottom:1px solid rgba(0,0,0,.075);
		margin:0;
		padding:0;
		text-transform:capitalize;
		&:last-child{
			border-bottom:none;
		}
		a{
			cursor: pointer;
			display:inline-block;
			width:100%;
			color: #777777;
			padding: .75rem 0;
			line-height: 1.5em;
			text-decoration: none;
			&:hover{
				border:none;
				text-decoration:none;
				color: #333;
			}
		}
	}
}

@media all and (max-height: 699px) {
	#sidebar-account-navigation, 
	#sidebar-navigation.logged-in {
		padding:.55rem 1rem 1rem;
		li{
			a{
				padding: .5rem 0;
			}
		}
	}
}

#sidebar-navigation.logged-in {
	margin-top: -1.5rem;
	li {
		font-weight: normal;
		text-align: left;
	}
	
	li:first-of-type {
		border-top:1px solid rgba(0,0,0,.075);
	}
	
	a {
		margin: 0;
	}
}		

#signout-container {
	padding: 10px 10px 80px;
	@include flexbox();
	@include align-items(flex-end);
	@include justify-content(center);
	@include flex-grow(1);
}

#sidebar_login_signout{
	width: 100%;
	background: #F4F2F4;
	border-radius: 4px;
	font-weight:700;
	font-size: 12px;
	color: #858585;
	letter-spacing: 1px;
	text-align:center;
	text-transform:uppercase;
	padding:.75rem;
	text-decoration: none;
	&:hover{
		text-decoration:none;
		border:none;
		background: #E4E2E4;
	}
}

#sidebar_signin {
	padding: 1rem;
	color: #858585;
	background-color: transparent;
	width: 100%;
	text-transform: none;
	cursor:pointer;
}

.sidebar-signup-nudge{
	font-size:12px;
	font-weight: 800px;
	color: #7f6e35;
	text-align:center;
	margin-top:15px;
	letter-spacing:.02em;
}
.sidebar-nav-signup {
	text-align: center;

	.signup {
		margin: 0;
	}
}
