.batchgeo-search-input-wrapper {
  position: relative;
  margin-bottom: 10px;
  @include flexbox;
  @include flex-basis(280px);
}

.batchgeo-search-reset-button {
  border: 0;
  background: transparent;
  position: absolute;
  top: 0;
  right: 0;
  color: #C9C8C5;
  font-size: 25px;
  font-weight: bold;
  cursor: pointer;
  @include align-self(center);
}

.batchgeo-search-input {
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 3px;
  font-family: inherit;
  padding: 10px 35px;
  font-size: 13px;
  background: url(/images/batchgeo-search-input-icon.png) no-repeat 10px;
  background-size: 17px;
  &:focus {
    -webkit-appearance: none;
    outline: 0;
    box-shadow: #DDE0EE 0 0 5px 3px;
  }
  &.active {
    background-color: #F3F6FF;
  }
}

.batchgeo-search-input::-ms-clear {
  width : 0;
  height: 0;
}
