.admin-map-table-header-wrapper {
  @include flexbox;
  @include justify-content(space-between);
  .toolbar-icon-archive:before {
    position: relative;
    top: 2px;
  }
}
.center-sort-key {
  justify-content: center !important;
  padding-right: 15px;
  padding-left: 15px;
}
.admin-map-table-header-inner[data-sort-key] {
  @include flexbox;
  @include justify-content(space-between);
  @include align-items(center);
  position: relative;
}

.admin-map-table-header-checkbox-wrap {
  @include flexbox;
  @include justify-content(space-between);
  @include align-items(center);
  .admin-map-table-header-inner {
    padding-left: 4px;
    flex-grow: 1;
  }
  .admin-map-table-header-content {
    text-align: left;
  }
}
