$baseURL: "";
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600&display=swap");

$md-breakpoint: 600px;
$xs-breakpoint: 199px;

@import "src/reset";
@import "src/include-media";
@import "src/mixins";
@import "src/icons";
@import "src/globals";
@import "src/site_meta";
@import "src/menus";
@import "src/site_content";
@import "src/features";
@import "src/enterprise";
@import "src/mapping";
@import "src/generator";
@import "src/my_account";
@import "src/batchgeo-inline-notification";
@import "src/admin-map-table";
@import "src/admin-users";
@import "src/colorbox";
@import "src/buttons";
@import "src/captions";
@import "src/batchgeo-map-tooltip";
@import "src/map_core";
@import "src/pricing";
@import "src/about";
@import "src/header";
@import "src/edit";
@import "src/batchgeo-signup";
@import "src/home-alt";
@import "src/batchgeo-pricing";
@import "src/batchgeo-features";
@import "src/batchgeo-mobile-page";
@import "src/recent-maps";
@import "src/batchgeo-sidebar-menu";
@import "src/batchgeo-info-box";
@import "src/batchgeo-toolbar";
@import "src/batchgeo-geocode-dashboard";
@import "src/batchgeo-ui-map-mode-toggle";
@import "src/batchgeo-cookie-notice";
@import "src/batchgeo-unverified-email-notice";
@import "src/resource-error-modal";
@import "src/add-users-modal";
@import "src/pay-period-conversion-modal";

// This is needed for iOS. Without it when a user taps on an input it'll zoom
// into the input
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  select,
  textarea,
  input {
    font-size: 16px;
  }
}

/* Leftovers? =================== */
strong {
  font-weight: bold;
}

#feature li {
  list-style-type: decimal;
}

#side-nav ul {
  list-style: none;
  margin-left: 0;
  padding: 0;
}

// SEO: use to describe anchor tags that don't have text content
.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

#side-nav li {
  margin: 0;
}

#side-nav li a {
  border: 1px solid #ddd;
  border-bottom-width: 0;
  color: $color-primary;
  display: block;
  font-size: 0.9em;
  font-weight: bold;
  padding: 8px 10px;
}

#side-nav li.top a {
  @include border-top-radius(8px);
}

#side-nav li.bottom a {
  border-bottom-width: 1px;
  @include border-bottom-radius(8px);
}

#side-nav li a:hover {
  background: #bfe4f9;
  color: $color-primary;
  text-decoration: none;
}

#side-nav a.current,
#side-nav a.current:hover {
  background: $color-primary;
  color: $white;
}

#side-nav li.prolist a {
  background: #fff;
  border: 7px solid #eed678;
  color: #f63;
  padding: 0;
}

#side-nav li.prolist a:hover {
  background: #fff;
  border: 7px solid #c4aa41;
  color: #e51c00;
  padding: 0;
}

#side-nav li.prolist span.pro-header {
  background: #ffe681;
  display: block;
  padding: 6px 0 6px 3px;
}

#side-nav li.prolist span.pro-icon {
  background: #00a614;
  color: #fff;
  font-weight: bold;
  padding: 4px 6px 4px 6px;
  text-transform: uppercase;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
}

#side-nav li.prolist span.pro-list {
  display: block;
  line-height: 1.8em;
  padding: 10px;
}

.prolist img {
  margin-left: 10px;
}

.blog img {
  width: auto;
}

#sign_up_form input[type="text"],
#sign_up_form input[type="password"],
#sign_up_form .input-email {
  width: 100%;
}

@include media("screen", ">=phone") {
  #logo {
    top: 0;
    max-width: 274px;
  }
  #logo img {
    max-width: 100%;
  }
  #logo span {
    left: 76%;
  }
  a.login {
    top: 0;
  }

  #sign_up_form input[type="text"],
  #sign_up_form input[type="password"],
  #prepaid_form input[type="text"],
  #prepaid_form input[type="password"],
  #sign_up_form .input-email {
    width: 240px;
  }
}

@include media("screen", ">=624px") {
  #favorites li {
    clear: none;
    float: left;
    margin-right: 2.762430939%;
    width: 22.928176794%;
  }
  #favorites li:last-child {
    margin-right: 0;
  }
}

@include media("screen", ">=tablet") {
  .onecol {
    width: 5.801104972%;
  }
  .twocol {
    width: 14.364640883%;
  }
  .threecol {
    width: 22.928176794%;
  }
  .fourcol {
    width: 31.491712705%;
  }
  .fivecol {
    width: 40.055248616%;
  }
  .sixcol {
    width: 48.618784527%;
  }
  .sevencol {
    width: 57.182320438000005%;
  }
  .eightcol {
    width: 65.74585634900001%;
  }
  .ninecol {
    width: 74.30939226%;
  }
  .tencol {
    width: 82.87292817100001%;
  }
  .elevencol {
    width: 91.436464082%;
  }
  .twelvecol {
    width: 99.999999993%;
  }
  .onecol,
  .twocol,
  .threecol,
  .fourcol,
  .fivecol,
  .sixcol,
  .sevencol,
  .eightcol,
  .ninecol,
  .tencol,
  .elevencol,
  .twelvecol {
    position: relative;
    float: left;
    margin-left: 2.762430939%;
  }
  .pullone {
    margin-right: 5.801104972%;
  }
  .first {
    margin-left: 0;
  }
  @include media("screen", "<=tablet") {
    .first-tablet {
      margin-left: 0;
    }
  }

  .last {
    float: right;
  }
  .wrap {
    max-width: 1200px;
  }
  .intro p {
    margin-bottom: 2.5em;
  }
  #publishers a.round,
  #testimonials a.round {
    margin-top: 0;
  }

  #pro-intro {
    text-align: left;
  }
  .pro-slide {
    display: block;
  }
  .pro-slide img {
    max-width: none;
    height: 468px;
  }
  .get_started_button {
    margin-bottom: 0;
    width: 80%;
  }
  .pro-features div {
    min-height: 250px;
  }
}
@include media("screen", ">=desktop") {
  .pro-features div {
    min-height: 210px;
  }
  #favorites li div {
    float: right;
    margin: 0;
    width: 60%;
  }
}

@include media("screen", "<tablet") {
  #pricing-wrapper .signup-footer {
    .signup-footer-logos-list {
      display: inline-block;
      text-align: center;
    }
    .signup-footer-logos-list-item {
      display: inline-block;
      margin-bottom: 25px;
    }
  }
}

.wrap.header-wrap {
  max-width: 1200px;
  width: 100%;
}

.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.vertical-align {
  @include media("screen", ">phone") {
    @include flexbox();
    @include align-items(center);
  }
}

.no-mobile {
  @include media("screen", "<=phone") {
    display: none;
  }
}
.mobile-only {
  @include media("screen", ">phone") {
    display: none;
  }
}
.no-tablet {
  @include media("screen", "<=tablet") {
    display: none;
  }
}

.centered {
  margin-left: auto;
  margin-right: auto;
}
.videoBlock {
  width: 640px;
  max-width: 100%;
}
.videoWrapper {
  position: relative;
  display: block;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
}
.videoWrapper iframe,
.videoWrapper video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

input[type="radio"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  border-radius: 50%;
  width: 24px;
  height: 24px;

  color: #8b8b8b;
  border: 1px solid currentColor;
  background: #fff;
  transition: 0.2s all linear;
  margin-right: 5px;

  position: relative;
  top: 4px;
}

input[type="radio"]:active {
  color: #0e8841;
  background: radial-gradient(
    circle at 50% 50%,
    currentcolor 50%,
    #2f5865 50.001%,
    #fff 52.1%
  );
  box-shadow:
    0px 4px 4px rgba(50, 50, 71, 0.08),
    0px 4px 8px rgba(50, 50, 71, 0.06);
}
input[type="radio"]:checked {
  color: $black;
  /* background: radial-gradient(currentcolor 50%, rgba(255, 0, 0, 0) 51%); */
  background: radial-gradient(
    circle at 50% 50%,
    currentcolor 50%,
    #2f5865 50.001%,
    #fff 52.1%
  );
}
input[type="radio"]:focus {
  outline: none;
}
.error input[type="radio"],
input[type="radio"].error {
  color: $red;
  background-color: $red-lite;
}
