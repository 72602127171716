/******************************************************************

BatchGeo

Stylesheet: Colorbox

TODO: Add real retina images, make sure to include backgroudSize property as well

******************************************************************/

/*
    Colorbox Core Style:
    The following CSS is consistent between example themes and should not be altered.
*/
#colorbox, #cboxOverlay, #cboxWrapper{position:absolute; top:0; left:0; z-index:9999; overflow:hidden;}
#cboxWrapper {max-width:none;}
#cboxOverlay{position:fixed; width:100%; height:100%;}
#cboxMiddleLeft, #cboxBottomLeft{clear:left;}
#cboxContent{position:relative; float:none !important; margin: 0 auto;
}
#cboxLoadedContent{overflow:auto; -webkit-overflow-scrolling: touch;}
#cboxTitle{margin:0;}
#cboxLoadingOverlay, #cboxLoadingGraphic{position:absolute; top:0; left:0; width:100%; height:100%;}
#cboxPrevious, #cboxNext, #cboxClose, #cboxSlideshow{cursor:pointer;}
.cboxPhoto{float:left; margin:auto; border:0; display:block; max-width:none; -ms-interpolation-mode:bicubic;}
.cboxIframe{width:100%; height:100%; display:block; border:0;}
#colorbox, #cboxContent, #cboxLoadedContent{box-sizing:content-box; -moz-box-sizing:content-box; -webkit-box-sizing:content-box;}

/*
    User Style:
    Change the following styles to modify the appearance of Colorbox.  They are
    ordered & tabbed in a way that represents the nesting of the generated HTML.
*/
#cboxOverlay{background: rgba(220,220,220,1)}
#colorbox{outline:0;}
    #cboxTopLeft{display:none; width:21px; height:21px; background:url($baseURL + '/images/controls.png') no-repeat -101px 0;}
    #cboxTopRight{display:none; width:21px; height:21px; background:url($baseURL + '/images/controls.png') no-repeat -130px 0;}
    #cboxBottomLeft{display:none; width:21px; height:21px; background:url($baseURL + '/images/controls.png') no-repeat -101px -29px;}
    #cboxBottomRight{display:none; width:21px; height:21px; background:url($baseURL + '/images/controls.png') no-repeat -130px -29px;}
    #cboxMiddleLeft{display:none; width:21px; background:url($baseURL + '/images/controls.png') left top repeat-y;}
    #cboxMiddleRight{display:none; width:21px; background:url($baseURL + '/images/controls.png') right top repeat-y;}
    #cboxTopCenter{display:none; height:21px; background:url($baseURL + '/images/border.png') 0 0 repeat-x;}
    #cboxBottomCenter{display:none; height:21px; background:url($baseURL + '/images/border.png') 0 -29px repeat-x;}
    #cboxContent{background:#F7F7F7; overflow:hidden;border: 1px solid #CCCCCC;border-radius: 10px;box-shadow: 0 10px 25px rgba(0,0,0,.1)}
        .cboxIframe{background:#fff;}
        #cboxError{padding:50px; border:1px solid #ccc;}
        #cboxLoadedContent{margin-bottom:0;}
        #cboxTitle{position:absolute; bottom:4px; left:0; text-align:center; width:100%; color:#949494;}
        #cboxCurrent{position:absolute; bottom:4px; left:58px; color:#949494;}
        #cboxLoadingGraphic{background:url($baseURL + '/images/loading.gif') no-repeat center center;}

        /* these elements are buttons, and may need to have additional styles reset to avoid unwanted base styles */
        #cboxPrevious, #cboxNext, #cboxSlideshow, #cboxClose {border:0; padding:0; margin:0; overflow:visible; width:auto; background:none; }

        /* avoid outlines on :active (mouseclick), but preserve outlines on :focus (tabbed navigating) */
        #cboxPrevious:active, #cboxNext:active, #cboxSlideshow:active, #cboxClose:active {outline:0;}

        #cboxSlideshow{position:absolute; bottom:4px; right:30px; color:#0092ef;}
        #cboxPrevious{position:absolute; bottom:0; left:0; background:url($baseURL + '/images/controls.png') no-repeat -75px 0; width:25px; height:25px; text-indent:-9999px;}
        #cboxPrevious:hover{background-position:-75px -25px;}
        #cboxNext{position:absolute; bottom:0; left:27px; background:url($baseURL + '/images/controls.png') no-repeat -50px 0; width:25px; height:25px; text-indent:-9999px;}
        #cboxNext:hover{background-position:-50px -25px;}
        #cboxClose{
					position: absolute;
					width: 40px;
					height: 40px;
					right: 7px;
					top: 7px;
					color: #979797;
					z-index: 99999;
					text-indent: -200px;
					overflow: hidden;
			
					&:before {
						position:absolute;
						top:5px;right:0;
						width: 40px;
						height:40px;
						font-family: 'batchgeotoolbaricons' !important;
						speak: none;
						font-style: normal;
						font-weight: normal;
						font-variant: normal;
						text-transform: none;
						line-height: 1;
						-webkit-font-smoothing: antialiased;
						-moz-osx-font-smoothing: grayscale;
						content: "\e912";
						font-size: 28px;
						color: #979797;
						text-indent:0;
					}
				}
        #cboxClose:hover{background-position:-25px -25px;}

/* Custom dialogs */

#cboxOverlay.custom-dialog {
    background: rgba(255,255,255,0.8);
}

#cboxOverlay.map-success-dialog {
    background: rgba(255,255,255,0.8)
}
#colorbox.map-success-dialog {
    border-radius: 8px;
    box-shadow: rgb(199, 199, 199) 0 1px 2px 1px;

    h1 {
        margin-bottom: 9px;
    }

    .post-map-create-modal-input {
        padding: 10px 20px;
        text-align: center;
        margin: 13px auto;
    }


    #cboxContent {
        background: #F7F7F7;
        width: 100% !important;
        // Because it's overflow hidden, pick a large number we won't ever hit
				height: 1000px !important;
    }

    #cboxLoadedContent {
        height: 100% !important;
        width: 100% !important;
    }

    .go-to-button {
        margin-right: 15px;
    }

		.free-plan-button {
			margin-top: 25px;
		}

    .post-map-create-modal {
        padding: 30px 125px 0;
        @media only screen and (max-width: 572px){
            padding: 30px 25px 0;
            .button{
                width:75%;
                margin-right:0 ;
            }
        }
    }
    #cboxTopLeft,
    #cboxTopCenter,
    #cboxTopRight,
    #cboxMiddleLeft,
    #cboxMiddleRight,
    #cboxBottomLeft,
    #cboxBottomCenter,
    #cboxBottomRight {
        width: 0;
        height: 0;
        display: none !important;
    }

}

#colorbox.custom-dialog {
    box-shadow: 0px 15px 45px rgba(0,0,0, 0.5);
    border-radius: 4px;

    #cboxTopLeft,
    #cboxTopCenter,
    #cboxTopRight,
    #cboxMiddleLeft,
    #cboxMiddleRight,
    #cboxBottomLeft,
    #cboxBottomCenter,
    #cboxBottomRight {
        width: 0;
        height: 0;
        display: none !important;
    }

    #cboxContent {
        text-align: center;
        border-radius: 4px;
				background: #eee;
				float:none;
    }

    #cboxLoadedContent {
        padding: 24px;
        margin-bottom: 0;
    }

    .dialog-message {
        color: #555;
        text-shadow: 0 1px 0 white;
        margin-bottom: 24px;
        margin-top: 6px;
        kbd {
            padding: 2px 4px;
            background: rgba(0,0,0,0.1);
            border: 1px solid rgba(0,0,0,0.2);
        }
    }

    .dialog-buttons {
        margin-bottom: 0;

        .button {
            min-width: 25%;
            font-size: 14px;
            padding: 0.5em 1em;
            margin-bottom: 0;
        }

        .dismiss-btn {
            margin-right: 1em;
        }
    }
}

#colorbox.custom-dialog h2 {
	margin-top: 0px;
}
