$breakpoint-md: 780px;
$breakpoint-sm: 425px;
$breakpoint-x-sm: 350px;
$breakpoint-img: 715px;

#pay-period-conversion-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  gap: 40px;
  isolation: isolate;
  justify-content: space-between;
  position: absolute;
  width: 386px;
  height: 551px;

  background: #ffffff;
  border-radius: 12px;
  .pro-upsell-header {
    /*  */

    width: 252px;
    height: 60px;

    /* Large/Regular */
    font-family: "Arimo";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    /* or 30px */
    text-align: center;

    color: #024059;
    font-family: Arial, Helvetica, sans-serif;
    font-style: normal;

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .pro-upsell-blerb {
    width: 232px;
    height: 48px;

    /* Body/Default */
    font-family: "Arimo";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    /* or 24px */
    text-align: center;

    color: #777777;
    font-family: Arial, Helvetica, sans-serif;
    font-style: normal;

    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;
  }
  .pay-period-conversion-content {
    align-content: center;
    justify-content: space-between;
    text-align: center;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
  .annual-monthly-toggle {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px;
    gap: 8px;

    width: 232px;
    height: 64px;

    background: #f0f2f3;
    border-radius: 9999px;
    align-self: center;
    input {
      display: none;
    }
    label {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 12.5px 10px;
      gap: 10px;
      width: 104px;
      height: 48px;
      border-radius: 999px;
      /* Body/Sm - regular */
      font-family: Arial, Helvetica, sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 150%;
      /* identical to box height, or 22px */

      color: #777777;

      /* Inside auto layout */
      flex: none;
      order: 0;
      flex-grow: 0;
    }
    input:checked + label {
      background: #ffffff;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
      color: black;
      /* Body/Sm - regular */
      font-family: Arial, Helvetica, sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 150%;
      /* identical to box height, or 22px */

      color: #1d1d1d;
    }
  }
  .add-on-pitch-header {
    /* Level up your usage with addons */

    width: 306px;
    height: 30px;

    /* Large/Regular */
    font-family: Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    /* identical to box height, or 30px */
    text-align: center;

    color: #024059;
  }
  .add-on-pitch-body {
    /* With addons you can add more users, map views and geocodes whether you were a lite or pro user */

    width: 306px;
    height: 72px;

    /* Body/Default */
    font-family: Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    /* or 24px */
    text-align: center;

    color: #777777;
  }
  .add-on-selection {
    display: flex;
    flex-direction: row;
    align-content: flex-start;
  }
  .add-on-count {
    display: flex;
    flex-direction: row;
    align-content: flex-start;
    justify-content: center;
  }
  .add-on-tooltip-container {
    position: absolute;
    // left: 330px;
    // top: 317.41px;

    /* Inside auto layout */
    flex: none;
    order: 2;
    flex-grow: 0;
    z-index: 2;

    justify-content: left;
    align-self: flex-end;

    /* Frame 398 */

    box-sizing: border-box;

    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    gap: 11px;

    width: 155px;
    height: 161px;
    text-align: left;
    background: #ffffff;
    border: 1px solid #ededed;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    .add-on-tooltip-bold {
      /* smaller/bold */
      font-family: Arial, Helvetica, sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 150%;
      /* or 18px */

      color: #071d25;
    }
    .add-on-tooltip {
      /* Body/Smaller */
      font-family: Arial, Helvetica, sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 150%;
      /* identical to box height, or 18px */

      color: #777777;
    }
    .add-on-tooltip-number {
      font-family: Arial, Helvetica, sans-serif;
      font-style: normal;
      font-size: 12px;
      line-height: 150%;
      font-weight: 600;
    }
    display: none;
  }

  .add-on-tooltip-container-tax {
    position: absolute;
    // left: 330px;
    // top: 317.41px;

    /* Inside auto layout */
    flex: none;
    order: 2;
    flex-grow: 0;
    z-index: 2;

    justify-content: left;
    align-self: flex-end;

    /* Frame 398 */

    box-sizing: border-box;

    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    gap: 11px;

    width: 155px;
    height: 161px;
    text-align: left;
    background: #ffffff;
    border: 1px solid #ededed;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
    border-radius: 8px;

    .add-on-tooltip {
      /* Body/Smaller */
      font-family: Arial, Helvetica, sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 150%;
      /* identical to box height, or 18px */

      color: #777777;
    }

    display: none;
  }
  .flex-center {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .price-line-item {
    .add-on-tooltip-image {
      margin: 0;
      padding: 2px;
      width: 12px;
      height: 12px;
    }
    /* Frame 405 */

    box-sizing: border-box;

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 4px 0px;

    width: 306px;
    height: 22px;

    border-bottom: 1px solid #ededed;
  }

  .price-line-item-label {
    display: flex;
    flex-direction: row;
    justify-content: left;
    font-family: Arial, Helvetica, sans-serif;
    /* Yearly Subscription */
    text-align: left;
    flex-shrink: 0;
    /* Body/Smaller */
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    /* identical to box height, or 18px */

    color: #777777;
  }
  .price-line-item-value {
    font-family: Arial, Helvetica, sans-serif;

    /* smaller/bold */
    font-family: "Arimo";
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 150%;
    /* identical to box height, or 18px */
    text-align: right;

    color: #1d1d1d;
  }
  .convert-actions {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-self: center;
    padding: 7px 20px;
    gap: 10px;
  }
  .convert-plan-button {
    /* Primary Button */

    box-sizing: border-box;

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-self: center;
    padding: 7px 20px;
    gap: 10px;

    width: 161px;
    height: 32px;

    background: #f9c81b;
    border-width: 0px;
    border-bottom: 4px solid #e0af00;
    border-radius: 999px;

    /* smaller/bold */
    font-family: Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 150%;
    /* identical to box height, or 18px */

    color: #1d1d1d;
    cursor: pointer;
  }
  .convert-plan-upsell-button {
    /* Secondary button */

    box-sizing: border-box;

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 7px 20px;
    gap: 10px;

    width: 161px;
    height: 32px;

    background: #024059;
    border-bottom: 4px solid #022a3a;
    border-radius: 999px;

    color: white;

    font-family: Arial, Helvetica, sans-serif;
    font-style: normal;
    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 1;
    cursor: pointer;
  }
  .convert-downsell {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: start;
    flex-shrink: 1;
    gap: 10px;
  }
  .convert-plan-downsell-button {
    // text-decoration: underline;
    font-size: 10px;
    color: #777777;
    cursor: pointer;
  }
  .add-on-selection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    gap: 20px;

    width: 306px;
    height: 31px;
  }
  .add-on-count-selection {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 12px;

    width: 87px;
    height: 31px;

    background: #e7f3ef;
    border-radius: 140px;
  }

  .add-on-count-label {
    font-family: Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 150%;
    color: #071d25;
    align-self: center;
    padding-left: 10px;
  }
  .add-on-count-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;

    width: 23px;
    height: 23px;

    background: #024059;
    border-radius: 60px;
    border-width: 0px;
  }
  .add-on-count-value {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
    line-height: 135%;
    letter-spacing: 0px;
    color: #071d25;
    font-weight: bold;
  }
  .add-on-tooltip-image {
    margin: 0;
    width: 16px;
    height: 16px;
  }
  .add-on-tooltip-image:hover .add-on-tooltip-container {
    display: flex;
  }
  .add-on-tooltip-image:hover .add-on-tooltip-container-tax {
    display: flex;
  }
}
