.admin-map-table-loader {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(255,255,255,0.5);
  @include flexbox;
  @include align-items(center);
  @include justify-content(center);
}

.admin-map-table-loader > div {
  width: 18px;
  height: 18px;
  background-color: rgba(68, 67, 67, 0.5);
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: admin-map-table-loader-bounce-delay 1.4s infinite ease-in-out both;
  animation: admin-map-table-loader-bounce-delay 1.4s infinite ease-in-out both;
}

.admin-map-table-loader .admin-map-table-loader-bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.admin-map-table-loader .admin-map-table-loader-bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes admin-map-table-loader-bounce-delay {
  0%, 80%, 100% { -webkit-transform: scale(0) }
  40% { -webkit-transform: scale(1.0) }
}

@keyframes admin-map-table-loader-bounce-delay {
  0%, 80%, 100% {
    @include transform(scale(0));
  }
  40% {
    @include transform(scale(1.0));
  }
}