.form-error-modal {
  text-align: left;

  button {
    text-align: right;
  }
}

/* Plan breakdown Section */

.summary-wrapper {
  margin-bottom: 2rem;
}

.monthly-total table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;

  #table-bottom {
    border-top: 1px solid #ededed;
    padding-top: 0.5rem;
  }

  tr:last-child {
    text-align: left;
    font-weight: bold;
  }

  td:nth-child(1) {
    width: 80%;
  }

  #table-left-spacer {
    font-style: italic;
    padding-left: 1rem;
  }

  #table-bottom-spacer {
    font-style: italic;
    padding-bottom: 1.5rem;
  }

  td:nth-child(2) {
    width: 40%;
    text-align: right;
  }
}

/* Cancel Subscription Section */
.cancel-subscription {
  #sign_up_form {
    display: flex;
    flex-direction: column;
  }

  .cancel-buttons {
    flex-direction: column;
    align-items: center;
  }

  .cancel-subscription-confirm .red {
    margin-top: 20px;
    margin-left: 10px;
  }

  input {
    margin-bottom: 0;
  }
}

.usage_section {
  background: linear-gradient(180deg, #ffffff 0%, #fbfbfb 100%);
  border: 1px solid #b7b7b7;
  box-sizing: border-box;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  padding: 1rem;
}

.usage_section span {
  font-size: 14px;
  line-height: 19px;
  color: #606060;
}

input {
  font-size: 14px;
  font-family: $sans-serif;
  box-sizing: border-box;
  border: 1px solid #aaa;
  border-radius: 4px;
  padding: 7px 0.5rem 6px;
}
input:not(.button):focus {
  color: $green;
  border-color: $green;
  outline-color: $green;
  box-shadow: 0px 4px 4px 0px rgba(50, 50, 71, 0.2);
}
input.error {
  color: $red;
  border-color: $red;
  background-color: $red-lite;
}
input[disabled] {
  color: $gray;
  border-color: $gray;
  background-color: $gray-lite;
}

#usageWrapper {
  h3 {
    margin-top: 2rem;
  }
}

.link-wrapper {
  text-align: right;
  a {
    text-decoration: none;
    display: inline-block;
    color: #fff;
  }
}
.usage_month {
  border-bottom: $color-primary solid 3px;
}
.admin {
  select {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0.8rem;
    min-height: auto;

    &[disabled] {
      color: #8b8b8b;
      border-color: #8b8b8b;
      background-color: #f0f2f3;
    }
  }
}
.progress-bar {
  width: 100%;
  height: 100%;
  background-color: #ededed;
  border-radius: 3px;
  margin-bottom: 1rem;
}
.progress-bar-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0.2rem;
}
.progress {
  font-size: 10px;
  font-weight: 700;
  height: 100%;
  max-height: 14px;
  max-width: 100%;
  border-radius: 3px;
  background-color: #7cb62f;
}
.progress span {
  visibility: hidden;
}

.warning-header {
  font-size: 18px;
  font-weight: bold;
}

.warning-wrapper {
  margin-bottom: 1rem;
}

.warning-container {
  background: #fffedd;
  border: 1px solid #ffd449;
  border-radius: 3px;
  padding: 1rem;
  color: #6f5500;
  li {
    margin: 0;
  }
}

.account_buttons {
  display: none;
  justify-content: space-between;
  margin-top: 3rem;
  form {
    &.center {
      margin: 0 auto;
    }
  }
}

.remove-button {
  cursor: pointer;
  max-width: 203px;
  background: linear-gradient(180deg, #ed1c24 0%, #aa1317 100%);
  border: 1px solid #980c10;
  border-radius: 3px;
  padding: 13px 23px;
  color: #ffffff;
}

.remove-addon-button {
  cursor: pointer;
  border-radius: 3px;
  color: #ffffff;
  padding-top: 8px;
  padding-bottom: 8px;
}

.add-button {
  cursor: pointer;
  font-size: 1em;
  width: 100%;
  max-width: fit-content;
  background: linear-gradient(180deg, #7db72f 0%, #4e7d0e 100%);
  border: 1px solid #538312;
  border-radius: 3px;
  padding: 13px 23px;
  color: #ffffff;
}

.admin-button {
  cursor: pointer;
  max-width: 203px;
  height: 47px;
  font-size: 16px;
  display: flex;
  align-items: center;
  border: 1px solid #b7b7b7;
  border-radius: 3px;
  padding: 13px 23px;
  &.green {
    background: linear-gradient(180deg, #7db72f 0%, #4e7d0e 100%);
  }
}

.addOn__btn-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 52px;
  margin-bottom: 2rem;
}
.addOn__btn-wrapper button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/*Account section */
.account-info-wrapper {
  margin-top: 2rem;
  display: flex;
  flex-wrap: wrap;
}

.account-info-col {
  flex-direction: column;
  width: 80%;
}

/*Usage section */
.col1 {
  flex: 60%;
  margin-right: 1rem;
}

/*Add-on section */
.col2 {
  flex: 30%;
  border-left: solid 1px #b7b7b7;
  padding-left: 1rem;
}

@media only screen and (max-width: 1260px) {
  .account-info-col {
    width: 100%;
  }
  .col2 {
    flex: 100%;
    border-left: none;
    padding-left: 0;
    margin-left: 0;
  }
  .col1 {
    flex: 100%;
    margin-right: 0;
  }
}

/*styling for all boxes in the account panel*/
.account-panel-box {
  border: 1px solid #b7b7b7;
  box-shadow: 0 4px 14px rgba(0, 0, 0, 0.15);
  background: linear-gradient(180deg, #fff 0, #fbfbfb 100%);
  border-radius: 6px;
  padding: 0.8rem;
  margin: 0 0 1rem 0;
}

#delete {
  margin-right: 1rem;
}
#added-detail p {
  font-size: 14px;
  margin: 0;
}

.account-panel form {
  display: block;
}

#totalAddon {
  font-weight: bold;
}

.account_addons {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 300px;
  opacity: 1;
  padding: 10px;
}

.addon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.added-section {
  display: flex;
  justify-content: space-between;
}

#addon-info {
  padding-left: 5px;
  margin: 5px;
  border-left: solid 1px #b7b7b7;
  color: grey;
}

#addon-info p {
  font-size: 12px;
  margin: 0;
}

#addon_price {
  font-size: 60px;
}

#addon_price::before {
  content: "$";
  font-size: 34px;
  vertical-align: 0.5em;
  color: #707070;
  position: relative;
  right: -10px;
}

#manage-subscription > h1 {
  font-size: 2rem;
  color: #585757;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
  margin-top: 2rem;
}

/*Add-on modal section */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  z-index: 99999;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  -webkit-transition: opacity 100ms ease-in;
  -moz-transition: opacity 100ms ease-in;
  transition: opacity 100ms ease-in;
}

/* Modal Content/Box */
.modal-content {
  max-width: 700px;
  text-align: left;
  padding: 30px;
  left: 50%;
  top: 50%;
  position: absolute;
  transform: translate(-50%, -50%) !important;
  border-radius: 7px;

  p {
    padding-top: 5px;
    margin: 0;
    // font-size: 16px !important;
    color: #767676 !important;
  }

  .modal-info {
    margin-bottom: 30px;
    text-align: left;
  }

  /* The Close Button */
  .close {
    color: #aaa;
    font-size: 28px;
    font-weight: bold;
    position: absolute;
    top: 2px;
    right: 10px;
  }

  .modal-buttons {
    text-align: right;
  }

  #addOn_total {
    width: 150px;
    padding: 10px;
    text-align: right;
  }

  .add-button {
    padding: 10px 50px 10px 50px;
    font-size: 15px;
  }

  input[type="number"]::-webkit-inner-spin-button {
    opacity: 1;
  }

  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    width: 90%;
  }
}

.subscription-modal {
  a {
    float: right;
    text-decoration: none;
    color: #fff;
  }
}

.spinner {
  /* Spinner size and color */
  width: 1rem;
  height: 1rem;
  border-top-color: #fff;
  border-left-color: #fff;

  /* Additional spinner styles */
  animation: spinner 1.2s linear infinite;
  border-bottom-color: transparent;
  border-right-color: transparent;
  border-style: solid;
  border-width: 1px;
  border-radius: 50%;
  box-sizing: border-box;
  display: inline-block;
  vertical-align: middle;
}

/* Animation styles */
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* credit card update/form modal */

.update-billing-top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
}

#update-plan-link {
  display: inline-block;
  text-decoration: underline;
  padding-bottom: 20px;
  cursor: pointer;
  font-weight: bold;
}
#convert-plan-link {
  display: inline-block;
  text-decoration: underline;
  padding-bottom: 20px;
  cursor: pointer;
  font-weight: bold;
}

.modal-content {
  select {
    margin-bottom: 10px;
  }

  input {
    margin-bottom: 10px;
  }

  label {
    display: block;
    margin-bottom: 5px;
    color: #767676;
    font-size: 12px;
  }

  .gray-text {
    color: #767676;
  }

  .card_number {
    margin-top: 10px;
    input {
      width: 80%;
    }
  }

  .security_field {
    input {
      width: 100%;
    }
    padding-left: 10px;
  }

  .info_wrapper {
    margin-bottom: 10px;
    display: flex;
  }

  .billing_warning_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 5px;
    margin-bottom: 5px;

    p {
      margin-bottom: 2px;
    }
  }

  .red-text {
    color: #d81b21 !important;
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .green-text {
    color: #219653 !important;
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  #captcha_element {
    width: 300px;
    margin: 20px 0 20px 0;
  }

  #pricing-cards-wrapper {
    margin-bottom: 0;
  }

  .pricing-card {
    margin-top: 20px;
  }

  .pricing-table-container {
    margin-top: 0;
  }

  .confirmation-wrapper {
    p {
      font-size: 16px !important;
      margin-bottom: 20px;
    }
  }

  .cancel-subscription-buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    @media (min-width: 440px) {
      flex-wrap: nowrap;
      justify-content: flex-end;
    }
    button {
      padding: 10px 23px;
      margin-left: 10px;
      margin-top: 20px;
    }
    .admin-button.red {
      margin-bottom: 0px;
    }
  }
  h2#cancel-header {
    font-size: 18px;
    text-align: center;
    @media (min-width: 440px) {
      font-size: 20px;
      text-align: left;
    }
  }

  .cancel-subscription-confirm {
    display: none;
    label {
      font-size: 16px;
      margin-top: 10px;
    }
  }
}

.cancel-wrapper {
  margin-top: 20px;
}

#verify-card-wrapper {
  padding: 20px;
  background: #fef3a6;
  border-radius: 15px;
  font-size: 14px;
  margin-bottom: 10px;

  a {
    color: black;
    text-decoration: underline;
    cursor: pointer;
  }
}

@media only screen and (min-width: 200px) and (max-width: 800px) {
  .modal-content {
    width: 90%;
    max-height: calc(100vh - 100px);
    overflow-y: auto;
  }
  .update-billing-top {
    flex-direction: column;
  }
  .info_wrapper {
    flex-direction: column;
  }
  .security_field {
    padding-left: 0 !important;
  }
  .transaction_field {
    width: 100%;
  }
}

.added-section div {
  display: flex;
}

.added-section div:first-child {
  flex-direction: column;
}

.added-section div:last-child {
  justify-content: flex-end;
  align-items: center;
}

.added-section input {
  margin: 0 5px 0 0;
}

.addon-warning {
  font-size: 14px;
}

#remove-addon-modal .modal-buttons {
  display: flex;
  justify-content: flex-end;
  vertical-align: center;
}

#remove-addon-modal input {
  margin: 0 5px 0 0;
  height: 100%;
}

#remove-addon-modal a {
  width: 100%;
  text-align: start;
}

.gray {
  color: #737373;
  background: #ededed;
  border: 1px solid #737373;
}
