.geocode-tracker-header{
	background: #515050;
	display:flex;
	flex:1;
	justify-content: center;
	align-items: center;
	z-index: 101;
	position:relative;
	.inner{
		display:flex;
		flex:1;
		justify-content: space-between;
		align-items: center;
	}
	h1{
		color: #fff;
		padding:0;
		margin:0;
		font-size: 24px;
		display:flex;
		flex-direction: row;
		img{
			width:30px;
			height:30px;
			display:flex;
			margin-right:15px;
		}
	}
	p{
		color: #fff;
		padding:0;
		margin:0;
		font-size: 16px;

	}
}
#geocode-tracker-wrapper{
	display:flex;
	flex-direction: row;
	justify-content: center;
}
.inner{
	padding: 1rem;
	max-width:1300px;
	justify-content: center;
	width:100%;
}

.geocode-tracker-chart{
	justify-content: center;
	flex-direction: column;
	align-items: center;
	display:flex;
}
#geocode-chart{
	width:100%;
	height:200px;
}

.geocode-tracker-chart-toggle-buttons{
	display:inline-flex;
	flex-direction: row;
	list-style:none;
	border:1px solid #D8D8D8;
	border-radius: 4px;
	overflow:hidden;
	li{
		margin:0;padding:0;
		border-right:1px solid #D8D8D8;
		&:last-child{
			border-right:none;
		}
		a{
			display:inline-block;
			width:100px;
			text-align:center;
			padding: 4px 0;
			background-image: linear-gradient(0deg, #FCFCFC 0%, #E8E8E8 30%);
			background-image: linear-gradient(-180deg, #FCFCFC 2%, #F0F0F0 100%);
			background-image: linear-gradient(-180deg, #FCFCFC 2%, #F0F0F0 100%);
			font-size: 16px;
			color: #979797;
			&:hover{
				border:none;
				cursor:pointer;
			}

		}
		&.active{
			a{
				background: #E3E3E3;
			}
		}
	}
}
.geocode-tracker-stats{
	display:flex;
	flex: 1;
	justify-content: space-evenly;
	flex-direction: row;
	.stat-container{
		position:relative;
		flex: 1;
		text-align:center;
		border-right:1px solid #D8D8D8;
		&:last-child{
			border-right:none;
		}
		.stat-title{
			font-size: 22px;
			color: #9B9B9B;
			font-weight:700;
		}
		.stat-count{
			font-size: 64px;
			font-weight:500;
			color: #FFC715;
		}
		.overlimit-info{
			display:none;
		}
		.stat-sub-text{
			font-size:14px;
			font-style:italic;
			color: #4A4A4A;
		}
		&.overlimit{
			.stat-count, .overlimit-info{
				color: #D0021B;
			}
			.overlimit-info{
				display:block;
				position:absolute;
				bottom: 25px;
				width: 100%;
				font-size: 14px;
				font-weight:700;
				left:0;
			}
		}
	}
}

hr{
	margin:3.5rem 0;
	background: #D8D8D8;
}

.geocode-tracker-jobs{
	table{
		width:100%;
		font-size:14px;
		margin-bottom:5rem;
		color: #4A4A4A;
		thead{
			text-align:left;
			font-weight:700;
			color: #000;
			th{
				padding: .5rem .5rem 1rem .5rem;
			}
		}
		tbody{
			tr{
				td{
					padding: .75rem .5rem;
				}
			}
			tr:nth-child(odd){
				background: #F7F7F7;
			}
		}
	}
}

.lds-ring {
	display: inline-block;
	position: relative;
	width: 48px;
	height: 48px;
}
.lds-ring div {
	box-sizing: border-box;
	display: block;
	position: absolute;
	width: 36px;
	height: 36px;
	margin: 6px;
	border: 4px solid #ffc715;
	border-radius: 50%;
	animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
	border-color: #ffc715 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
	animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
	animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
	animation-delay: -0.15s;
}
@keyframes lds-ring {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}


#lds-ring-container{
	opacity:0;
	margin-right:10px;
	&.animation{
	-webkit-animation: fadeinText 2s; /* Safari, Chrome and Opera > 12.1 */
	-moz-animation: fadeinText 2s; /* Firefox < 16 */
	-ms-animation: fadeinText 2s; /* Internet Explorer */
	-o-animation: fadeinText 2s; /* Opera < 12.1 */
	animation: fadeinText 2s;
	}
}
#cover {
	#lds-ring-container {
		opacity: 1;
	}
}

@keyframes fadeinText {
	0% { opacity: 0; }
	30%   { opacity: 1; }
	80%   { opacity: 1; }
	100%   { opacity: 0; }
}


.last-updated-right{
	display:flex;
	flex-direction:row;
	align-items: center;
}

#last_updated_text{
	&.animation{
		-webkit-animation: colorText 2s; /* Safari, Chrome and Opera > 12.1 */
		-moz-animation: colorText 2s; /* Firefox < 16 */
		-ms-animation: colorText 2s; /* Internet Explorer */
		-o-animation: colorText 2s; /* Opera < 12.1 */
		animation: colorText 2s;
	}
}
@keyframes colorText {
	0% { color: "#fff;" }
	30%   { color: "#ffc715;" }
	80%   { color: "#ffc715;" }
	100%   { color: "#fff;" }
}

@keyframes fadeCover {
	0% { opacity: 0; }
	10% { opacity: 1; }
	50%   { opacity: .9; }
	75%   { opacity: .9; }
	100%   { opacity: 0; }
}

#cover{
	position:fixed;
	left:-1000px;
	background: #fff;
	opacity:0;
	z-index: 100;
	display:flex;
	align-items: center;
	justify-content: center;
	&.animation{
		top:0;left:0;right:0;bottom:0;
		-webkit-animation: fadeCover 2s; /* Safari, Chrome and Opera > 12.1 */
		-moz-animation: fadeCover 2s; /* Firefox < 16 */
		-ms-animation: fadeCover 2s; /* Internet Explorer */
		-o-animation: fadeCover 2s; /* Opera < 12.1 */
		animation: fadeCover 2s;
	}
}
