#signup-wrapper {
  font-family: $sans-serif;
  margin-top: 2rem;

  + #footer {
    margin: 0;
  }

  b {
    font-weight: bold;
  }

  img {
    width: auto;
  }

  .blink {
    background: #fff8a9;
  }

  .map-count {
    background: transparent;
    transition: 0.25s background;
  }
  .standard-body {
    margin-left: 0;
  }

  .signup-right {
    padding-bottom: 25px;
    min-width: 348px;
    text-align: center;
  }

  .signup-form-wrapper {
    margin-bottom: 80px;
  }

  .signup-splash {
    padding: 80px 25px 50px;
    text-align: center;

    h1 {
      color: #fff;
      font-size: 2.4em;
      font-weight: normal;
      margin: 0;
    }

    h2 {
      color: $orange;
      font-weight: bold;
      font-style: italic;
      font-size: 1.8em;
    }

    p {
      color: #fff;
      font-size: 1.6em;
    }
  }

  .order-summary-container {
    margin: 32px 0;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0 0 0;
  }
  th,
  td {
    padding: 10px 0px;
    border: 0px solid #ddd;
    color: #777;
    font-size: 15px;
  }
  .row {
    display: flex;
    justify-content: space-between;
  }
  .total {
    font-weight: bold;
    color: black;
    border-top: 1px solid #d8d5ca !important;
    margin-top: 20px;
    padding-top: 10px;
  }

  .total td {
    font-weight: bold;
    color: black;
  }

  .price {
    color: black;
    font-weight: bold;
  }

  .signup-form {
    display: block;
    max-width: 800px;
    margin: 0 auto 50px;

    .signup-fields {
      background: #f4f4f4;
      padding: 64px 75px 50px;
      margin-bottom: 20px;
      font-size: 100%;

      @media screen and (max-width: 599px) {
        padding: 0 40px 50px;
      }
      &.hidden {
        display: none;
      }
      .field {
        margin: 0 0 0.5rem;
      }
    }

    h3 {
      font-size: 25px;
      margin: 0px 0 6px;
      z-index: 2;
      position: relative;
      text-align: left;
    }

    a {
      color: #4990e2;
    }

    .signup-form-bubble-number {
      background: $orange;
      color: #fff;
      border-radius: 100%;
      display: inline-block;
      margin-right: 5px;
      width: 33px;
      height: 33px;
    }

    .split-width .field:first-child {
      margin-right: 46px;
    }

    .split-width {
      @include flexbox;
      @include justify-content(space-between);
    }

    .field {
      width: 100%;
      margin-bottom: 15px;
      text-align: left;

      label {
        margin-bottom: 5px;
        @include flexbox;
        @include align-items(center);
        @include justify-content(space-between);
      }

      input.full-width {
        width: 100%;
      }
      input {
        border: 1px solid #cacaca;
        padding: 14px 16px 14px 12px;
      }

      &.full-width {
        width: 100%;
        input,
        label {
          width: 100%;
        }
      }
      &.fiftywidth {
        width: 100%;
        input,
        label {
          width: 50%;
          margin-right: 50%;
        }
      }

      select {
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        padding: 14px 26px 14px 12px;
        border-radius: 0;
        border: 1px solid #cacaca;
        background: url(/images/signup/select-arrows.svg) no-repeat
          calc(100% - 10px) center #fff;
        background-size: 6px 20px;
      }

      select::-ms-expand {
        color: #cfcfcf;
        background: #fff;
        border: 0;
      }

      &.field-cvv {
        width: 285px;
      }
    }

    .input-helper-text {
      padding: 10px 0 0 0;
      font-size: 12px;
      font-weight: bold;
      font-style: italic;
      color: #777777;
      text-align: left;
    }

    .input-error-helper {
      color: #ff2f2a;
      display: none; /* gets displayed if there's an error */
    }

    .card-number-expiration-field select {
      width: 214px;
    }

    .card-number-expiration-slash {
      padding: 0 5px;
      font-size: 1.1em;
      font-weight: bold;
      color: #777777;
    }

    .state-select-field select {
      width: calc(100% - 187px);
    }

    .pricing-subhead {
      color: #333;
      text-align: center;
      font-style: italic;
      font-weight: bold;
      font-size: 0.95em;
      margin-bottom: 5px;
    }

    .pricing-text {
      font-size: 0.8em;
      color: #333333;
      margin: 0px 0 35px 0;
      line-height: 140%;
      letter-spacing: 0.02em;
    }
  }

  .signup-signin-text {
    margin: 10px 0 20px;
    text-align: center;
    color: #333333;
    font-weight: 700;
    font-size: 1.1em;
  }

  .signup-signin-button {
    border: 0;
    background: none;
    color: $orange;
    font: inherit;
    text-decoration: underline;
  }

  .secure-label {
    color: #5fa700;
    font-weight: normal;
    img {
      height: 12px;
    }
  }

  .split-width-two-thirds {
    @include flexbox;
    @include justify-content(center);
  }

  .split-width-two-thirds .field:first-child {
    margin-right: 50px;
  }

  .signup-submit-wrapper {
    text-align: center;
    margin-top: 35px;

    img {
      width: 28px;
      vertical-align: middle;
    }

    p {
      color: #333333;
      margin: 0 0 25px;
    }

    button {
      font-size: 1.5em;
      font-weight: 500;
      font-family: inherit;
      padding: 18px 72px;
    }

    .small-print {
      font-size: 12px;
    }
  }

  .signup-footer {
    background: #444343;
    color: #fff;
    padding: 50px;
    clear: both;

    p {
      margin: 0;
      text-align: center;
      font-weight: bold;
      font-size: 1.8em;
    }

    .signup-footer-underline {
      background: url(/images/signup/underline.svg) no-repeat bottom;
      padding: 0 0 7px;
    }

    .signup-footer-logos-list {
      margin-top: 30px;
      @include flexbox;
      @include justify-content(space-between);
      @include align-items(center);
      list-style: none;
    }

    .signup-footer-logos-list-item {
      padding: 0 10px;
    }
  }

  .batchgeo-form-auto-error {
    color: red;
    font-weight: bold;
  }

  // Big screen styles
  @media all and (min-width: 1280px) {
    background-size: contain;
  }

  // Mobile styles
  @media all and (max-width: 700px) {
    .signup-footer .signup-footer-logos-list-item:nth-child(even) {
      display: none;
    }
  }

  @media all and (max-width: 580px) {
    // Flip it so more fit since there's less evens than odds
    .signup-footer .signup-footer-logos-list-item:nth-child(even) {
      display: block;
    }
    .signup-footer .signup-footer-logos-list-item:nth-child(odd) {
      display: none;
    }
    .signup-splash {
      font-size: 0.8em;
    }

    .signup-form .split-width,
    .signup-form .split-width-two-thirds {
      display: block;
    }

    .signup-form .field.field-cvv {
      width: 100%;
    }

    .signup-form .card-number-expiration-slash {
      display: none;
    }

    .signup-form .card-number-expiration-month {
      margin-bottom: 30px;
    }

    .signup-form .state-select-field select,
    .signup-form .card-number-expiration-field select {
      width: 100%;
    }

    .signup-form .signup-submit-wrapper button {
      padding: 18px 25px;
    }
  }

  .pricing-box {
    margin-left: auto;
    margin-right: auto;
    hr {
      margin: 0px;
    }
  }
  @media all and (min-width: 768px) {
    .pricing-box {
      margin-top: 0;
    }

    .signup-right {
      width: 31%;
    }
  }
  .instruction {
    color: #777777;
  }
  #addon_price::before {
    right: 0;
  }
}
.pricing-box-title {
  font-size: 16px;
  line-height: 150%;
  color: #071d25;
  padding: 0px 48px;
}
.pricing-box-body {
  max-width: none;
  margin: 0px;
  text-align: start;
  padding: 32px 24px 32px 24px;
  div {
    font-size: 15px;
    line-height: 150%;
    color: #777777;
    margin-bottom: 8px;
  }
  .h1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .plan-frequency {
    color: #777777;
  }
}
.signup_frequency_radio_group {
  display: flex;
  flex-wrap: wrap;
}

button.faux-braintree-payment-options-button {
  background: #fafafa;
  border-radius: 4px;
  color: #000;
  cursor: pointer;
  font-size: 14px;
  margin: 0 auto;
  padding: 18px;
  text-align: center;
  width: 100%;
  border: none;
  line-height: 1.2;
  font-weight: 400;
  &:hover {
    font-weight: 500;
    background: #fcfcfc;
    span {
      border-color: #000;
    }
  }
  span {
    border-bottom: 1px solid #b5b5b5;
    padding-bottom: 1px;
  }
}
#annual-monthly-toggle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  padding: 8px;
  gap: 8px;

  width: stretch;
  height: 64px;

  background: #fff;
  border-radius: 9999px;
  align-self: center;
  input {
    display: none;
  }
  div {
    display: flex;
    flex-grow: 1;
  }
  label {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12.5px 10px;
    gap: 10px;
    height: 48px;
    border-radius: 999px;
    /* Body/Sm - regular */
    font-family: Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 150%;
    /* identical to box height, or 22px */

    color: #777777;
    flex-grow: 1;
  }
  input:checked ~ label {
    background: #f9c81b;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    color: black;
    /* Body/Sm - regular */
    font-family: Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 150%;
    /* identical to box height, or 22px */

    color: #1d1d1d;
  }
}
.add-on-count-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  div {
    display: flex;
  }
}
.add-on-count-selection {
  display: flex;
  flex-direction: row;
  padding: 0px;
  gap: 12px;
  border-width: 2px;
  border-color: #0000;
  align-items: center;
  background: #fff;
  border-radius: 140px;
}

.add-on-count-label {
  font-family: Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 150%;
  color: #071d25;
  align-self: center;
  padding-left: 10px;
}
.add-on-prompt {
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #777777;
}
.add-on-label {
  padding-left: 20px;
  flex-grow: 1;
  align-items: center;
  /* smaller/bold */
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;

  color: #777777;
}
.add-on-price {
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  align-items: center;
  color: #071d25;
}
.add-on-count-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;

  width: 23px;
  height: 23px;

  background: #024059;
  border-radius: 60px;
  border-width: 0px;
  margin: 5px;
}
.add-on-count-value {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 15px;
  font-weight: 500;
  line-height: 22.5px;
  letter-spacing: 0px;
  color: #024059;
  font-weight: bold;
}
.add-on-tooltip-image {
  margin: 0;
  width: 16px;
  height: 16px;
}
.add-on-tooltip-image:hover .add-on-tooltip-container {
  display: flex;
}
.add-on-tooltip-image:hover .add-on-tooltip-container-tax {
  display: flex;
}
.add-on-contents-label {
  padding-top: 40px;
  padding-bottom: 16px;
  font-size: 16px;
  line-height: 150%;

  color: #071d25;
}
.add-on-contents {
  display: flex;
  flex-direction: row;
  gap: 4px;
}
.hide {
  display: none;
  overflow: hidden;
}
.add-on-content {
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  gap: 6px;

  background: #ffffff;
  border-radius: 70px;

  font-size: 12px;
  line-height: 150%;

  color: #777777;
  span {
    font-weight: 700;
  }
}
.account-info-prompt {
  font-size: 16px;
  line-height: 150%;
  color: #777777;
}
.savings-highlight {
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  gap: 10px;

  background: #cfe4ed;
  border-radius: 80px;

  font-size: 12px;
  font-weight: 400;
  color: #024059;
}
