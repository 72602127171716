#feature-page {
	section {
		text-align:center;
	}
}

#feature-page section.using-batchgeo {
	padding: 0;
}

.feature-set {
	.feature-group{
		@include flexbox;
		@include flex-direction(row);
		@include justify-content(center);
	}
	h2 {
		font-size: 30px;
		font-weight:600;
		text-align:center;
	}
	p {
		text-align:center;
	}
	.feature-item {
		@include flexbox;
		@include flex(1);
		border:0;
		margin:0 11px;
		text-align:center;
		background: #F0F2F3;
		a {
			display:inline-block;
			height:360px;
			margin: 0 auto;
			text-decoration: none;
		}
		a:hover {
			outline:none;
			text-decoration:none;
			border-bottom:none;
		}
		.feature-content{
			height: 155px;
			@include flexbox;
			@include flex-direction(column);
			@include justify-content(space-between);

			padding:0 10px;
			h3 {
				font-size: 20px;
				color: $color-primary;
				margin:0 0 12px 0;
				padding:0;
			}
			p {
				margin:0 0 10px 0;
				padding:0;
				line-height: 20px;
				&.more{
					color: $color-primary;
				}
			}
		}
		.featureill {
			height: 195px;
			padding: 20px 25px;
			@include flexbox;
			@include align-items(center);
			@include justify-content(center);
			img {
				max-width: 170px;
				@media screen and (max-width: 1250px) {
					max-width: 140px;
				}
			}
		}
	}
	.feature-item:hover {
		background: #fbfbfb;
	}
}
.foryourbusiness {
	.feature-item{
		background: #fff;
	}
	.feature-content p{
		font-size: 15px;
	}
}
.using-batchgeo {
	.using-batchgeo-contain {
		a:hover{
			border:none;
			opacity:.8;
		}
		h1, p, ul, li, a {
			color: #fff;
		}
		h1, p {
			text-align: center;
		}
		ul, li {
			text-align: left;
			font-weight: bold;
		}
		li {
			list-style: none;
			background:url(/images/icon-article.svg) no-repeat 0 2px;
			a {
				padding-left:30px;
			}
		}
		li:before {
			display:none;
		}
	}
}
.batchgeo-list-wrapper{
	padding-top: 1rem;
	@include flexbox;
	@include flex-direction(row);
	@include flex-basis(33%);
	ul{
		@include flex(1);
	}
	@media all and (min-width: 801px) {
		ul:first-of-type {
			flex-basis: 40%;
		}
		ul:nth-of-type(2) {
			flex-basis: 33%;
		}
		ul:last-of-type {
			flex-basis: 27%;
		}
	}
}

@media all and (max-width: 1150px) {
	.feature-group{
		@include flexbox;
		@include flex-direction(row);
		@include flex-wrap(wrap);
	}
	.feature-set {
		width:100%;
		.feature-item {
			@include flex-wrap(wrap);
			@include flex(none);
			@include flex-basis(30%);
			margin-bottom: 1.5rem;
			}
	}
}

@media all and (max-width: 800px) {
	.batchgeo-list-wrapper {
		@include flex-direction(column);
		@include align-items(center);
		margin-bottom:25px;
		ul{
			width:225px;
			margin:0;
			li{
				margin-bottom:5px;
			}
		}
	}
}
@media all and (max-width: 650px) {
	.feature-group {
		@include flexbox;
		@include flex-direction(row);
		@include flex-wrap(wrap);
	}
	.feature-set {
		width:100%;
		.feature-item {
			@include flex-wrap(wrap);
			@include flex(1);
			@include flex-basis(48%);
			margin-bottom:1.5rem;
		}
	}
	.using-batchgeo-contain ul {
		li{
			width:100%;
		}
	}
}

@media all and (max-width: 460px) {
	.feature-group {
			@include flexbox;
			@include flex-direction(column);
			@include flex-wrap(wrap);
	}
	.feature-set {
			width:100%;
			.feature-item {
				max-width:300px;
				@include flex-basis(100%);
				@include flex-wrap(wrap);
			}
	}
}

.promo-features.feature-set  {
	.feature-item {
		box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.33);
	}
}

@media all and (min-width: 1250px) {
	.promo-features.feature-set  {
		.feature-item {
			margin: 0 2.25rem;
		}
	}
}
