.batchgeo-toolbar {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  top: 10px;
  left: 70px;
  z-index: 2;
  @media screen and (max-width: $xs-breakpoint) {
    left: 10px;
    right: 5px;
    flex-wrap: wrap;
  }
}

.batchgeo-toolbar-button {
  display: none;
  padding: 0;
  margin: 0;
  width: 48px;
  height: 48px;
  background: #fff;
  border: 1px solid #d9d9d9;
  cursor: pointer;
}

.batchgeo-toolbar-button.drawing-manager-button {
  width: 48px;
  margin-left: -1px;
}

.batchgeo-toolbar-button-group:hover .batchgeo-toolbar-button {
  display: block;
  border-left: 1px solid #d9d9d9;
}

.batchgeo-toolbar-button:hover {
  background: #f7f7f7;
}

.batchgeo-toolbar-button:first-child {
  display: block;
  margin-left: -1px;
}

.batchgeo-toolbar-button-group .batchgeo-toolbar-button {
  margin-top: -1px;
}

.batchgeo-toolbar-button-group .batchgeo-toolbar-button:first-child {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  margin-top: 0;
}

.batchgeo-toolbar-button-group:first-child
  .batchgeo-toolbar-button:first-child {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.batchgeo-toolbar-button-group:last-child .batchgeo-toolbar-button:first-child {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.batchgeo-toolbar-button:focus {
  outline: none;
}

.batchgeo-toolbar-button-icon {
  width: 32px;
  height: 32px;
  font-size: 32px;
  display: inline-block;
  opacity: 1 !important;
  color: #666666;
}
.batchgeo-toolbar-button:hover .batchgeo-toolbar-button-icon {
  color: #111;
}

.batchgeo-toolbar-button.active {
  background: #f2f2f2;
}
.batchgeo-toolbar-button.active .batchgeo-toolbar-button-icon:before {
  opacity: 0.3;
  color: #333;
}
.batchgeo-toolbar-button-group .batchgeo-toolbar-button:first-child.active {
  box-shadow:
    inset 0 0 8px rgba(0, 0, 0, 0.15),
    0 1px 1px rgba(0, 0, 0, 0.1);
}
.batchgeo-toolbar-button-group
  .batchgeo-toolbar-button:first-child.active
  .batchgeo-toolbar-button-icon:before {
  opacity: 1;
  color: #3366cc;
}
.batchgeo-toolbar-button-group .groupSelectionCount {
  position: absolute;
  left: 0px;
  bottom: 48px;
  margin-bottom: 8px;
}
.batchgeo-toolbar-button-group .groupSelectionCount span:first-of-type {
  display: none;
}

.batchgeo-toolbar-button.active .batchgeo-toolbar-button-icon {
  opacity: 1;
  filter: alpha(opacity=100);
}

.batchgeo-toolbar-tooltip {
  z-index: 10;
  display: none;
  background: #4687f1;
  padding: 5px 10px;
  width: 100%;
  text-align: center;
  color: #fff;
  position: absolute;
  top: 60px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}

button.has-options {
  &:hover div.toolbar-options-button-group {
    display: flex;
    align-items: center;
  }
  &:first-child:hover .toolbar-options-button-group {
    display: none;
  }
  &:before {
    content: "";
    position: absolute;
    top: 3px;
    left: 3px;
    font-size: 0.7em;
  }
  &.miles:before {
    content: "mi";
  }
  &.kilometers:before {
    content: "km";
  }
}

.toolbar-options-button-group {
  position: absolute;
  z-index: 1;
  top: 12px;
  left: 40px;
  display: none;
  .batchgeo-toolbar-button {
    display: flex;
    align-items: center;
    font-weight: bold;
  }
}

// GM Official Buttons
// Overriding default Google Controls, hence the !important
.gm-bundled-control {
  .gmnoprint {
    > div {
      width: 48px !important;
      height: 96px !important;
      button {
        width: 48px !important;
        height: 48px !important;
        display: flex !important;
        // @include align-items(center);
        // @include justify-content(center);
        img {
          margin: 0 !important;
          height: 12px !important;
          width: 12px !important;
        }
        > div {
          top: auto !important;
          left: auto !important;
        }
      }
      > div {
        margin: 0 !important;
        left: auto !important;
        width: 100% !important;
      }
    }
  }
}

.gm-style-mtc {
  div {
    font-size: 12px !important;
  }
}
.gm-svpc {
  top: 110px !important;
  width: 48px !important;
  height: 48px !important;

  > div {
    // left: 11px !important;
    // top: 11px !important;
    img {
      width: 14px !important;
      height: 24px !important;
    }
  }
  &:hover {
    > div {
      img {
        width: 24px !important;
        height: 32px !important;
      }
    }
  }
}

@media screen and (max-width: 599px) {
  .in-iframe .gm-bundled-control {
    .gmnoprint {
      > div {
        width: 32px !important;
        height: 64px !important;
        button {
          width: 32px !important;
          height: 32px !important;
          img {
            margin: 0 !important;
            height: 12px !important;
            width: 12px !important;
          }
        }
        > div {
          left: auto !important;
          width: 100% !important;
        }
      }
    }
    .gm-svpc {
      width: 32px !important;
      height: 32px !important;
      display: none;
    }
  }

  .in-iframe .batchgeo-toolbar-button-group {
    width: 24px;
    height: 24px;
  }
  .in-iframe .batchgeo-toolbar-button {
    height: 24px;
    width: 24px;
  }
  .in-iframe .batchgeo-toolbar-button-icon {
    height: 20px;
    width: 20px;
    font-size: 20px;
  }
  .in-iframe .batchgeo-toolbar {
    left: 50px;
    @media screen and (max-width: $xs-breakpoint) {
      left: 10px;
    }
  }
}

.in-iframe .gm-style-mtc {
  @media screen and (max-width: 300px) {
    display: none;
  }
}

.storeLocator-view {
  .batchgeo-toolbar {
    left: calc(70px + 375px);
  }
}
