/******************************************************************

BatchGeo

Stylesheet: Icons

******************************************************************/

@font-face {
	font-family: 'icomoon';
	src: url($baseURL + '/css/fonts/icomoon.eot?v=3#iefix') format('embedded-opentype'),
		url($baseURL + '/css/fonts/icomoon.woff?v=3') format('woff'),
		url($baseURL + '/css/fonts/icomoon.ttf?v=3')  format('truetype'),
		url($baseURL + '/css/fonts/icomoon.svg?v=3#svgFontName') format('svg');
	font-weight: normal;
	font-style: normal;
}

/* Use the following CSS code if you want to use data attributes for inserting your icons */
[data-icon]:before {
	font-family: 'icomoon';
	content: attr(data-icon);
	speak: none;
	font-weight: normal;
	-webkit-font-smoothing: antialiased;
}

@font-face {
	font-family: 'batchgeotoolbaricons';
	src:  url($baseURL + '/css/fonts/batchgeotoolbaricons.eot?192019');
	src:  url($baseURL + '/css/fonts/batchgeotoolbaricons.eot?192019#iefix') format('embedded-opentype'),
	url($baseURL + '/css/fonts/batchgeotoolbaricons.ttf?192019') format('truetype'),
	url($baseURL + '/css/fonts/batchgeotoolbaricons.woff?192019') format('woff'),
	url($baseURL + '/css/fonts/batchgeotoolbaricons.svg?192019#batchgeotoolbaricons') format('svg');
	font-weight: normal;
	font-style: normal;
}

[class^="toolbar-icon-"], [class*=" toolbar-icon-"] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'batchgeotoolbaricons' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.toolbar-icon-archive:before {
	content: "\e90a";
}
.toolbar-icon-error:before {
	content: "\e90c";
}
.toolbar-icon-info:before {
	content: "\e90d";
}
.toolbar-icon-success:before {
	content: "\e90e";
}
.toolbar-icon-refresh:before {
	content: "\e901";
}
.toolbar-icon-location:before {
	content: "\e902";
}
.toolbar-icon-following:before {
	content: "\e926";
}
.toolbar-icon-hand:before {
	content: "\e903";
}
.toolbar-icon-rectangle:before {
	content: "\e904";
}
.toolbar-icon-circle:before {
	content: "\e905";
}
.toolbar-icon-polygon:before {
	content: "\e906";
}
.toolbar-icon-ruler:before {
	content: "\e907";
}
.toolbar-icon-marker-distance:before {
	content: "\e908";
}
.toolbar-icon-print:before {
	content: "\e909";
}
.toolbar-icon-dock:before {
	content: "\e90b";
}
.toolbar-icon-undock:before {
	content: "\e910";
}
.toolbar-icon-zoom:before {
	content: "\e911";
}
.toolbar-icon-close:before {
	content: "\e912";
}
.toolbar-icon-compass-rose:before {
	content: "\e900";
}
.toolbar-icon-marker:before {
	content: "\e947";
}
.toolbar-icon-search:before {
	content: "\e90f";
}
.toolbar-icon-menu:before {
	content: "\e913";
}
.toolbar-icon-table:before {
	content: "\e914";
}
.toolbar-icon-map:before {
	content: "\e915";
}
.toolbar-icon-marker:before {
	content: "\e947";
}
.toolbar-icon-direction:before {
	content: "\e922";
}
.toolbar-icon-copy:before {
	content: "\e91e";
}
.toolbar-icon-cancel-circle:before{
	content: "\e902";
}

@font-face {
	font-family: 'batchgeoenterpriseicons2020';
	src:    url('/css/fonts/batchgeoenterpriseicons2020.eot');
	src:    url('/css/fonts/batchgeoenterpriseicons2020.eot') format('embedded-opentype'),
		url('/css/fonts/batchgeoenterpriseicons2020.ttf') format('truetype'),
		url('/css/fonts/batchgeoenterpriseicons2020.woff') format('woff'),
		url('/css/fonts/batchgeoenterpriseicons2020.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}
/* @font-face {
	font-family: 'batchgeofeatureicons';
	src:    url('/css/fonts/batchgeofeatureicons.eot?byvac3');
	src:    url('/css/fonts/batchgeofeatureicons.eot?byvac3#iefix') format('embedded-opentype'),
		url('/css/fonts/batchgeofeatureicons.ttf?byvac3') format('truetype'),
		url('/css/fonts/batchgeofeatureicons.woff?byvac3') format('woff'),
		url('/css/fonts/batchgeofeatureicons.svg?byvac3#batchgeotoolbaricons') format('svg');
	font-weight: normal;
	font-style: normal;
} */

[class^="feature-icon-"], [class*=" feature-icon-"] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'batchgeoenterpriseicons2020' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.feature-icon-office:before {
    content: "\e901";
}
.feature-icon-users:before {
    content: "\e907";
}
.feature-icon-lock:before {
    content: "\e910";
}
.feature-icon-pdf:before {
    content: "\e90e";
}
.feature-icon-meter:before {
    content: "\e90f";
}
.feature-icon-make-group:before {
    content: "\e904";
}
.feature-icon-table2:before {
    content: "\e90c";
}
.feature-icon-insert-template:before {
    content: "\e90d";
}
.feature-icon-cancel-circle:before {
	content: "\e902";
}

/* @font-face {
	font-family: 'batchgeoenterpriseicons';
	src:    url('/css/fonts/batchgeoenterpriseicons.eot');
	src:    url('/css/fonts/batchgeoenterpriseicons.eot') format('embedded-opentype'),
		url('/css/fonts/batchgeoenterpriseicons.ttf') format('truetype'),
		url('/css/fonts/batchgeoenterpriseicons.woff') format('woff'),
		url('/css/fonts/batchgeoenterpriseicons.svg') format('svg');
	font-weight: normal;
	font-style: normal;
} */


[class^="enterprise-icon-"], [class*="enterprise-icon-"] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'batchgeoenterpriseicons2020' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

/* .enterprise-icon-headset:before {
	content: "\e938";
}
.enterprise-icon-user-plus:before {
	content: "\ea45";
}
.enterprise-icon-credit-card:before {
	content: "\e9de";
}
.enterprise-icon-bell-minus:before {
	content: "\ea0b";
}
.enterprise-icon-printer:before {
	content: "\ea11";
}
.enterprise-icon-rocket:before {
	content: "\eaad";
}
.enterprise-icon-clipboard2:before {
	content: "\eaf0";
}
.enterprise-icon-select:before {
	content: "\eb72";
}
.enterprise-icon-share3:before {
	content: "\ec4d";
}
.enterprise-icon-office:before {
	content: "\e904";
}
.enterprise-icon-database:before {
	content: "\ea2a";
}
.enterprise-icon-file-presentation:before {
	content: "\e99d";
}
.enterprise-icon-cancel-circle:before {
	content: "\eb90";
} */


.enterprise-icon-headset:before {
	content: "\e90b";
}
.enterprise-icon-user-plus:before {
	content: "\e907";
}
.enterprise-icon-credit-card:before {
	content: "\e909";
}
.enterprise-icon-printer:before {
	content: "\e906";
}
.enterprise-icon-rocket:before {
	content: "\e903";
}
.enterprise-icon-clipboard2:before {
	content: "\e90a";
}
.enterprise-icon-select:before {
	content: "\e905";
}
.enterprise-icon-share3:before {
	content: "\e908";
}
.enterprise-icon-office:before {
	content: "\e901";
}
.enterprise-icon-database:before {
	content: "\e900";
}
.enterprise-icon-file-presentation:before {
	content: "\e904";
}
.enterprise-icon-cancel-circle:before {
	content: "\e902";
}




@font-face {
	font-family: 'batchgeomobilepageicons';
	src:  url('fonts/batchgeomobilepageicons.eot?5nxjpy');
	src:  url('fonts/batchgeomobilepageicons.eot?5nxjpy#iefix') format('embedded-opentype'),
	url('fonts/batchgeomobilepageicons.ttf?5nxjpy') format('truetype'),
	url('fonts/batchgeomobilepageicons.woff?5nxjpy') format('woff'),
	url('fonts/batchgeomobilepageicons.svg?5nxjpy#batchgeomobilepageicons') format('svg');
	font-weight: normal;
	font-style: normal;
}

[class^="mobilepage-icon-"], [class*="mobilepage-icon-"] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'batchgeomobilepageicons' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.mobilepage-icon-directions:before {
	content: "\e900";
	color: #f8cc00;
}
.mobilepage-icon-filter:before {
	content: "\e901";
	color: #f8cc00;
}
.mobilepage-icon-geolocation:before {
	content: "\e902";
	color: #f8cc00;
}
.mobilepage-icon-share:before {
	content: "\e907";
	color: #f8cc00;
}
.mobilepage-icon-search:before {
	content: "\e903";
	color: #f8cc00;
}
