// uncomment if index4.php is used
/* #tier-pricing-wrapper {
	 #pricing-header {
		background: url(/images/signup/signup-background.jpg) no-repeat;
		background-size: cover;
		padding: 75px 0 200px 0;
		h1 {
			color: #fff;
			font-size: 2.4em;
			font-weight: 400;
			margin: 0 0 10px 0;
			text-align: center;
		}
		p {
			color: #fff;
			font-size: 1.6em;
			text-align: center;
			margin-bottom:40px;
		}
		@include media ("screen", "<tablet") {
			padding: 75px 0;
		}
	}

	#pricing-cards-wrapper {

		@include media ("screen", "<=tablet") {
			.pricing-card { padding: 0; }
		}
		@include media ("screen", "<=phone") {
			margin-top: 0;
			.pricing-card { padding: 15px; }
		}
		&.two-up{
			max-width:820px;
		}
	}

	&.gray{
		background: #F8F8F8;
	}

	.pro-features {
		margin-top: 60px;
		h2 {
				text-align: center;
				font-weight:600;
		}
		&.white{
			background:#fff;
			border-top:0;
			border-bottom:0;
			padding-top:50px;
			h2{
				color: #585757;
			}
		}
	}
	

	#media {
		background-color: #EFEFEF;
		padding: 35px 0;
	}
	#pro-cta {
		background: #444343;
		padding: 35px 0;
		margin: 0;
		text-align: center;
		.wrap {
			@include media ("screen", ">=tablet") {
				@include flexbox;
				@include align-items(center);
				text-align: left;
				max-width:900px;
			}
		}
		h2 {
			color: #fff;
			font-size: 24px;
			font-weight:600;
			margin-bottom:0px;
			text-align:left;
			@include media ("screen", "<tablet") {
				margin-bottom: 15px;
			}
		}
		p {
			font-size: 1em;
			color: #DADADA;
			margin: 0;
			@include media ("screen", "<tablet") {
				margin-bottom: 15px;
			}
		}
	}
} */

.promo-banner {
	padding: 10px;
	background: #fff0c0;
	text-align: center;
	font-weight: bold;
}

.pricing-card {
	padding: 0 15px;
	&.signup-card{
		margin-bottom:25px;
	}
	.card-content {
		background: #fff;
		border: 1px solid #FFC715;
		border-radius: 8px;
		text-align: center;
		padding: 30px 15px;
		background-clip: padding-box;
		position: relative;
		&.signup-card-content{
			padding:0;
			border-color: #DDDDDD;
		}
		@include box-shadow(0 1px 4px rgba(0,0,0,0.2));
		h1 {
			font-weight: 200;
			font-size: 2.9em;
			color: #515050;
			margin: 0;
			line-height: 1em;
			padding: 0 10px;
		}
		.price {
			font-weight: 600;
			font-size: 3.75em;
			color: #FFC715;
			margin: 0;
			line-height: 1.25em;
			&.enterprise {
				font-size: 1.625em;
				margin: 10px 0 42px 0;
			}
		}
		p {
			font-size: 0.75em;
			margin-bottom: 25px;
		}
		.card-title{
			font-size:1em;
			font-weight:700;
			margin-bottom: 10px;
			margin-top:15px;
		}
		ul {
			list-style: none;
			margin: 0;
			li {
				border-bottom: 1px solid #DADADA;
				margin-bottom: 10px;
				padding-bottom: 10px;
				&:last-child {
					border-bottom: 0;
				}
			}
		}
		.pricing-card-explainer{
			border-top: 1px solid #DADADA;
			background: #F6F6F6;
			padding: 25px;
			p{
				padding-bottom:0;
				margin-bottom:0;
				font-size:12px;
				color: #757575;
			}
		}
		.popular {
			background-color: #ffd449;
			color: #515050;
			font-size: 0.875em;
			position: absolute;
			padding: .25em 3em;
			left: -2.5em;
			font-weight: 600;
			top: 2.5em;
			@include transform(rotate(-45deg));
		}
	}
}

.pro-features {
	display: flex;
	text-align: center;
}

@media screen and (max-width: 766px) {
	.pro-features {
		flex-direction: column;
		flex-wrap: nowrap;
		align-items: center;
	}

	.pro-features .pro-feature {
		width: 80%;
	}
}

@media screen and (min-width: 767px) {
	.pro-features {
		max-width: 1200px;
		justify-content: center;
		flex-direction: row;
		flex-wrap: wrap;
		margin: auto;
	}

	.pro-features .pro-feature {
		width: 33.33%;
		padding: 20px;
	}
}

.pro-features h2 {
	margin-bottom: 1.7em;
}
.pro-features h3 {
	color: $color-primary;
}

.pro-features span {
	display: block;
	color: $color-primary;
	font-size: 4em;
	margin: 0 0 1rem;
}

.bg-blue .pro-features-content {
	h3, span, a {
		color: $liteblue;
	}
}

.lite-features-cta {
	margin-top: 2rem;
}

// uncomment if index2.php is used
/* #pricing-wrapper {
	#content-wrap {
		background: url(/images/signup/signup-background.jpg) no-repeat;
		background-size: cover;

		.pricing-splash h1 {
			color: #fff;
			font-size: 2.4em;
			font-weight: 400;
			margin: 0 0 10px 0;
			text-align: center;
		}

		.pricing-splash p {
			color: #fff;
			font-size: 1.6em;
			text-align: center;
			margin-bottom:40px;
		}

		#pro-intro .proprice {
			font-size:72px;
			line-height:52px;
			margin:0;
			padding:0;
			font-weight:500;
			sup {
				position:relative;
				top:-30px;
				margin-left:-15px;
				margin-right:5px;
			}
		}
		#pro-intro .proprice-label {
			font-size:20px;
			line-height:16px;
			color:#fff;
			font-style:normal;
			padding:0 0 10px 0;
			letter-spacing:.0125em;
		}

		#pro-intro h2 {
			font-size: 20px;
			font-weight:600;
			color: #fff;
			margin:0;
			padding:0 0 10px 0;
		}
		#pro-intro p {
			font-size:16px;
		}

		.get_started_button {
			display:block;
			width:100%;
		}

		#pro-intro {
			background: rgba(0, 0, 0, .15);
			border-radius: 4px;
			padding:20px;
			text-align:center;
		}

	}
	.pro-slide {
		margin: 0 !important;
	}

	.pro-features h2 {
		color: #585757;
		font-size:30px;
		text-align:center;
		margin-bottom:50px;
	}

	#pro-cta-wrap {
		background: #444343;
		border-bottom: 1px solid #5E5E5E;
		padding:50px 0;
	}
	#pro-cta {
		margin:0 auto;
		background: #444343;

		h2 {
			color:#fff;
			font-size:36px;
			font-weight:700;
			line-height: 100%;
			margin-bottom: 10px;
		}
		h3 {
			color:#fff;
			margin:5px 0 0 0;
		}
	}

	.signup-footer {
		background: #efefef;
		color: #fff;
		padding: 50px;

		p {
			color:#585757;
			margin: 0;
			text-align: center;
			font-weight: 700;
			font-size: 30px;
		}

		.signup-footer-logos-list {
			margin-top: 30px;
			@include flexbox;
			@include justify-content(space-between);
			@include align-items(center);
			list-style: none;
		}

		.signup-footer-logos-list-item {
			padding: 0 10px;
		}

	}
	#pro-demo {
		margin:0;
		border: 1px solid #C0C0C0;
		box-shadow: 0 1px 4px 0 rgba(0,0,0,0.20);
	}

} */

.pricing-video{
	display:block;
	margin:0 auto;
	justify-content:center;
	background:none;
	text-align:center;
	#pro-demo{
		height:auto;
		margin: 1rem auto 4rem;
		max-width:100%;
		max-height:400px;
		height:auto;
		width:auto;
	}
}

.flexible-billing{
	line-height:24px;

}

a.pro-features-link{
	color: #FF9200;
	font-weight:bold;
	margin-top:20px;
	text-decoration:underline;
	display:inline-block;
	&:hover{
		text-decoration:none;
		border-bottom:0;
		padding-bottom:0;
		cursor:pointer;
	}
}

@media screen and (max-width: 1125px) {
	.pricing-card .card-content h1 {
		font-size: 2.5em;
	}
}

@media screen and (max-width: 1115px) {
	.pricing-card .card-content h1 {
		font-size: 2.4em;
	}
}

@media screen and (max-width: 950px) {
	.pricing-card .card-content h1 {
		font-size: 1.8em;
	}

	.pricing-card .card-content .price {
		font-size: 3.25em;
	}
}

/* .signup-footer-underline {
	background: url(/images/signup/underline.svg) no-repeat bottom;
	padding: 0 0 7px;
} */
