/******************************************************************
BatchGeo
Stylesheet: Menus
******************************************************************/


#login_box p {
  margin-bottom: 18px;
  line-height: 18px;
}
#login_box p:last-child {
  margin-bottom: 0;
}
.pro-signin #login_box{
  background: #000;
  color: #fff;
  position: absolute;
  font-size: 11px;
  right: 0;
  padding: 20px 20px 20px 27px;
  text-align: left;
  top: 25px;
  z-index: 99;
  border-radius: 3px;
}

.pro-signin #login_box.logged-in{
  width:100%;
  padding:0;
}

.pro-signin #login_box.logged-in p{
  margin:0;
}
.pro-signin #login_box.logged-in .advancedToggle{
  padding:8px 28px;
  &.on{
    padding:8px 0;
  }
}
.pro-signin #login_box a {
  color: #fff;
  font-size: 12px;
  cursor:pointer;
}
.pro-signin #login_box.logged-in a{
  width:auto;
  display:block;
  padding:8px 28px;
  margin:0;
}
.pro-signin #login_box p:first-child a{
  margin-top:10px;
}
.pro-signin #login_box p:last-child a{
  margin-bottom:10px;
}
.pro-signin #login_box a:hover{
  text-decoration:none;
  border-bottom:none;
  color: #ddd;
  background:#333;
}
.pro-signin #login_box.logged-in .advancedToggle:hover {
  background:#333;
  color:#ddd;
}
.pro-signin a.login.active {
  color: #fff;
  background-color: #000;
  position: relative;
  background-repeat: no-repeat;
  background-position: 98% center;
  z-index: 10;
  border-radius: 5px 5px 0 0;
}
.pro-signin a.login span {

}

#login_box.logged-in {
  vertical-align: baseline;
}
#login_box label {
  display: block;
  font-size: 12px;
}
#login_box span {
  float: left;
  font-size: 12px;
  margin-top: 10px;
}
#login_box label[for="remember_me"] {
   float: left;
   margin-top: 10px;
}
#login_box input[type="text"],
#login_box input[type="password"] {
   padding: 6px;
   width: 195px;
   border-radius: 2px;
}

#login_box input.button {
   float: right;
   font-size: 14px;
   padding: 8px 20px;
   margin-bottom: 0px;
}

#mapWrap #login_box {
  padding: 10px 20px 0px 30px;
}

.dots{
	height: 14px;
	width: 4px;
	margin-left:10px;
	margin-top:2px;
	div{
		display:block;
		background: #979797;
		width:3px;
		height:3px;
		margin-bottom:3px;
		border-radius: 100%;

	}
}
