.batchgeo-map-tooltip-anchor {
	position: absolute;
	width: 100%;
	left: 0;
	margin-top: 70px;
	text-align: left;
	// Make it above everything else, including the infowindow
	z-index: 9999;

	&.no-title {
		margin-top: 38px;
		.batchgeo-map-tooltip-content {
			padding-top: 10px;
		}
	}

	.batchgeo-map-tooltip-content {
		// Positioning logic
		position: absolute;
		top: 0;
		left: 0;
		transform: translate(-50%, -100%);

		// Actual styling
		background: rgba(255,255,255,0.85);
		padding: 5px 10px 10px 10px;
		border-radius: 5px;
		box-shadow: 0 2px 10px 1px rgba(0,0,0,0.5);

		@mixin content-base () {
			margin: 0;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
			max-width: 300px;
		}

		h1 {
			font-weight: bold;
			color: #333;
			font-size: 14px;
			float: none;
			@include content-base();
		}

		p {
			font-size: 11px;
			@include content-base();
		}
	}
}
