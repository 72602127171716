.unverifed-email-notice-wrapper {
	background: $dark-gray;
}
.unverifed-email-notice {
	max-width: 1140px;
	margin: 0 auto 25px;
	padding: 5px 0;
	text-align: center;
	font-weight: bold;
	font-size: 15px;
	color: $white;
}
