.batchgeo-cookie-notice {
	position: fixed;
	width: 100%;
	background: #444343;
	color: #fff;
	padding: 24px;
	left: 0;
	bottom: 0;
	display: flex;
	z-index: 1000;

	p {
		font-size: 12px;
		margin-bottom: 0;
	}

	a {
		color: #fff;
		text-decoration: underline;
	}

	button {
		width: 32px;
    height: 32px;
    font-size: 18px;
    color: #fff;
    border: 1px solid #fff;
    border-radius: 100%;
    background: transparent;
		flex: 0 0 32px;
    margin-right: 20px;
    line-height: 1.1;
    cursor: pointer;
		text-align: center;
		padding: 0;
	}
}
