$breakpoint-md: 780px;
$breakpoint-sm: 425px;
$breakpoint-x-sm: 350px;
$breakpoint-img: 715px;

#add-users-modal {
	display: none; /* Hidden by default */
	position: fixed; /* Stay in place */
	z-index: 1; /* Sit on top */
	left: 0;
	top: 0;
	z-index: 99999;
	width: 100%; /* Full width */
	height: 100%; /* Full height */
	overflow: auto; /* Enable scroll if needed */
	background-color: rgb(0,0,0); /* Fallback color */
	background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
	-webkit-transition: opacity 100ms ease-in;
	-moz-transition: opacity 100ms ease-in;
	transition: opacity 100ms ease-in;
    justify-content: center;
    align-items: center;

	.add-users-modal-content {
		display: flex;
		flex-direction: column;
		align-items: center;
		max-width: 1009px;
        max-height: 100%;
        width: 95%;
		text-align: left;
        padding: 50px 20px 50px 20px; // small screens
		position: relative;
		border-radius: 7px;
		background: white;
        @media (min-width: $breakpoint-sm) {
            padding: 67px 46px 46px 46px; // medium screens
        }
        @media (min-width: $breakpoint-md) {
            padding: 46px 67px; // larger screens
        }
	}

    // main containers
    #add-users-content, #reached-limit-content {
        width: 100%;
        height: 100%;
    }
    #reached-limit-content{
        display: none;

        h3 {
            font: normal normal normal 20px/31px Arial;
            margin-bottom: 20px;
            @media (min-width: $breakpoint-sm) {
                font: normal normal normal 27px/31px Arial;
            }
        }
        p {
            font: normal normal normal 14px/26px Arial;
            @media (min-width: $breakpoint-sm) {
                font-size: 18px;
            }
        }

        .list-title{
            @extend .text-defaults;
            @extend .text-light-gray;
            font: normal normal bold 14px/26px Arial;
            @media (min-width: $breakpoint-sm) {
                font-size: 18px;
            }
        }
        .icon-title-container{
            @extend .flex;
            gap: 16px;
            align-items: center;
        }
        .list-item-container{
            margin-left:70px;
        }

        .addon-button {
            @extend .green-cta;
            width: 213px;
        }
        .pro-button {
            @extend .green-cta;
            width: 136px;
        }
    }
    .main-content-container {
        @extend .flex;
        width: 100%;
        @media (min-width: $breakpoint-img) {
            gap: 20px;
        }
        @media (min-width: $breakpoint-md) {
            gap: 90px;
        }
    }

    // universal
    .soft-gray-border {
        border: 1px solid #EBEBEB;
    }
    .text-defaults {
        text-align: left;
        letter-spacing: 0px;
        color: #162B32;
        opacity: 1;
    }
    .font-14 {
        font: normal normal normal 14px/20px Arial;
    }
    .font-15 {
        font: normal normal normal 15px/28px Arial;
    }
    .font-bold-15 {
        font: bold normal normal 15px/28px Arial;
    }
    .font-13 {
        font: normal normal normal 13px/20px Arial;
    }
    .font-18 {
        font: normal normal normal 16px/26px Arial;
        @media (min-width: $breakpoint-sm) {
            font-size: 18px;
        }
    }
    .w-100 {
        width: 100%;
    }
    .text-light-gray {
        color: #707070;
    }
    .flex{
        display: flex;
    }
    .margin-bottom-30{
        margin-bottom: 20px;
        @media (min-width: $breakpoint-sm) {
            margin-bottom: 30px;
        }
    }

    // text
    h3 {
        @extend .text-defaults;
        font: normal normal normal 24px/31px Arial;
        color: #024059;
        margin: 0;
        margin-bottom: 30px;
        @media (min-width: $breakpoint-sm) {
            font: normal normal normal 27px/31px Arial;
        }
        @media (min-width: $breakpoint-md) {
            margin-bottom: 20px;
        }
    }
    p {
        @extend .font-18;
        @extend .text-defaults;
		@extend .text-light-gray;
        margin: 0;
        @media (min-width: $breakpoint-img) {
            max-width: 403px;
        }
	}
    .first-p {
        margin-bottom: 30px;
    }
    .second-p {
        margin-bottom: 30px;
        @media (min-width: $breakpoint-sm) {
            margin-bottom: 34px;
        }
        @media (min-width: $breakpoint-md) {
            margin-bottom: 30px;
        }
    }

    // table
    .table-wrapper {
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
        margin-top: 30px;
        max-height: 152px;
        overflow-y: auto;
        @extend .soft-gray-border;
        border-right: none;
        border-left: none;
        &::-webkit-scrollbar-thumb{
            background: #DDDDDD 0% 0% no-repeat padding-box;
            border-radius: 0px 5px 0px 0px;
            opacity: 1;
        }
        &::-webkit-scrollbar {
            width: 10px;
        }
        &::-webkit-scrollbar-track {
            @extend .soft-gray-border;
            border-left: none;
            border-top: none;
            border-bottom: none;
            border-top-right-radius: 5px;
        }
        @media (min-width: $breakpoint-md) {
            margin-top: 50px;
        }
    }

    table{
        width: 100%;
        tbody {
            tr {
                height: 38px;
            }
            th {
                @extend .font-bold-15;
                border-top: none;
            }
            th, td {
                @extend .text-defaults;
                @extend .soft-gray-border;
                border-collapse: collapse;
                padding: 8px;
                height: 100%;
                vertical-align: middle;
            }
            .td-count {
                @extend .font-14;
            }
            .td-email, .td-admin {
                @extend .font-13;
            }
            .td-email {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                max-width: 1px;
            }
            &:last-child{
                td {
                    border-bottom: none;
                }
            }
        }
    }

    // images
    .meeples-3-container {
        display: none;
        @media (min-width: $breakpoint-img) {
            display: block;
            max-width: 388px;
            width: 50%; 
            background-image: url('/images/3-batch-avatars.png'); 
            background-size: contain; 
            background-repeat: no-repeat;
            background-position: center;
        }
    }
    .meeples-5-container {
        display: none;
        @media (min-width: $breakpoint-img) {
            display: block;
            max-width: 416px;
            width: 50%; 
            background-image: url('/images/5-batch-avatars.png'); 
            background-size: contain; 
            background-repeat: no-repeat;
            background-position: center;
        }
    }

    // inputs and buttons
    #add-user-email {
        @extend .font-18;
        @extend .text-defaults;
		@extend .text-light-gray;
        @extend .soft-gray-border;
        width: 100%;
    }
    button, input {
        min-height: 43px;
    }
    .input-container {
        position: relative;

        .button-input-group {
            display: flex;
            gap: 9px;
        }
        #add-user-button {
            width: 95px;
        }
        #input-error {
            @extend .font-13;
            color: red;
            position: absolute;
            top: -25px;
        }
    }
    .buttons-container {
        @extend .flex;
        justify-content: space-between;
        align-items: end;
        margin-top: 35px;
        @media (min-width: $breakpoint-sm) {
            margin-top: 70px;
            justify-content: end;
        }
        @media (min-width: $breakpoint-md) {
            margin-top: 50px;
        }
    }
    button {
        &:hover{
            cursor: pointer;
        }
    }
    .green-cta {
        background: transparent linear-gradient(180deg, #77B02B 0%, #4F7E0F 100%) 0% 0% no-repeat padding-box;
        border: 1px solid #68922F;
        border-radius: 5px;
        @extend .font-18;
        @extend .text-defaults;
        color: white;
        text-align: center;
        
        &:disabled {
            background: gray;
            border: rgb(112, 112, 112);
        }
    }
    .complete-button {
        @extend .green-cta;
        width: 126px;
    }
    .skip-button {
        color: black;
        background: transparent linear-gradient(180deg, #F9C81B 0%, #C7AD28 100%) 0% 0% no-repeat padding-box;
        border: 1px solid #000000;
        border-radius: 5px;
        width: 126px;
        @extend .font-18;
        letter-spacing: 0px;
        opacity: 1;
        margin-right: 13px;
    }
    #close-modal{
        position: absolute;
        top: 10px;
        right: 13px;
        height: 13.5px;
        min-height: 13.5px;
        width: 13.5px;
        display: flex;
        justify-content: center;
        align-items: center;
        //remove default styles
        border: none;
        background: inherit;
        padding: 0;
    }
}