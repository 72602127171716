/* My Account =================== */
.loading-icon {
  cursor: wait;
  background-color: rgba(0, 0, 0, 0);
  border: 3px solid #ffc715;
  border-top: 3px solid rgba(0, 0, 0, 0);
  border-left: 3px solid rgba(0, 0, 0, 0);
  opacity: 0.8;
  border-radius: 50px;
  margin: 5% auto;
  -moz-animation: loading 0.5s infinite linear;
  -webkit-animation: loading 0.5s infinite linear;
  animation: loading 0.5s infinite linear;
  width: 30px;
  height: 30px;
}
@keyframes loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@-moz-keyframes loading {
  0% {
    -moz-transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

.admin-users-table-wrap {
  overflow-x: auto;
  th,
  td.title,
  td.action > a {
    font-size: 12px !important;
  }
  th,
  td {
    height: 46px !important;
  }
  td.action {
    text-align: center !important;
  }
  @media (min-width: 516px) {
    th,
    td.title,
    td.action > a {
      font-size: 16px !important;
    }
  }
}

body.admin table.data {
  width: 100%;
  border: 1px solid #ddd;
  border-left-width: 0;
  border-collapse: separate;
  border-spacing: 0;
  border-radius: 6px 6px 0 0;

  th,
  td {
    padding: 6px 8px;
    border: 1px solid #ddd;
    border-width: 1px 0 0 1px;
    text-align: left;
  }

  th {
    font-weight: bold;
    border-top-width: 0;
    background: #fff;
  }

  td {
    font-size: 12px;
  }

  td.title {
    font-size: inherit;
    strong {
      font-weight: bold;
    }
    small {
      font-size: 12px;
    }
  }

  th:first-child {
    border-radius: 6px 0 0 0;
  }

  th:last-child {
    border-radius: 0 6px 0 0;
  }

  th:only-child {
    border-radius: 6px 6px 0 0;
  }

  .center {
    text-align: center;
  }

  .updated {
    width: 100px;
  }
  .action {
    &.edit-action {
      width: 65px;
    }
    &.export-action {
      width: 186px;
    }
    &.manage-user {
      width: 137.25px;
    }
  }

  a.button {
    margin: 2px 2px;
    padding: 6px 6px 5px;
  }
}

@media screen and (max-width: 557px) {
  body.admin table.data {
    .admin-map-bulk-checkbox {
      @include flex(1);
    }

    .admin-map-table-title {
      @include flex(12);
    }

    .updated {
      width: auto;
    }

    .action {
      &.export-action {
        .button {
          display: block;
          text-align: center;
        }
        width: auto;
      }
    }
  }
}

/*Subscription Pricing Table*/

#pricing-cards-wrapper {
  flex-direction: row;
  margin-bottom: 50px;
  display: flex;
  .pricing-card {
    padding: 0 25px 0 0;
    display: flex;
    justify-content: stretch;
    flex: 1;
    display: none;
    &.show {
      display: flex;
    }
    &.last {
      padding: 0;
    }
    .card-content {
      background: #fff;
      border: 1px solid #ddd;
      border-radius: 8px;
      text-align: center;
      padding: 30px 15px;
      background-clip: padding-box;
      position: relative;
      margin-bottom: 6px;
      flex: 1;
      @include box-shadow(0 1px 4px rgba(0, 0, 0, 0.2));
      h1 {
        font-weight: 200;
        font-size: 3em;
        color: #515050;
        margin: 0;
        line-height: 1em;
      }
      .price {
        font-weight: 600;
        font-size: 3.75em;
        color: #ffc715;
        margin: 0;
        line-height: 1.25em;
        &.enterprise {
          font-size: 1.625em;
          margin: 10px 0 42px 0;
        }
      }
      p {
        font-size: 0.75em;
        margin-bottom: 25px;
      }
      ul {
        list-style: none;
        margin: 0;
        li {
          border-bottom: 1px solid #dadada;
          margin-bottom: 10px;
          padding-bottom: 10px;
          list-style: none;
          &:last-child {
            border-bottom: 0;
          }
        }
      }
      .popular {
        background-color: #ffd449;
        color: #515050;
        font-size: 0.875em;
        position: absolute;
        padding: 0.25em 3em;
        left: -2.5em;
        font-weight: 600;
        top: 2.5em;
        @include transform(rotate(-45deg));
      }

      .price-wrapper {
        display: flex;
        justify-content: center;
        height: 125px;
        position: relative;
        width: 100%;
      }
      .button-wrapper {
        display: flex;
        justify-content: center;
        height: 60px;
        position: relative;
        width: 100%;
        .button {
          margin-bottom: 0;
          // width:80%;
        }
      }
      .popular {
        background-color: #ffd449;
        color: #515050;
        font-size: 0.875em;
        position: absolute;
        padding: 0.25em 3em;
        left: -2.5em;
        font-weight: 600;
        top: 2.5em;
        @include transform(rotate(-45deg));
      }
      &.clip {
        overflow: hidden;
      }
      &.selected {
        border: 4px solid #ffc715;
        margin-top: -3px;
        position: relative;
        margin-bottom: 3px;
        &:before {
          display: block;
          position: absolute;
          left: 50%;
          margin-left: -20px;
          top: -20px;
          background: #64991e;
          width: 40px;
          height: 40px;
          content: "\e90e";
          border-radius: 50px;
          font-family: "batchgeotoolbaricons";
          color: #fff;
          font-size: 36px;
          line-height: 38px;
        }
      }
    }
    .button.disabled {
      position: relative;
    }

    .button.disabled:before {
      position: relative;
      top: 10px;
      display: inline-block;
      width: 10px;
      height: 10px;
      content: "\e90e";
      font-family: batchgeotoolbaricons;
      color: #64991e;
      font-size: 36px;
      line-height: 0;
      margin-right: 30px;
      margin-left: -10px;
    }
  }
  @include media("screen", "<=tablet") {
    flex-direction: column;
    margin-bottom: 50px;
    display: flex;
    .pricing-card {
      padding: 0;
    }
  }
  @include media("screen", "<=phone") {
    margin-top: 0;
    .pricing-card {
      padding: 15px;
    }
  }
}

#manage-subscription {
  h2 {
    color: #585757;
    font-size: 20px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
  }
}

.account-panel {
  border-radius: 5px;
  margin-bottom: 12px;
}
.update-billing-top {
  display: flex;
  flex-direction: row;
  p {
    span {
      color: #eee;
      padding: 0 15px;
    }
  }
}

#sign_up_form {
  .header {
    font-size: 20px;
  }
  .amount {
    margin: 0;
    color: 5857757;
    font-size: 16px;
  }
  .cancel-buttons {
    display: flex;
    gap: 14px;
    vertical-align: center;
    .button {
      margin: 0 15px 0 0;
    }
    label {
      display: flex;
      align-items: center;
      padding: 0;
    }
    @include media("screen", "<=phone") {
      flex-direction: column;
      align-items: center;

      .button {
        margin: 0.5rem 0 1.5rem;
      }
      label {
        margin-right: 0;
      }
    }
  }
}

.annual-toggle-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  background: rgba(255, 255, 255, 0.1);
  max-width: 440px;
  margin-top: -200px;
  color: #fff;
  margin-bottom: 50px;
  border-radius: 8px;
  p {
    display: flex;
    flex: 1;
    margin: 15px 10px;
    font-size: 16px;
    letter-spacing: 0.2rem;
    text-transform: uppercase;
    justify-content: center;
    opacity: 0.4;
    -webkit-transition: opacity 0.25s ease-in-out;
    -moz-transition: opacity 0.25s ease-in-out;
    -o-transition: opacity 0.25s ease-in-out;
    transition: opacity 0.25s ease-in-out;
    &.active {
      opacity: 1;
    }
  }
  .toggle-btn {
    width: 60px;
    height: 30px;
    margin: 0 10px;
    border-radius: 50px;
    display: inline-block;
    position: relative;
    background: rgba(255, 199, 21, 0.75);
    cursor: pointer;
    -webkit-transition: background-color 0.4s ease-in-out;
    -moz-transition: background-color 0.4s ease-in-out;
    -o-transition: background-color 0.4s ease-in-out;
    transition: background-color 0.4s ease-in-out;
    cursor: pointer;
    &.active {
      .round-btn {
        left: 35px;
      }
    }
    .round-btn {
      width: 20px;
      height: 20px;
      border: 1px solid #fff;
      background-color: rgba(255, 255, 255, 0.75);
      border-radius: 50%;
      display: inline-block;
      position: absolute;
      left: 5px;
      top: 50%;
      margin-top: -10px;
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
      box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
    }
    .cb-value {
      position: absolute;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      z-index: 9;
      cursor: pointer;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    }
  }

  &.manage-subscription-toggle {
    margin-top: 10px;
    margin-bottom: 15px;
    background: #fff;
    border: none;
    color: #555;
  }

  @include media("screen", "<=tablet") {
    margin-top: -80px;
  }
}

.pricing-table-container {
  margin-top: 20px;
}

.no-pricing-table-container {
  border-top: none;
  margin-top: 20px;
  margin-bottom: 50px;
}

/*Receipts - Invoice Generator Styles*/

#feature {
  margin-bottom: 50px;

  .centered {
    text-align: center;
  }

  a.green.button {
    color: #fff;
  }
}
#submit-generate {
  margin: 0 0 8px;
  display: inline-block;
  margin-top: 10px;
  width: 100%;
  border-top: 1px solid #eee;
  padding-top: 20px;
}
.custom-info-container {
  padding: 25px;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 6px;
  margin-bottom: 25px;
  display: none;
}
.custom-info-container label {
  font-size: 14px;
  clear: both;
  display: block;
  margin: 8px 0 4px 0;
  color: #585757;
}
.custom-info-container .field {
  margin: 0 0 15px;
  width: 70%;
}
.custom-info-container input[type="text"],
.custom-info-container textarea {
  width: 100%;
  padding: 5px;
}

.custom-info-container textarea {
  height: 200px;
  border-color: #d7d7d7;
}
.custom-info-container .green.button {
  margin-top: 25px;
  margin-right: 25px;
  width: auto;
}

.error {
  font-weight: bold;
  color: #ff0000;
}

.billing-modal-text {
  font-size: 12px;
}
.billing-modal-header {
  margin: 0px;
}
