/******************************************************************
BatchGeo
Stylesheet: Site Content
******************************************************************/

#content {
  padding: 60px 0 0;
}

@media all and (min-width: 768px) {
  #content {
    padding: 100px 0 0;
  }
}
html {
  text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}
/* @media all and (max-width: 400px) {
	html {
		font-size: 14px;
	}
} */

/****** These styles are for the VIDEO POD ON HOMEPAGE ******/

#video {
  text-align: center;

  p {
    margin: 9px 0;
  }
  img {
    border: 2px solid #ccc;
    width: 100%;
  }
  div {
    padding: 60px 0 40px;

    a {
      &:active,
      &:hover {
        border-bottom: 0 none;
      }
    }
  }
}

/****** These styles are for the MOBILE CALLOUT POD ON HOMEPAGE ******/

#mobile-callout {
  padding: 60px 0 20px;

  h2 {
    margin-bottom: 40px;
    text-align: center;
  }
}

#publishers {
  margin-bottom: 3em;
  padding: 1em 0;
  text-align: center;
}
#publishers h2 {
  text-align: left;
}
#publishers p {
  margin: 0.5em 0 0;
  padding: 0.5em 0 0;
}
#publishers a.round,
#testimonials a.round {
  border: 0 none;
  background: #dfdfdf;
  color: #333;
  font-size: 14px;
  display: block;
  margin-top: 2em;
  padding: 8px;
}
#publishers a.round:hover,
#testimonials a.round:hover {
  background: #ccc;
}

/******************************************************************
These are the styles for the PRESS LOGOS SECTION
******************************************************************/

#pressDiv {
  width: 100%;
  text-align: center;
  margin-top: 3.5em;
  margin-bottom: 4.5em;
}

ul.centerPress {
  margin: 0 auto;
}
.centerPress li {
  display: inline-block;
  text-align: center;
  margin-right: 1em;
  overflow: hidden;
}
.centerPress li.lastli {
  display: inline-block;
  text-align: center;
  margin-right: 0;
  overflow: hidden;
}
.list-press {
  display: block;
  height: 40px;
  background: url($baseURL + "/images/press-sprite.png") top left no-repeat;
}
@media only screen and (-webkit-min-device-pixel-ratio: 2) {
  .list-press {
    display: block;
    background: url($baseURL + "/images/press-sprite@2x.png") no-repeat;
    background-size: 820px 40px;
  }
}
#content-wrap {
  background: #eee;
  padding: 0 0 72px 0;
}

#content-wrap.slideshow {
  padding: 2em 0;
  margin-bottom: 3em;
}
.slideshow > #content > img {
  margin-left: 0px;
}

.toggle-billing {
  left: 50%;
  margin-left: -2%;
  position: relative;
  width: 110px;
}
.switch {
  background: $white;
  @include box-shadow(inset 0 0 3px rgba(black, 0.1));
  @include border-radius(14px);
  display: block;
  height: 20px;
  margin-bottom: 1em;
  position: relative;
  width: 38px;
}
.switch label {
  color: $gray;
  font-size: $small;
  font-weight: 500;
  line-height: 20px;
  @include transition(color 0.2s ease);
  width: 90px;
}
.switch label:nth-of-type(1) {
  left: -250%;
  position: absolute;
  text-align: right;
}
.switch label:nth-of-type(2) {
  position: absolute;
  right: -250%;
  text-align: left;
}
.switch input {
  height: 16px;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 36px;
  z-index: 2;
}

.switch input:checked ~ label:nth-of-type(1) {
  color: $black;
}
.switch input:checked ~ label:nth-of-type(2) {
  color: $gray;
}

.switch input ~ :checked ~ label:nth-of-type(1) {
  color: $gray;
}
.switch input ~ :checked ~ label:nth-of-type(2) {
  color: $black;
}

.switch input:checked ~ .toggle {
  left: 2px;
}
.switch input ~ :checked ~ .toggle {
  left: 19px;
}
.switch input:checked {
  z-index: 0;
}

.toggle {
  background: #4a4a4a;
  @include border-radius(50%);
  height: 16px;
  left: 0;
  position: absolute;
  top: 2px;
  @include transition(left 0.2s ease);
  width: 16px;
  z-index: 1;
}
.pricing {
  @extend %clearfix;
  clear: both;
  list-style-type: none;
  margin: 0 auto 0.5em;
  max-width: 100%;

  ul {
    list-style-type: none;
    margin: 0;
  }

  > li {
    @include box-shadow(0 1px 4px rgba(0, 0, 0, 0.2));
    @include border-radius(4px);
    overflow: hidden;
    padding: 1em;
    position: relative;

    li {
      border-bottom: 1px solid lighten($gray, 12%);
      font-size: $small;
      margin-bottom: 0;
      padding: 0.5em;

      &:last-child {
        border-bottom: 0 none;
      }
    }

    &.pop {
      @include box-shadow(0 1px 6px lighten($alert-yellow, 10%));
      background-color: lighten($alert-yellow, 32%);

      .price span {
        color: darken($alert-yellow, 20%);
      }

      > span {
        background-color: $alert-yellow;
        color: darken($alert-yellow, 30%);
        font-size: 0.5em;
        position: absolute;
        padding: 0.25em 3em;
        left: -3em;
        text-transform: uppercase;
        top: 2em;
        @include transform(rotate(-35deg));
      }

      li {
        border-bottom-color: lighten($alert-yellow, 20%);
      }
    }
  }

  .th {
    border: none;
    padding: 0;

    h2 {
      color: #000;
      font-size: 32px;
      font-weight: 100;
      letter-spacing: 1px;
      margin-bottom: 0;
      text-align: center;
      white-space: nowrap;
    }
    .price {
      font-size: 44px;
      font-weight: normal;
      text-align: center;
      left: 3%;
      line-height: 1;
      position: relative;

      sup {
        font-size: 18px;
        position: relative;
        top: -19px;
      }

      span {
        color: $gray;
        font-size: 16px;
        position: relative;
        left: -12px;
      }
    }
    .button {
      width: 100%;
    }
  }
}

/****** These styles are for the PRO PRICING PAGE ******/

#pro-intro {
  h1 {
    margin-bottom: 0.5em;
    font-size: 32px;

    span {
      font-weight: 300;
      letter-spacing: 1px;
      white-space: nowrap;
    }
  }
  ul {
    margin-bottom: 2.5em;
  }
  .proprice {
    font-size: 50px;
    font-weight: normal;
    text-align: center;

    em {
      color: #666;
      font-size: 12px;
      margin-left: -39px;
    }
    sup {
      font-size: 24px;
      position: relative;
      top: -16px;
    }
  }
}

.pro-tag {
  margin-bottom: 3em;

  h2 {
    color: #333;
    font-size: 22px;
    font-weight: normal;
    margin: 1em 0;
    padding: 30px 0 5px;
    text-align: center;
  }
  h2 strong {
    font-weight: bold;
  }
  h2 strong:first-of-type {
    background: url($baseURL + "/images/underline@2x.svg") no-repeat 0px 34px;
    background-size: 115px 7px;
    padding: 10px 0;
  }
}
.free-plan {
  clear: both;
  font-size: $small;
  text-align: center;

  h3 {
    font-size: 12px;
    margin-bottom: 0;
  }

  strong {
    font-weight: 700;
  }
}
#how-to-use {
  margin: 20px 0 60px;
}

/****** These styles are for the GO PRO SECTION OF HOMEPAGE ******/

#go-pro {
  background: #fff;

  #anchor-wrapper {
    text-decoration: none;
    color: inherit;
  }

  ul {
    margin: 0;
    list-style: none;
  }

  li {
    margin-bottom: 9px;
    padding: 4px;

    h3 {
      color: $color-primary;
      font-size: 1.25rem;
    }
    @include media("screen", "<phone") {
      margin-bottom: 4px;

      h3 {
        margin-bottom: 0;
      }
    }
  }

  .go-pro-content {
    padding-top: 1rem;
    padding-bottom: 2rem;
    @include media("screen", "<phone") {
      padding-top: 0;
    }
  }

  .flex-columns-item {
    @media screen and (min-width: 600px) {
      padding: 0 0.5rem 1rem;
    }
  }
}

#testimonials {
  margin-bottom: 3em;
}
#testimonials .more {
  border: 0 none;
  display: block;
  text-align: center;
  margin: 1em auto;
  width: 40%;
}
#favorites ul {
  margin: 0;
}
#favorites li {
  @include border-radius(6px);
  font-size: 0.75em;
  list-style-type: none;
  margin-left: 0;
  text-align: left;
  clear: both;
  width: 100%;
}
#favorites li div {
  background: #e1eef3;
  @include border-radius(3px);
  display: block;
  min-height: 120px;
  padding: 1em;
}
.tweet_avatar {
  background: #efefef;
  display: block;
  float: left;
  padding: 0.3em;
  margin: 0.3em;
}
.tweet_avatar:hover {
  border: none;
}
.tweet_avatar img {
  display: block;
}
.tweet_time,
.tweet_user {
  display: block;
  font-size: 10px;
}
.tweet_user {
  font-weight: bold;
}

#mapCount {
  font-weight: bold;
}

// on lite pricing page
#go-pro a {
  margin-top: 12px;
}

// on pro pricing page
#pro-cta {
  margin: 1em auto;

  h2 {
    margin-bottom: 8px;
  }

  a {
    margin-top: 12px;
  }
}
#pro-cta.pricing1 {
  padding-bottom: 4rem;
}

.intro {
  font-size: 1.25rem;
  margin-bottom: 1.5rem;
  @media screen and (max-width: 599px) {
    font-size: 1.0625rem;
  }
}

.mobile h2 {
  margin-bottom: 18px;
}
.mobile a {
  border: 0 none;
}
.mobile ul {
  margin-bottom: 50px;
}
#webapp .fourcol p {
  margin-top: 3.3em;
}
.offer_to_sign_in {
  margin-bottom: 1em;
}

#sign_up_form,
#prepaid_form {
  fieldset {
    background: #eee;
    margin-bottom: 2em;
    padding: 26px 30px 30px;
    text-align: left;

    @include media("screen", "<phone") {
      padding: 24px 10px 30px;
    }
  }
  h1 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
    text-align: center;
  }
  .field {
    margin-bottom: 0;
    margin: 0 25%;
  }
  .field span {
    display: block;
    font-size: 11px;
  }
  label {
    display: block;
    margin-right: 10px;
    padding: 8px 0 2px;
  }
  b {
    font-weight: bold;
  }
  input[type="text"],
  #sign_up_form input[type="password"],
  #prepaid_form input[type="password"] {
    box-sizing: border-box;
    padding: 5px;
    font-size: 14px;
    width: 96%;
  }
  input.full-width {
    box-sizing: border-box;
    width: 100%;
  }
  label[for="card_expires_on_month"] {
    margin-bottom: 6px;
  }
  .buttons {
    margin-top: 20px;
    text-align: center;
  }
  .g-recaptcha-container {
    text-align: center;
    margin-bottom: 20px;
  }

  .g-recaptcha {
    display: inline-block;
  }
}

.admin #sign_up_form .field,
.admin #prepaid_form .field {
  /* margin: 0 25%; */
  max-width: 240px;
  margin: 0 auto;
  position: relative;
}
@include media("screen", "<phone") {
  .admin #sign_up_form .field,
  .admin #prepaid_form .field {
    max-width: 90%;
    margin: 0 auto;
    position: relative;
  }
}

.admin_narrow_content {
  max-width: 240px;
  margin-left: auto;
  margin-right: auto;
}
.admin_narrow_content.extra_narrow {
  max-width: 200px;
}

.disc_outline {
  display: inline-block;
  width: 0.4rem;
  height: 0.4rem;
  margin-left: -0.85rem;
  background: transparent;
  border: currentColor solid 1px;
  border-radius: 50%;
  position: relative;
  top: -2px;
}

#credit_card_form {
  .expiration {
    @include flexbox;
  }
  #card_expires_on_month {
    margin-right: 5px;
  }
  select {
    @include flex-grow(1);
  }
}

.signup-billing-address {
  display: flex;
  flex-wrap: wrap;
  input {
    width: 290px;
    margin-right: 20px;
  }
  select {
    width: 290px;
  }
  .field {
    width: auto !important;
  }
}

.secure {
  display: inline-block !important;
  font-size: 14px !important;
  line-height: 22px;
  color: #0a0;
  padding-left: 26px;
  margin-right: -90px;
  background: url($baseURL + "/images/icon-lock.png") top left no-repeat;
}
@media only screen and (-webkit-min-device-pixel-ratio: 2) {
  .secure {
    background: url($baseURL + "/images/icon-lock@2x.png") top left no-repeat;
    background-size: 22px 22px;
  }
}

.billing-address {
  display: flex;
  flex-wrap: wrap;
  input {
    width: 200px;
    margin-right: 50px;
  }
  select {
    width: 200px;
  }
}

#update_billing_form .field .secure {
  position: absolute;
  bottom: 0.3rem;
  left: calc(100% + 8px);
  margin: 0;
}
@include media("screen", "<phone") {
  #update_billing_form .field .secure {
    bottom: initial;
    left: initial;
    top: 0.25rem;
    right: 0;
  }
}
#captcha_element {
  width: 304;
  margin: 20px auto;
}
#captcha_element > div {
  margin: 0 auto;
}
@media screen and (max-width: 340px) {
  #captcha_element {
    width: 264px;
    margin: 20px auto;
  }
  #captcha_element > div {
    width: 264px !important;
    overflow-x: scroll;
  }
  #captcha_element > div iframe {
    max-width: 100% !important;
    overflow: hidden;
  }
}

.amount {
  margin-top: 18px;
}
.billingpoints h2 {
  margin-bottom: 0;
}
.right.signup h2 {
  margin-bottom: 0;
  margin-top: 50px;
}
.instruction {
  font-size: 0.925em;
}
.admin-instruction {
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
}

/******************************************************************
These are the styles for the MOBILE APP PAGE
******************************************************************/

#mobile-intro {
  background: #6ecbf9; /* Old browsers */
  background: -moz-radial-gradient(
    60% bottom,
    ellipse cover,
    #6ecbf9 0%,
    #ffffff 66%,
    #ffffff 100%
  ); /* FF3.6-15 */
  background: -webkit-radial-gradient(
    60% bottom,
    ellipse cover,
    #6ecbf9 0%,
    #ffffff 66%,
    #ffffff 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: radial-gradient(
    60% bottom,
    #6ecbf9 0%,
    #ffffff 66%,
    #ffffff 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#6ecbf9', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
  padding-top: 2em;

  .app-button {
    width: 80%;
    margin: 1em 0;
    color: #fff;
    border-color: #585757;

    a {
      color: #fff !important;
    }
  }
}

.button {
  color: #ffffff;
}
#key-features {
  background-image: url(/images/topo-bg.png);
  padding: 60px 0;
}

a.at-app-store {
  border: none;
}

figure {
  text-align: center;
  border-radius: 2px;
  margin: 1em 2em;
  color: #999;
}
figcaption {
  padding: 1em;
}

/******************************************************************
These are the styles for the BLOG
******************************************************************/

article strong {
  font-weight: 700;
}

.text-center {
  text-align: center;
}

h1.hero_hdr {
  font-size: 4rem;
  margin: 0.5em 0;
  @media screen and (max-width: 1024px) {
    font-size: 3.5rem;
  }
  @media screen and (max-width: 768px) {
    font-size: 3rem;
  }
  @media screen and (max-width: 599px) {
    font-size: 2rem;
  }
}

h1.section_hdr {
  margin-bottom: 2rem;
}

h1.section_hdr,
h2.section_hdr {
  font-size: 3rem;

  @media screen and (max-width: 1024px) {
    font-size: 2.75rem;
  }

  @media screen and (max-width: 768px) {
    font-size: 2.25rem;
  }

  @media screen and (max-width: 599px) {
    font-size: 1.75rem;
  }
}
h1.section_hdr.big,
h2.section_hdr.big {
  font-size: 3.8rem;

  @media screen and (min-width: 769px) and (max-width: 1024px) {
    font-size: 3.25rem;
  }

  @media screen and (min-width: 600px) and (max-width: 768px) {
    font-size: 2.75rem;
  }
  @media screen and (max-width: 599px) {
    font-size: 1.75rem;
  }
}
h2.section_subhdr {
  font-size: 2.2rem;

  @media screen and (max-width: 1024px) {
    font-size: 2rem;
  }

  @media screen and (max-width: 768px) {
    font-size: 1.75rem;
  }

  @media screen and (max-width: 599px) {
    font-size: 1.35rem;
  }
}
h3.section_subhdr {
  font-size: 2rem;

  @media screen and (max-width: 1024px) {
    font-size: 1.75rem;
  }

  @media screen and (max-width: 768px) {
    font-size: 1.5rem;
  }

  @media screen and (max-width: 599px) {
    font-size: 1.35rem;
  }
}

.centered_hdr {
  max-width: 786px;
  margin-left: auto;
  margin-right: auto;
}

/* Flex utility classes */
.flex-align-center {
  @include align-items(center);
}
.flex-space-between {
  @include justify-content(space-between);
}
.flex-space-evenly {
  @include justify-content(space-evenly);
}

/* Flex tablet and desktop columns */
@media screen and (min-width: 760px) {
  .flex-columns-two {
    @include flexbox();
    /* @include align-items(flex-start); */
  }
  .flex-columns-three {
    @include flexbox();
    /* @include align-items(flex-start); */
  }
  .flex-columns-two .flex-columns-item {
    flex-basis: 50%;
  }
  .flex-columns-two.two-thirds .flex-columns-item {
    flex-basis: 33%;
  }
  .flex-columns-two.two-thirds .flex-columns-item.two {
    flex-basis: 67%;
  }
  .flex-columns-two.two-thirds.pad .flex-columns-item {
    flex-basis: 28%;
  }
  .flex-columns-two.two-thirds.pad .flex-columns-item.two {
    flex-basis: 62%;
  }
  .flex-columns-two.pad .flex-columns-item {
    flex-basis: 47%;
  }
  .flex-columns-three .flex-columns-item {
    flex-basis: 33.333%;
  }
  .flex-columns-three.pad .flex-columns-item {
    flex-basis: 31%;
  }
}

.hero {
  .intro {
    font-size: 1.5rem;

    @media screen and (max-width: 900px) {
      font-size: 1.25rem;
    }

    @media screen and (max-width: 599px) {
      padding-bottom: 1em;
      font-size: 1.125rem;
    }
  }
}
.section-pad {
  padding: 5rem 0 4rem;
  @media screen and (min-width: 600px) and (max-width: 1024px) {
    padding: 3.5em 0 2.5rem;
  }
  @media screen and (max-width: 599px) {
    padding: 2.5em 0 1.5rem;
  }
}
.hero {
  padding: 4em 0;

  @media screen and (min-width: 600px) and (max-width: 1024px) {
    padding: 3em 0;
  }

  @media screen and (max-width: 599px) {
    padding: 2em 0;
  }
}

.bg-blue {
  background: $color-primary-dark;
  color: #fff;
}
.bg-gray {
  background-color: #f0f2f3;
}
.gradient-gray {
  background: #eee;
  @include css-gradient(#fff, #eee);
}
.bg-map-blue {
  /* background-color: $color-primary;
	background-image:	url(/images/step-by-step-bg.svg);
	background-repeat: no-repeat;
	background-position: center center;
	background-blend-mode: multiply; */
  background: url(/images/blue-map-bg.svg) no-repeat center center;
  background-size: cover;
  color: #fff;
}
.bg-map-blue a {
  color: #fff;
  /* text-decoration: underline; */
}
.bg-map-blue a.button {
  text-decoration: none;
}

.callout-one-column,
.callout-one-column.wrap {
  max-width: 800px;
  padding: 6.5rem 0;

  p {
    font-size: 1.25rem;
    margin-bottom: 1.6em;

    @media screen and (max-width: 599px) {
      font-size: 0.9375rem;
    }
  }

  @media screen and (min-width: 600px) and (max-width: 1024px) {
    padding: 4rem 0;
  }
  @media screen and (max-width: 599px) {
    padding: 2rem 0;
  }
}

/* New Home 3 Simple Steps */
.list-steps {
  margin: 3rem 0 0;
  list-style: none;
  padding: 3rem 0 1.5rem;
  color: #fff;

  @media screen and (max-width: 599px) {
    padding: 0;
  }
}

.list-steps-item {
  display: flex;
  @include justify-content(space-between);
  @include align-items(center);

  @media screen and (max-width: 599px) {
    @include flex-direction(column);
    padding-bottom: 2.5rem;
  }
}
.list-steps-item:last-of-type .list-steps-img {
  margin-top: -3rem;
  margin-bottom: -3rem;
}
@media screen and (max-width: 599px) {
  .list-steps-item:first-of-type .list-steps-img {
    margin-bottom: -2rem;
  }
  .step2 .list-steps-img {
    margin-bottom: -3rem;
  }
  .list-steps-item:last-of-type .list-steps-img {
    margin-bottom: -4rem;
  }
}
.list-steps-txt {
  padding: 0 9% 1rem;

  @media screen and (min-width: 600px) and (max-width: 900px) {
    flex-basis: 60%;
    padding: 0 4% 0 8%;
    font-size: 14px;
  }
  @media screen and (max-width: 599px) {
    font-size: 12px;
  }
  @media screen and (min-width: 901px) {
    flex-basis: 50%;
    padding: 1rem 5% 1rem 9%;
  }

  h3 {
    position: relative;
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 0.7em;
    text-transform: capitalize;

    @media screen and (max-width: 1024px) {
      font-size: 2.5rem;
    }
    @media screen and (max-width: 768px) {
      font-size: 2rem;
    }
    @media screen and (max-width: 599px) {
      font-size: 1.5rem;
      margin-bottom: 1.5em;
    }

    &:after {
      content: "";
      position: absolute;
      bottom: -15px;
      left: 0;
      width: 32%;
      height: 0;
      border-bottom: $gold solid 2px;
    }
  }
  .list-num {
    color: $gold;

    @media screen and (max-width: 599px) {
      display: block;
      font-size: 3rem;
      margin-bottom: 0.9rem;
    }
    @media screen and (min-width: 600px) {
      position: absolute;
      left: -1.15em;
      font-size: 2rem;
    }
    @media screen and (min-width: 901px) {
      font-size: 3rem;
    }
  }
}
.list-steps-img {
  /* padding: 1rem; */

  @media screen and (min-width: 600px) and (max-width: 900px) {
    flex-basis: 40%;
  }
  @media screen and (min-width: 901px) {
    flex-basis: 50%;
  }

  img {
    display: block;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.5);
  }

  /* @media screen and (max-width: 599px) {
		width: 106%;
	}
	@media screen and (min-width: 600px) {
		transform: translateX(6%);
	}
	@media screen and (min-width: 901px) {
		transform: translateX(7.5%);
	}
	@media screen and (min-width: 1400px) {
		transform: translateX(10%);
	} */
}

@media screen and (min-width: 600px) {
  .list-steps-item.step1 {
    .list-steps-txt {
      order: 1;
    }
    .list-steps-img {
      order: 2;
    }
  }
}
@media screen and (min-width: 901px) {
  .list-steps-item.step2 {
    .list-steps-txt {
      padding: 1rem 8% 1rem 4%;
    }
  }
}

/* New Home Maps Stats */
.batchgeo-stats {
  text-align: center;
  padding-top: 2rem;
}
@media screen and (min-width: 600px) {
  .batchgeo-stats {
    padding-top: 4rem;
  }
}
@media screen and (min-width: 1200px) {
  .batchgeo-stats {
    padding-top: 5rem;
  }
}

.batchgeo-stats-item {
  margin-bottom: 2.75rem;
}

.batchgeo-stats-item h2 {
  position: relative;
  font-size: 3rem;
  padding-bottom: 1.2rem;
  margin-bottom: 1.75rem;

  &:after {
    content: "";
    position: absolute;
    width: 20%;
    bottom: -5%;
    left: 40%;
    border-bottom: 2px solid #e4b80b;
  }

  @media screen and (max-width: 1024px) {
    font-size: 2rem;
  }
  @media screen and (max-width: 599px) {
    font-size: 1.5rem;
    padding-bottom: 0;
    margin-bottom: 0.75rem;

    &:after {
      top: -1.5rem;
      bottom: initial;
    }
  }
}
.batchgeo-stats-item p {
  font-size: 1.5625rem;
  @media screen and (max-width: 599px) {
    margin-bottom: 1.2rem;
  }
}

@media screen and (min-width: 600px) {
  .home-how-to-content.flex-columns-three .flex-columns-item {
    flex-basis: 30%;
  }
}
.home-how-to-content h3 {
  font-size: 1rem;
  font-weight: normal;
}
.card-how-to {
  background: #fff;
  padding: 1rem;
  margin-bottom: 1.5rem;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
}

.card-how-to-img {
  display: block;
  width: 100%;
  margin-bottom: 2rem;
}

.swiper-wrapper {
  display: flex;
  align-items: center;
}

.swiper-container {
  overflow: hidden;
}

@media screen and (max-width: 599px) {
  .press-slides {
    margin-top: 2rem;

    .swiper-button-next,
    .swiper-button-prev {
      display: none;
    }
    .swiper-wrapper {
      flex-direction: column;
      justify-content: center;
    }

    .swiper-slide {
      max-width: 60%;
      margin: 0 auto 3rem;
    }
    .slide-1 {
      max-width: 50%;
      margin-top: 1rem;
    }
    .slide-2 {
      max-width: 50%;
    }
  }
}

@media screen and (min-width: 600px) {
  .swiper-slide {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .press-slides.swiper-container {
    margin-top: 3rem;
    width: calc(100% + 40px);
    transform: translateX(-20px);
    padding-left: 20px;
    padding-right: 20px;

    .swiper-button-next.swiper-button-disabled,
    .swiper-button-prev.swiper-button-disabled {
      opacity: 0.25;
    }
    .swiper-button-next::after,
    .swiper-button-prev::after {
      font-size: 2rem;
      font-weight: normal;
    }
    .swiper-button-next,
    .swiper-button-prev {
      width: 40px;
      height: 40px;
      color: $orange;
      background-color: transparent;
      border-radius: 50%;
      text-align: center;
    }
    .swiper-button-prev,
    .swiper-container-rtl .swiper-button-next {
      left: 0;
    }
    .swiper-button-next,
    .swiper-container-rtl .swiper-button-prev {
      right: 0;
    }
    .swiper-button-prev:hover,
    .swiper-button-next:hover {
      filter: brightness(110%);
    }
  }
}
@media screen and (min-width: 1024px) {
  .press-slides.swiper-container {
    .slide-1 img {
      max-width: 175px;
      transform: translateY(10px);
    }
    .slide-9 img {
      max-width: 175px;
    }
    .slide-11 img {
      max-width: 175px;
    }
    .slide-12 img {
      max-width: 135px;
    }
    .slide-15 img {
      max-width: 135px;
    }
  }
}
.home-reviews {
  width: 1000px;
  max-width: 100%;
  margin: 0 auto;
  height: 80vh;
  min-height: 400px;

  // widths come from g2 (3rd party reviews) styles
  @media screen and (max-width: 1110px) {
    width: 750px;
  }
  @media screen and (max-width: 832px) {
    width: 600px;
  }
}
