@import "./batchgeo-custom-extents";

$tabRowBreakpoint: 600px;
$tabletBreakpoint: 769px;
$tabletBreakPointPlus: 770px;
$mobileBreakpoint: 400px;
#preview #rmMarker {
  display: none;
}

.ui-helper-hidden-accessible {
  display: none;
}

div.tabRow {
  border: 0;
  border-bottom: 1px solid #aaa;
  font-size: 12px;
  padding: 20px 0;
  text-align: left;
  width: 100%;
  overflow: hidden;
  cursor: pointer;
  .zoom,
  .rmMarker,
  .pop-infowindow {
    display: none;
  }
  .markerLabel {
    float: left;
    div {
      width: auto;
    }
    @media screen and (max-width: $tabRowBreakpoint) {
      float: none;
    }
  }
  .descIMG {
    max-width: 198px;
    margin: 8px 0 12px;
    float: right;
    @media screen and (max-width: $tabRowBreakpoint) {
      float: none;
      margin: 0 auto 15px 12px;
      display: block;
    }
  }
}

img.descIMG {
  max-width: 198px;
  border: 0;
}

a.marker {
  &:link,
  &:visited,
  &:hover {
    border-bottom: 0px;
    margin: 0 0 2px 0;
    text-decoration: none;
    color: #3366cc;
  }
}

a.goog {
  &:link,
  &:visited {
    text-decoration: none;
    color: #000;
  }
}

.l {
  color: #369;
}

span.tabT {
  font-weight: bold;
}

span.tabA {
  display: block;
  margin-bottom: 4px;
}

span.tabG {
  display: block;
  margin: 4px 0;
}

.tabL {
  float: right;
  padding-left: 20px;
  cursor: hand;
  font-size: 36px;
  color: #aaa;
}

a.directions-link {
  &:visited,
  &:link {
    text-decoration: none;
    color: #000;
    font-size: 12px;
  }
  &:hover {
    text-decoration: underline;
  }
}

#legWrap {
  text-align: left;
  background-color: #333;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  padding: 10px 0 0 0;
  position: relative;
  line-height: 1.2;
  &:after {
    content: "";
    clear: both;
    display: table;
  }
}

// When it's in a store locator view we want a solid background or else you see
// just a sliver of the map behind the store locator view in the black legWrap.
.storeLocator-view #legWrap {
  background-color: #000;
}

.listButton {
  color: white;
  justify-self: flex-start;
  height: 18px;
  width: 15px;
  margin-right: 10px;
  white-space: nowrap;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  background-image: url("/images/noun-arrow.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  cursor: pointer;
  grid-column: 1/2;
  align-self: center;
}

.listBackButton {
  color: white;
  justify-self: center;
  height: 18px;
  width: 15px;
  margin-right: 15px;
  @media screen and (hover: none) and (pointer: coarse) {
    margin-left: -5px;
  }
  border-radius: 3px;
  white-space: nowrap;
  background-image: url("/images/noun-arrow.svg");
  background-color: rgba(0, 0, 0, 0);
  border: none;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  position: absolute;
  top: 1px;
  left: -25px;
  transform: rotate(180deg);
  cursor: pointer;
}

.listBackButton::before {
  background-image: url("/images/noun-arrow.svg");
  transform: rotate(180deg);
}

.placeholder {
  grid-column: 2/3;
  grid-row: 1/2;
  color: white;
  justify-self: center;
  height: 25px;
  width: 25px;
  margin-right: 10px;
  border-radius: 3px;
}

.ellipsis {
  font-size: 16px;
  line-height: 16px;
  width: 35px;
  text-align: center;
}

.buttonbox {
  display: grid;
  //grid-template-columns: 20px 20px 20px 20px 20px;
  cursor: pointer;
  &.active,
  &:hover {
    background-position: left -1px;
    color: #fffb73;
  }

  li {
    &.active,
    &:hover {
      background-position: left -1px;
      color: #fffb73;
    }
    &.inactive {
      filter: alpha(opacity=50);
      opacity: 0.5;
    }
  }
}

.buttonboxforward {
  display: grid;
  cursor: pointer;
  padding-left: 8px;
}
.wrapView {
  height: 36px;
  display: block;
  @media screen and (min-width: $tabletBreakPointPlus) {
    display: none !important;
  }
}

.shrinkLegWrap {
  height: fit-content;
  padding: 0px !important;
  min-height: 0px !important;
}

.smc-up {
  bottom: 83px !important;
}

.selected-marker-count {
  position: absolute;
  right: 5px;
  bottom: 32px;
  background: rgba(0, 0, 0, 0);
  border: rgba(0, 0, 0, 0);
  span {
    background: #2a2e2b 0% 0% no-repeat padding-box;
    color: #fff;
    padding: 5px 5px;
    border-radius: 3px;
    position: relative;
    z-index: 99;
    height: 24.39px;
    box-shadow:
      0 1px 0 rgba(0, 0, 0, 0.3),
      inset 0 1px 0 rgba(255, 255, 255, 0.3);
    opacity: 0.81;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    .selectIndicatorSVG {
      height: 100%;
      margin-right: auto;
    }
    p {
      padding: 0;
      margin: 0;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 11px;
      line-height: 11px;
    }
  }
}

#legDiv {
  margin: 0 auto;
  padding: 0;
  font-size: 12px;
  color: #fff;
  width: 100%;
  display: grid;
  grid-template-columns: min-content 100px minmax(min-content, max-content) auto;
  @media screen and (max-width: $tabletBreakpoint) {
    grid-template-rows: auto auto auto;
  }
  @media screen and (max-width: $mobileBreakpoint) {
    display: block;
  }
  .columnWrap {
    grid-column-start: 1;
    grid-row-start: 1;
    position: relative;
    display: table-cell;
    padding: 0 0 10px 10px;
    &:hover .columnList {
      display: block;
    }
    &.no-menu {
      .columnName {
        padding: 5px 9px;
        margin-left: 10px;
        &:after {
          display: none;
        }
      }
      &:hover .columnList {
        display: none;
      }
    }
  }
  .columnName {
    display: inline-block;
    border-radius: 3px;
    padding: 5px 23px 5px 9px;
    margin-right: 15px;
    white-space: nowrap;
    background: rgba(255, 255, 255, 0.3);
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    box-shadow:
      0 1px 0 rgba(0, 0, 0, 0.3),
      inset 0 1px 0 rgba(255, 255, 255, 0.3);
    -webkit-box-shadow:
      0 1px 0 rgba(0, 0, 0, 0.3),
      inset 0 1px 0 rgba(255, 255, 255, 0.3);
    cursor: pointer;
    position: relative;
    @media screen and (max-width: $tabletBreakpoint) {
      font-size: 12px;
    }
    &:before {
      /* A transparent rectangle to fill the gap between the
       * columnName and columnList when hovering columnWrap. */
      position: absolute;
      left: 0;
      top: -15px;
      height: 15px;
      width: 100%;
      content: "";
    }
    &:after {
      position: absolute;
      top: 7px;
      right: 6px;
      background: url(/images/noun-arrow-2.svg);
      background-size: 100% 100%;
      background-position: center;
      height: 10px;
      width: 10px;
      content: "";
    }
    &:hover {
      background-color: rgba(255, 255, 255, 0.4);
      color: #fff;
      &:after {
        transform: rotate(180deg);
      }
    }
  }
  .columnList {
    display: none;
    position: absolute;
    bottom: 100%;
    background: #fbfbfb;
    border-radius: 3px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
    list-style: none;
    margin: 0 0 15px 0;
    padding: 3px;
    &.overflowColumnList {
      overflow: auto;
      box-sizing: border-box;
      margin: 0 0 10px 0;

      &:after {
        display: none;
      }
      li {
        overflow: hidden;
        text-overflow: ellipsis;
        box-sizing: border-box;
        min-width: auto;
      }
    }
    li {
      color: #000;
      background: rgba(0, 0, 0, 0.1);
      padding: 9px;
      margin-bottom: 1px;
      border-radius: 3px;
      min-width: 120px;
      cursor: pointer;
      @media screen and (max-width: 992px) {
        /* Display on screens smaller than desktop */
        padding: 5px;
      }
      &:hover {
        background: rgba(0, 0, 0, 0.2);
      }
    }
    &:after {
      position: absolute;
      left: 10px;
      bottom: -8px;
      border-color: #fbfbfb transparent transparent;
      border-style: solid;
      border-width: 10px 10px 0;
      content: "";
    }
  }

  .firstPage {
    grid-column: 2/4;
    grid-row: 1/2;
  }

  .leftItem {
    &.active,
    &:hover {
      background-position: left -1px;
      color: #fffb73;
    }
    &.inactive {
      filter: alpha(opacity=50);
      opacity: 0.5;
    }
  }

  .groupList {
    text-align: left;

    display: flex;
    flex-wrap: wrap;

    margin: 0;
    width: auto;
    padding: 0 0 5px 0;
    line-height: 20px;
    grid-row: 1/2;
    grid-column: 2/4;
    @media screen and (max-width: $mobileBreakpoint) {
      padding: 0 0 5px 10px;
    }
    li {
      display: inline-block;
      margin: 2px 12px 5px 0;
      padding: 0 6px 0 21px;
      background-repeat: no-repeat;
      background-position: left top;
      white-space: nowrap;
      cursor: pointer;
      max-width: 90vw;
      // FIXME: this causes the legend to be pushed down
      //overflow: hidden;
      text-overflow: ellipsis;
      &.active,
      &:hover {
        background-position: left -1px;
        color: #fffb73;
      }
      &.inactive {
        filter: alpha(opacity=50);
        opacity: 0.5;
      }
    }
  }
  .filterListWrap {
    grid-column-start: 1;
    grid-column-end: 5;
    grid-row-start: 2;
    line-height: 100%;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    background: #494949;
    // margin-top: 5px;
    display: flex;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    @media screen and (max-width: $tabletBreakpoint) {
      flex-direction: column;
    }
  }
  .listCount {
    @include flexbox;
    @include align-items(center);
    @include justify-content(center);
    align-items: center;
    padding: 0 0 2px 10px;
    background-size: auto 100%;
    @media screen and (max-width: $tabletBreakpoint) {
      @include justify-content(flex-start);
      background: -webkit-gradient(
        linear,
        left top,
        left bottom,
        from(#494949),
        to(#646464)
      );
      border-bottom: 1px solid rgba(255, 255, 255, 0.15);
      padding: 10px 5px 10px 10px;
      margin-right: 0;
    }
    p {
      font-size: 12px;
      color: #ffffff;
      letter-spacing: 0.29px;
      margin: 0;
      white-space: nowrap;
    }
    .listCountDivider {
      height: 60%;
      width: 2px;
      background-color: #6b6e6c;
      margin-left: 30px;
      margin-right: 20px;
      @media screen and (max-width: $tabletBreakpoint) {
        display: none;
      }
    }
    .listCountCloseButton:before {
      content: " ";
      display: block;
      background: url(/images/closeCircle.png);
      background-size: 100% 100%;
      background-position: center;
      position: relative;
      height: 15px;
      width: 15px;
      margin-right: 5px;
      cursor: pointer;
      @media screen and (max-width: $tabletBreakpoint) {
        margin-left: 0;
        margin-right: 6px;
      }
    }
  }
  .filterList {
    margin: 0 0 7px 0;
    padding: 0;
    text-align: left;
    @media screen and (max-width: $tabletBreakpoint) {
      padding: 5px;
      margin: 0;
    }
    li {
      @include inline-flex;
      @include align-items(center);
      @include justify-content(center);
      margin: 5px 5px 0px 0;
      padding: 5px 10px 5px 7px;
      background: rgba(255, 255, 255, 0.3);
      box-shadow:
        0 1px 0 rgba(0, 0, 0, 0.3),
        inset 0 1px 0 rgba(255, 255, 255, 0.3);
      -webkit-box-shadow:
        0 1px 0 rgba(0, 0, 0, 0.3),
        inset 0 1px 0 rgba(255, 255, 255, 0.3);
      border-radius: 3px;
      -webkit-border-radius: 3px;
      cursor: pointer;
      color: #000;
      // line-height: 100%; //new
      font-size: 11px;
      @media screen and (max-width: $tabletBreakpoint) {
        margin: 4px 4px 4px 4px;
      }
      &:before {
        content: " ";
        display: block;
        background: url(/images/closeCircle.png);
        background-size: 100% 100%;
        background-position: center;
        position: relative;
        height: 15px;
        width: 15px;
        margin-right: 5px;
        cursor: pointer;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  /* Display on screens smaller than desktop */
  #legalDiv {
    font-size: 10px;
  }
}

#descriptionWrap {
  margin: 5px 0;
  #description {
    padding: 5px 10px;
  }
}

a.mapButton {
  &:link,
  &:visited {
    background: #fff;
    border: 1px solid #999999;
    border-radius: 1px;
    -moz-border-radius: 1px;
    -webkit-border-radius: 1px;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.398438);
    //-webkit-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.398438);
    color: #999999;
    font-size: 13px;
    font-weight: bold;
    left: 80px;
    padding: 2px 2px 1px 2px;
    position: absolute;
    text-align: center;
    text-decoration: none;
    top: 77px;
    display: none;
  }
  &:hover {
    background-image: -webkit-linear-gradient(
      top,
      rgb(255, 255, 255),
      rgb(230, 230, 230)
    );
    color: #000;
  }
}

#mapDiv-outlierMenu-container {
  background-color: #ffffff;
  opacity: 1;

  p {
    margin-bottom: 0;
  }

  #outlierMenu {
    padding: 24px 24px 22px 24px;
    width: 43%;
    text-align: left;
    border: 1px solid #aaa;
    border-left: 0;

    @media (max-width: 700px) {
      width: 100%;
    }

    .title-text {
      font: normal normal bold 16px/18px Arial;
      letter-spacing: 0px;
      color: #1d1d1d;
      margin-bottom: 20px;
    }

    .outlier-text,
    .invalid-text {
      font: normal normal normal 12px/14px Arial;
      letter-spacing: 0px;
      color: #1d1d1d;
      margin-bottom: 6px;
    }

    .outlier-container,
    .invalid-container {
      height: 100px;
      overflow-y: scroll;
      width: 100%;
      border: 1px solid #ebebeb;
      &::-webkit-scrollbar-thumb {
        background: #dddddd 0% 0% no-repeat padding-box;
        opacity: 1;
      }
      &::-webkit-scrollbar {
        width: 10px;
      }
      &::-webkit-scrollbar-track {
        border: 1px solid #ebebeb;
      }
    }

    .outlier-container {
      margin-bottom: 6px;
      p {
        font: normal normal normal 11px/17px Arial;
        padding-left: 3px;
        min-height: 20px;
        letter-spacing: 0px;
        color: #1d1d1d;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        gap: 3px;
        button {
          display: flex;
          align-items: center;
          justify-content: center;
          visibility: hidden;
          color: red;
          border: none;
          padding: 0px;
          img {
            width: 8px;
            height: 8px;
            min-width: 8px;
            min-height: 8px;
          }
        }
        span {
          font: normal normal bold 11px/17px Arial;
          white-space: nowrap;
        }
        &:hover {
          background: #f7f7f7 0% 0% no-repeat padding-box;
          button {
            visibility: visible;
          }
        }
        &:focus {
          background: #f7f7f7 0% 0% no-repeat padding-box;
          button {
            visibility: visible;
          }
        }
      }
    }

    .invalid-container {
      margin-bottom: 14px;
      p {
        padding: 4px 0px 4px 7px;
        text-decoration: line-through;
        font: normal normal bold 11px/12px Arial;
        letter-spacing: 0px;
        color: #960000;
        text-transform: uppercase;
        opacity: 1;
      }
    }

    .explanation {
      margin-bottom: 50px;
      p {
        font: normal normal normal 11px/12px Arial;
        letter-spacing: 0px;
        color: #1d1d1d;
        margin-bottom: 14px;
      }
      .if-issue {
        font: normal normal bold 11px/12px Arial;
        letter-spacing: 0px;
        color: #000000;
      }
    }

    .buttons-container {
      display: flex;
      justify-content: space-between;
      gap: 12px 12px;
      flex-wrap: wrap;

      .buttons-sub-container {
        display: flex;
        gap: 12px;
        flex-wrap: wrap;
      }

      button {
        text-align: center;
        font: normal normal bold 11px/15px Arial;
        letter-spacing: 0px;
        border-radius: 3px;
        height: 26px;
        padding: 0px 10px;
        border: 1px solid;
        cursor: pointer;
      }
      .edit-data {
        background: #ffffff 0% 0% no-repeat padding-box;
        border-color: #000000;
        color: #000000;
      }
      .keep-all {
        background: #72aa27 0% 0% no-repeat padding-box;
        border-color: #547d1d;
        color: #ffffff;
      }
      .discard-outside {
        background: #f9c81b 0% 0% no-repeat padding-box;
        border-color: #707070;
        color: #000000;
      }
    }
  }

  #mapDiv {
    position: relative;
  }
}

#mapDiv.outlier-mode {
  width: 57%;
  @media (max-width: 700px) {
    width: 100%;
    height: 450px !important;
  }
}
#outlierMenu.outlier-mode {
  display: block;
}
#mapDiv-outlierMenu-container.outlier-mode {
  display: flex;
  @media (max-width: 700px) {
    flex-wrap: wrap;
  }
}

#mapDirections {
  display: none;
  float: left;
  height: 550px;
  width: 28%;
  background-color: #fff;
  overflow-y: scroll;
  z-index: 99;
}

#alias {
  width: 265px;
  font-size: 14px;
  @media screen and (max-width: 345px) {
    width: 235px;
  }
}

#alias_check {
  font-weight: bold;
}

#mapWrap {
  overflow: hidden;
  position: relative;
  background-color: #eee;
  height: 100vh;
}

.clip {
  overflow: hidden;
}

a img {
  border: 0;
}

.hide {
  display: none;
}

// begin new infobox code
.infobox-wrapper {
  display: none;
}

.markerLabel {
  border-width: 0;
  padding: 0 0.4em 0 0;
  line-height: 1.4em;
  border-spacing: 0;
  margin: 0;
  min-width: 200px;
  max-width: 350px;
  .markerContent {
    @include flexbox();
    @include flex-direction(column);
    .cardTitle {
      font-size: 14px;
      font-weight: 600;
      margin: 0;
      height: auto;
      line-height: 17px;
      white-space: normal;
      padding-right: 25px;
      margin-top: 2px;
      a {
        color: #3366cc;
      }
    }
    .address {
      font-style: normal;
    }
    .l {
      color: #545453;
      font-weight: bold;
    }
  }
  div {
    overflow-wrap: break-word;
  }
  h5 {
    font-size: 12px;
    margin: 0;
    padding: 0;
    top: -10px;
    color: #000;
    border: 0;
  }
  .markerLabelExtras {
    position: absolute;
    top: 0;
    right: 30px;
  }
  .rmMarker {
    float: right;
    padding-right: 2px;
    cursor: pointer;
  }
  .zoom {
    float: right;
  }
  .pop-infowindow {
    float: right;
    margin-right: 2px;
    cursor: pointer;
  }
  .address {
    margin: 0;
  }
  img.streetview {
    display: block;
    cursor: pointer;
    max-height: 193px;
    // These are needed because site.scss is included on edit pages and there's
    // image CSS that overrides these. A long term fix should be that there
    // shouldn't have site.scss on the edit page.
    max-width: 177px !important;
    margin: 0 !important;
  }
}

.pg {
  font-family: $sans-serif;
  background-color: #f0f0f0;
  border-radius: 0 0 2px 2px;
  font-size: 10px;
  right: -14px;
  top: -28px;
  padding: 1em;
  z-index: 99;
  text-align: center;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: nowrap;
  span {
    background-color: #333;
    color: #fff;
    padding: 2px 5px;
    margin: 1em;
    border-radius: 2px;
  }
}

.labels {
  color: #6e6758;
  font-family: $sans-serif;
  font-size: 9px;
  font-weight: bold;
  text-align: center;
  z-index: 1;
  text-shadow:
    rgba(255, 255, 255, 0.498039) -1px -1px 0px,
    rgba(255, 255, 255, 0.498039) 1px -1px 0px,
    rgba(255, 255, 255, 0.498039) -1px 1px 0px,
    rgba(255, 255, 255, 0.498039) 1px 1px 0px;
}
// end new infobox code

// begin icomoon font
@font-face {
  font-family: "icomoon";
  src: url($baseURL + "/css/fonts/icomoon.eot?r8bf86");
  src:
    url($baseURL + "/css/fonts/icomoon.eot?#iefixr8bf86")
      format("embedded-opentype"),
    url($baseURL + "/css/fonts/icomoon.woff?r8bf86") format("woff"),
    url($baseURL + "/css/fonts/icomoon.ttf?r8bf86") format("truetype"),
    url($baseURL + "/css/fonts/icomoon.svg?r8bf86#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"],
[class*=" icon-"] {
  font-family: "icomoon";
  //speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  cursor: pointer;
  text-decoration: none;
  content: "" !important;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// Honestly, I'm not sure why this is an issue but GMaps shows a sprite map when
// you load batchgeo on a mobile device. It does NOT do this if you're on
// desktop and then shrink it to mobile size or flip into a mobile mode which
// means the issue isn't some media query. I was unable to find anything on
// google about this issue so this was the best, easiest, fix I found in the meantime
[src$="sv9.png"] {
  display: none;
}

.markerLabelExtras,
.icon-search,
.icon-remove-circle {
  color: #aaa;
}
a.icon-search:hover,
a.icon-remove-circle:hover,
.pop-infowindow a:hover {
  color: #333;
  text-decoration: none;
  border-bottom: 0px;
}

.icon-keyboard-arrow-left,
.icon-keyboard-arrow-right {
  cursor: pointer;
}

.icon-clear {
  position: absolute;
  right: 7px;
  top: 7px;
  color: #aaa;
  &:hover {
    color: #333;
    text-decoration: none;
    border-bottom: 0px;
  }
}

.ui-menu .ui-menu-item {
  font-size: 11px;
}
