#enterprise-intro {
	margin-bottom: 1em;
}

.flex-columns-two.pad .enterprise-text-col-1, 
.flex-columns-two.pad .enterprise-text-col-2 {
	@media screen and (min-width: 600px) {
		flex-basis: 46%;
	}
}

.enterprise-text-col-2 {
	@media screen and (max-width: 599px) {
		padding-top: 1.5rem;
	}
}

#enterprise {
	.features_cta_button_wrapper {
        margin: auto;
        max-width: 320px; 
        display: flex;
        justify-content: center;
		margin-top: 40px;
    }

	.additional-features {

		margin: 2em;

	}

	.advantage {

		span {
			color: $color-primary;
		}

		h3 {
			font-size: 1.25rem;
			padding: 10px 0;
			margin-bottom: .5rem;
			display: inline-block;
			color: $color-primary;
		}
		.flex-columns-item {
			margin-bottom: 3rem;
		}
		@media screen and (max-width: 599px) {
			.flex-columns-item {
				margin-bottom: 1.5rem;
			}
		}
		
	}

	.learn-more {
		padding: 1.5em 0 2.5em;

		h2 {
			margin-bottom: .95em;
		}

		button {
			margin: 0 5px;
			img {
				width: 20px;
				margin-right: 5px;
				position: relative;
				top: 2px;
			}
		}
	}

	


	// PRICING CHART //////////////////////////////
	#pricing-table h2 {
		position: relative;

		@media screen and (min-width: 768px) {
			width: 30%;
			max-width: 290px;
			margin-bottom: -6rem;
		}
	}
	
	#pricing-table h2:after {
    content: '';
    position: absolute;
    bottom: -15px;
    left: 0;
    width: 190px;
    max-width: 57%;
    height: 0;
    border-bottom: $gold solid 2px;
	}

	.pricing {

		border-bottom: 1px solid #e8e8e8;
		border-radius: 0px 4px 4px 12px;
		margin: 0;
		max-width: 100%;
		padding: 6em 0;

		@media screen and (max-width: 767px) {
			padding: 3em 0;
		}

		.threecol {
			margin-left: 0;
			width: 100%;
		}

		.package-rows {

			display: none;

			li {
				text-align: left;
				margin-bottom: 0;

				&:first-child {
					border: none;
				}

				&:nth-child(2) {
					border-top: 1px solid #e8e8e8;
				}

				&:last-child {
					text-align: left;
					border-color: #e8e8e8;
					padding: 12px;

				}

			}

		}

		ul {
			@media screen and (min-width: 768px) {
				width: 90%;
			}
			
			margin-top: 1em;
			//float: left;

			&:first-child {
				text-align: left;
			}

		}

		li {

			border-top: #e8e8e8 1px solid;
			padding: 12px;
			text-align: center;
			box-sizing: border-box;
			margin-bottom: 0;

			&:first-child {
				border: none;
				height: 260px;

				
				button{
					width:90%;
				}
				span{
					@include flexbox;
					@include align-items(flex-end);
					@include justify-content(center);
					width:100%;
					padding:10px 0 14px;
					height:70px;
				}
			}

		}

		.markers:before {

			content: 'Markers per map ';
		}
		.users:before {
			content: 'Users ';
		}
		.geocodes:before {
			content: 'Geocodes per minute ';
		}
		.export:before {
			content: 'Export options ';
		}
		.security:before {
			content: 'Security and privacy ';
		}
		.sla:before {
			content: 'SLA ';
		}
		.custom:before {
			content: 'Custom markers ';
		}
		.advanced:before {
			content: 'Advanced features ';
		}
		.advertising:before {
			content: 'Advertising ';
		}

	}

	// ICONS //////////////////////////////

	[class^="enterprise-icon-"], [class*="enterprise-icon-"] {

		font-size: 1.6em;
		margin-right: 10px;
		position: relative;
		top: 5px;
	}

	@media only screen and (min-width: 768px) {

		.enterprise-intro {
			.intro-top {
				margin-bottom: 1em;
			}
			.sixcol.first {
				margin-top: 15px;
				margin-bottom: 0;
			}

		}

		.pricing {
			ul {
				margin: 0;
			}

			.package-rows {
				display: block;
			}

			.threecol {
				margin-left: 0;
				width: 25%;

			}
			.markers:before, .users:before, .geocodes:before, .export:before, .security:before, .sla:before, .custom:before, .advanced:before, .advertising:before {
				display: none;
			}
		}

	}


	@media only screen and (min-width: 1024px) {
		.enterprise-callout{
			h3{
				button{
					display:inline-block;
					clear:none;
					margin:0 0 0 25px;
				}
			}
		}

	}

}

.enterprise-footer-wrap #footer{
	margin:0;
}
