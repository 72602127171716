/******************************************************************
These are the styles for the FEATURES MAIN PAGE
******************************************************************/

#feature {
    /* padding-top: 2em; */
    .features_cta_button_wrapper {
        margin: auto;
        max-width: 320px; 
        display: flex;
        justify-content: center;
    }
    #features_cta_button, #f_geo_cta_button {
        margin-bottom: 0;
    }

    section {
        padding: 2em 0 4em;
    }

    .feature-home-h1 {
        font-weight: 400 !important;
        font-size: 48px;
        text-align: center;
    }

    ul li {
        list-style-type: circle;
    }
}

.feature-group {
	margin-bottom: 2.5rem;

	@media only screen and (max-width: 767px) {
		margin-bottom: 1.5rem;
	}
}

.mapsforwebsite, .foryourbusiness {

    p {
        margin: 2em 0 3em;
    }

}



/******************************************************************
These are the styles for the FEATURES PAGES
******************************************************************/

#feature {
    hr {
        background-image:none;
        border: 0;
        height: 0;
        margin: 40px 0;
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        border-bottom: 1px solid rgba(255, 255, 255, 0.3);
        width: 100%;
    }
    strong {
        font-weight: bold;
    }
    small {
        font-size: $small;
		}
		.intro {
            img {
                max-height:250px;
            }
		}
		.intro.section-pad {
			padding: 4rem 0;
			
			@media screen and (max-width: 600px) {
				padding: 3rem 0;
			}
		}
		.callout-one-column {
			padding: 5rem 0rem;
			@media screen and (min-width: 600px) and (max-width: 1024px) {
				padding: 4rem 0;
			}
			@media screen and (max-width: 599px) {
				padding: 2em 0;
			}
		
			p {
				font-size: 1.25rem;
				margin-bottom: 1.5em;
			}
		}

    .locdot {
        background-image: url(/images/locationdot@2x.png);
        background-repeat: no-repeat;
        background-position: center 40px;
        background-size: 110px;
        height: 260px;
    }
    .features {
        border-bottom: solid 1px #F1F1F1;
        padding-bottom: 20px;
        margin-bottom: 20px;
        clear: right;
    }
    .features.noborder {
        border-bottom: none;
    }
    .bordered {
        border: none;
    }
    .example-maps {
        height:100px;
        overflow-y:scroll;
        padding:0px 10px;
        margin-top:0px;
        border: 2px solid #F1F1F1;
    }
    @media only screen and (min-width: 768px) {
        .bordered {
            border: 2px solid #F1F1F1;
            margin: 40px 0;
            padding: 40px 0;
        }
        .example-maps {
            height:400px;
        }
    }

    /******************************************************************
    These are the styles for the HOW-TO TABLES IN FEATURES
    ******************************************************************/

    ol {
      display: table;
      list-style: none;
      overflow: hidden;
      padding: 0 0 0 0px;
      border: solid 1px #ddd;
      border-radius: 4px;
      counter-reset: numList;
      margin: 2em auto;
      width: 90%;
      background-color:#FFF;
    }

    ol li {
      border-bottom: 1px solid #ddd;
      margin: 0;
      padding: 1em 3em;
      position:relative;
      float: left;
      list-style-type: none;
      display: table-cell;
      width: 100%;
    }
    @media only screen and (min-width: 768px)  {
        ol li {
            width:100%;
        }
    } 
    ol li.last {
        border: none;
    }
    ol li:before {
        counter-increment: numList;
        content: counter(numList);
        float: left;
        position: absolute;
        left: 1em;
        top: 1.5em;
        font: bold 12px sans-serif;
        text-align: center;
        color: #fff;
        line-height: 20px;
        width: 19px; height: 19px;
        background: #ccc;
        background: $color-primary;
        -moz-border-radius: 999px;
        border-radius: 999px
    }
    
    /******************************************************************
    These are the styles for BULLETS IN AN UL (not numbers)
    ******************************************************************/

    .bullets ul {
        padding: 0 0 0 0px;
        margin: 2em auto;
        width: 90%;
    }

    ul.bullets li {
        list-style-type: circle;
        display: list-item;
    }
    .media-icons {
        a {
            display: inline-block;
            margin: 0 10px;
        }
    }

    // multi-point-routing adjustments
    .side-by-side {
        display: flex;
        gap: 60px;
        margin-bottom: 50px;
        .img-wrap {
            min-width: 33%;
            max-width: 400px;
        }
        .text-wrap {
            display: flex;
            flex-direction: column;
            justify-content: center;
            h3 {
                margin: 0;
                margin-bottom: 4px;
                line-height: 30px
            }
        }
    }
    .side-by-side.reverse {
        flex-direction: row-reverse;
    }
    .font-18 {
        font-size: 1.125rem !important;
        line-height: 1.875rem;
    }
    .mb-80 {
        margin-bottom: 80px;
    }
    .flex {
        display: flex;
    }
    .intro.flex {
        justify-content: center;
        gap: 80px;
    }
    .section_hdr.left {
        margin: 0 0 2.5rem 0;
        font-size: 2.8rem;
    }
    .intro-left {
        width: 55%;
        max-width: 750px;
    }
    .intro-img-wrap {
        width: 385px;
        display:flex;
        align-self:center;
        img {
            width: 100%;
            height: auto;
            max-height: 100%;
        }
    }
    .callout-one-column.wrap.multi-point-routing {
        max-width: 975px;
    }
    .wrap.multi-point-routing {
        @media only screen and (max-width: 768px) {
            max-width: 1125px;
        }
    }
    @media only screen and (max-width: 840px) {
        /* Header */
        .intro-img-wrap {
            display: none;
        }
        .intro-left {
            display:flex;
            flex-direction: column;
            align-items: center;
            width:100%;
            text-align:center;
            p {
                margin-bottom: 24px;
            }
        }
        /* first section */
        .wrap.multi-point-routing {
            text-align: center;
        }
        /* side-by-side */
        .side-by-side.reverse {
            flex-direction: column-reverse;
        }
        .side-by-side {
            gap: 0;
            flex-direction: column-reverse;
            align-items: center;
            .text-wrap {
                h3 {
                    margin-bottom: 8px;
                }
            }
        }
    }
}
#feature.mb-0 {
    margin-bottom: 0;
}

.map_badges_link {
	img {
		border: $color-primary solid 1px;
	}
	a:hover {
		text-decoration: none;
	}
}

