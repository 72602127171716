.batchgeo-custom-buttons {
	position: absolute;
	bottom: 25px;
	right: 10px;
	display: flex;
	gap: 5px;

	@media only screen and (max-width: 400px)  {
		width: 100%;
		justify-content: center;
		right: 0;
	}

	.batchgeo-custom-extent {
		box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
		background: #fff;
		border: 1px solid #D9D9D9;
		padding: 0 17px;
		font-family: Roboto, Arial, sans-serif;
		border-radius: 2px;
		color: #000;
		height: 40px;
		font-weight: 500;
		cursor: pointer;
	
		&:hover {
			background: #ebebeb;
		}
		&:disabled {
			background: #ebebeb;
			color: #666666;
		}
	}

	.batchgeo-boundary-zoom {
		@extend .batchgeo-custom-extent
	}
}
