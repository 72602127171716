.home-admin-map-table-title {
  text-align: center;
  margin-top: 3em;
}

.home-admin-map-container {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1140px;
  width: 90%;
  margin: 0 auto 4rem;

  table {
    width: 100%;
    border: 1px solid #ddd;
    border-left-width: 0;
    border-collapse: separate;
    border-spacing: 0;
    border-radius: 6px 6px 0 0;
    margin-bottom: 50px;
  }

  td {
    padding: 8px 9px;
    vertical-align: middle;
    border: 1px solid #ddd;
    border-width: 1px 0 0 1px;
    text-align: left;

    &.center {
      text-align: center;
    }

    &.updated {
      font-size: 12px;
    }
  }

  th {
    font-weight: 700;
    border-top-width: 0;
    padding: 6px 9px;
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
    border-width: 1px 0 0 1px;
    text-align: left;
    &:first-child {
      border-radius: 6px 0 0 0;
    }
  }
  .center {
    text-align: center;
  }
  .button {
    margin: 0;
    margin: 2px;
    padding: 6px 10px;
  }
}

@media all and (max-width: 768px) {
  .home-admin-map-container .button {
    display: block;
  }
  .home-admin-map-container td {
    vertical-align: top;
  }
}
