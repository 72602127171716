#edit_form {

    a:hover {
        text-decoration: none;
        border-bottom: 0;
    }
    .line {
        display: block;
        margin: 20px 0;
        clear: both;
    }
    .edit_form_inner {
        width: 800px;
				margin: auto;
				padding: 1rem 0;

        h1 {
            font-size: 14px;
            font-weight: bold;
        }
        #inline_save {
            width: 100%;
            label {
                font-size: 12px;
                font-weight: bold;
                margin-top: 6px;
            }
            .simplelabel {
                float: none;
                margin: 0 auto;
                display: inline;
            }
        }
    }
    span.small {
        color: #999;
    }

    #locFields {
        height: 150px;
    }
    #fields {
        text-align: center;
    }
    textarea#map_description {
        color: #000;
        font-size: 14px;
        width: 400px;
        height: 100px;
        font-family: $sans-serif
    }
    #edit_form {
        input {
            font-size: 14px;
        }
    }
    #zoom {
        display: none
    }
    textarea#map_description, #map_title, #map_email, #map_description, .map_embed_code, .map_locator_code, .map_badge_code {
        width: 350px;
    }
    #badge {
        margin-left: 125px;
        width: 284px;
        height: 142px;
    }
}
    @media only screen and (max-width: 900px) {
     #edit_form {
         .edit_form_inner {
             width: 100%;
         }
     }
    }

    @media only screen and (max-width: 630px) {
     #edit_form {
         .edit_form_inner #inline_save label {
             width: 100%;
             text-align: left;
             margin-bottom: 10px;
             margin-top: 25px;
             margin-right: 0;
         }
         textarea#map_description, #map_title, #map_email, #map_description, .map_embed_code, .map_locator_code, .map_badge_code {
             width: 100%;
         }
         #inline_save div.input_desc span.small {
             margin: 10px 0 15px 0;
         }
         #badge {
             margin-left: 0;
         }
         #generator hr {
             margin: 3em 0 2em;
             border-bottom: 1px solid #ccc;
         }
         fieldset, .fieldset {
             margin-top: 25px;
         }
      }
    }
