.infoBox {
  margin: 8px auto 0;
  background: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 5px;
  color: #333;
  font-family: $sans-serif;
  font-size: 12px;

  @include box-shadow(0 1px 1px rgba(0, 0, 0, 0.1));
  @include flexbox;
  @include flex-direction(column);

  .pg {
    background: transparent;
    font-size: 12px;
    z-index: inherit;
    text-align: left;
    border-bottom: none;
    padding: 3px 10px;
    font-weight: bold;
    color: #858585;
    @include flexbox;
    @include align-items(center);
    position: absolute;
    bottom: 0;
    right: 0;
    top: auto;

    .pg-arrow {
      font-size: 24px;
      padding: 0 5px;
      margin: 0;
      background: #fefefe; /* Old browsers */
      background: linear-gradient(to bottom, #fefefe 0%, #f6f6f6 100%);
      color: #979797;
      border: 1px solid #d4d4d4;
      border-radius: 4px;
      height: 26px;

      &:active {
        background: linear-gradient(to bottom, #fefefe 100%, #f6f6f6 0%);
      }

      &:before {
        font-family: "batchgeotoolbaricons" !important;
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        content: "\e923";
        font-size: 32px;
        color: #979797;
        position: relative;
        top: -4px;
      }
    }
    .icon-keyboard-arrow-left {
      margin-left: 10px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    .icon-keyboard-arrow-right {
      position: relative;
      left: -1px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      &:before {
        content: "\e924";
      }
    }
  }

  .batchgeo-infobox-close-button {
    position: absolute;
    right: 7px;
    top: 7px;
    color: #979797;
    z-index: 99999;

    &:before {
      font-family: "batchgeotoolbaricons" !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      content: "\e912";
      font-size: 28px;
      color: #979797;
    }
  }
  .icon-remove-circle {
    position: absolute;
    right: 2px;
    top: 7px;
    color: #979797;
    z-index: 99999;
    &:before {
      font-family: "batchgeotoolbaricons" !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      content: "\e920";
      font-size: 28px;
      color: #979797;
    }
  }

  .markerLabel {
    width: 275px;
    padding: 0;
    div {
      font-weight: 700;
      color: #585857;
      &.markerDetails {
        a {
          color: #585857;
          text-decoration: none;
          &:hover {
            text-decoration: underline;
            border: none;
          }
        }
      }
      &.address {
        font-weight: 400;
      }
    }

    .markerContent {
      padding: 10px 1em 55px 1em;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: -0.25px;
      @include flexbox;
      @include flex-direction(column);
      gap: 10px;

      .markerSub {
        margin-top: 5px;
        @include media(">phone") {
          max-height: 450px;
          overflow: auto;
        }

        .markerImage {
          display: block;
          float: right;
          margin-left: 10px;
          height: 80px;
          width: 80px;
          overflow: hidden;
          img {
            height: auto;
            width: 80px;
          }
          &.no-title {
            margin-top: 20px;
          }
        }
      }
      .markerDetails {
        div {
          text-align: left;
          margin-bottom: 5px;
        }
      }
    }

    .cardTitle {
      font-size: 14px;
      font-weight: 600;
      height: auto;
      line-height: 17px;
      white-space: normal;
      padding-right: 25px;
      margin-top: 2px;
      text-align: left;
      a {
        color: #3366cc;
      }
    }
    .address {
      font-style: normal;
    }
    .l {
      color: #585857;
      font-weight: 400;
    }

    .infowindow-footer {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 32px;
      background: #f6f6f6;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;

      @include flexbox;
      @include flex-direction(row);
      @include justify-content(flex-start);
      @include align-items(center);

      .infowindow-footer-button {
        position: relative;
        height: 32px;
        width: 40px;
        color: #878787;
        text-decoration: none;
        text-transform: uppercase;
        font-weight: bold;
        letter-spacing: 1px;
        font-size: 12px;

        @include flexbox;
        @include justify-content(center);
        @include align-items(center);

        &:active,
        &:focus {
          background: #f5f5f5;
        }
        &:hover {
          cursor: pointer;
        }

        &:before {
          display: block;
          content: "";
          width: 32px;
          height: 28px;
          text-align: center;
          background-size: 14px;
          background-position: center;
          font-family: "batchgeotoolbaricons" !important;
          speak: none;
          font-style: normal;
          font-weight: normal;
          font-variant: normal;
          text-transform: none;
          line-height: 1;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          content: "\e922";
          font-size: 28px;
          color: #979797;
        }
        &.pin-button {
          &:before {
            content: "\e92a";
          }
        }
        &.pin-button-active {
          &:before {
            content: "\e92b";
          }
        }
        &.zoom-button {
          &:before {
            content: "\e911";
          }
        }
        &.url-button {
          &:before {
            content: "\e921";
          }
        }
        &.nav-button {
          &:before {
            content: "\e922";
          }
        }
        &.distance-button {
          &:before {
            content: "\e91e";
          }
        }
        &:after {
          content: "";
          color: #d9d9d9;
          position: absolute;
          right: 0;
          top: 7px;
          border-right: 1px dotted #cccccc;
          height: 19px;
        }
        &:first-child {
          border-bottom-left-radius: 5px;
        }
        &:last-child {
          border-bottom-right-radius: 5px;
          &:after {
            content: "";
            display: none;
          }
        }
      }
    }
  }
}
