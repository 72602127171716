/******************************************************************
BatchGeo
Stylesheet: About Page styles
******************************************************************/

.about-container {

	#details {
		text-align: center;
		.detail-table {
			background: #ffffff;
			border: 1px solid #CDCDCD;
			border-radius: 8px;
			padding: 35px 0;
			margin: 35px 0 50px;
			@include box-shadow(0 1px 4px rgba(0,0,0,0.2));
			@include flexbox();
			@include align-items(center);
			@include justify-content(center);
			@include media ("screen", "<=phone") {
				@include flex-direction(column);
			}
			.cell {
				@include flexbox();
				@include flex-direction(column);
				@include justify-content(center);
				border-right: 1px solid #CDCDCD;
				width: 33%;
				padding: 25px;
				@include media ("screen", "<=phone") {
					border-bottom: 1px solid #CDCDCD;
					border-right: none;
					width: 80%;
					&:last-child {
						border-bottom: none;
					}
				}
				h1 {
					color: #FFC715;
					margin: 0;
				}
				h2 {
					color: #FFC715;
					margin: 0;
				}
				h5 {
					color: #666666;
					margin: 0;
					padding: 0;
					border: none;
				}
				&:last-child {
					border: none;
				}
			}
		}
	}

	#testimonials {
		.top-row,
		.bottom-row {
			@include flexbox();
			margin: 0 auto;
			.quote-col {
				width: 50%;
				position: relative;
				background: #ffffff;
				border-radius: 8px;
				padding: 20px;
				margin: 35px 20px;
				@include box-shadow(0 1px 4px rgba(0,0,0,0.2));
				strong {
					font-weight: 800;
					font-size: 1rem;
				}
				p {
					font-size: 16px;
					margin: 10px 0;
				}
				&:after {
					content: '';
					width: 0;
					height: 0;
					border-style: solid;
					position: absolute;
				}
				&.top-left {
					&:after {
						border-width: 0 35px 35px 0;
						border-color: transparent #ffffff transparent transparent;
						right: 40px;
						bottom: -33px;
						-webkit-filter: drop-shadow(2px 4px 1px rgba(0,0,0,.1));
						filter: drop-shadow(2px 4px 1px rgba(0,0,0,.1));
					}
				}
				&.top-right {
					&:after {
						border-width: 35px 35px 0 0;
						border-color: #ffffff transparent transparent transparent;
						bottom: -33px;
						left: 40px;
						-webkit-filter: drop-shadow(-2px 4px 1px rgba(0,0,0,.1));
						filter: drop-shadow(-2px 4px 1px rgba(0,0,0,.1));
					}
				}
				&.bottom-left {
					&:after {
						border-width: 35px 0 0 35px;
						border-color: transparent transparent transparent #ffffff;
						right: -33px;
						top: 25px;
						-webkit-filter: drop-shadow(4px 2px 1px rgba(0,0,0,.1));
						filter: drop-shadow(4px 2px 1px rgba(0,0,0,.1));
					}
				}
				&.bottom-right {
					&:after {
						border-width: 0 0 35px 35px;
						border-color: transparent transparent #ffffff transparent;
						left: -33px;
						top: 25px;
						-webkit-filter: drop-shadow(-4px 2px 1px rgba(0,0,0,.1));
						filter: drop-shadow(-4px 2px 1px rgba(0,0,0,.1));
					}
				}
			}
		}
		.top-row { max-width: 800px; }
		.bottom-row {
			max-width: 1200px;
			margin-top: 20px;
			@include align-items(flex-start);
			.quote-col {

				margin-bottom: 100px;
				margin-top: 0;
			}
		}
		.testimonial-image {
			width: 600px;
		}
	}

	
}
