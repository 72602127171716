// Styles used on the new pricing page
.pricing-box {
	max-width: 544px;
	border: #dadada solid 1px;
	border-radius: 4px;
	background-color: #fff;
	margin-top: 1rem;
	margin-bottom: 1rem;
}
.pricing-box.selected {
	border: 4px solid #ffc715;
	position: relative;
}
.pricing-box.selected:before {
	display: block;
	position: absolute;
	left: 50%;
	margin-left: -20px;
	top: -20px;
	background: #64991e;
	width: 40px;
	height: 40px;
	content: "\e90e";
	border-radius: 50px;
	font-family: batchgeotoolbaricons;
	color: #fff;
	font-size: 36px;
	line-height: 38px;
}
.pricing-box.pricing-pro {
	border-color: $gold;
}
.pricing-box-title {
	display: flex;
	background-color: $gold;
	height: 4.25rem;
	justify-content: center;
	align-items: center;
	font-size: 1.25rem;
	font-weight: normal;
	margin-bottom: 0;
}
.pricing-box-body {
	max-width: calc(266px + 4rem);
	margin: 0 auto;
	padding: 1rem;

	hr {
		height: 2px;
		margin: 0 auto 1.5rem;
	}

	h3 .date {
		font-size: 1.25rem;
		font-weight: normal;
		margin-left: -.75rem;

		@media only screen and (max-width: 900px) {
			margin-left: -.5rem;
		}

		@media only screen and (max-width: 599px) {
			margin-left: -.1rem;
		}
	}
}

.pricing-box-body-top {
	padding: 0 1rem;
}

.pricing-pro {
	hr {
		background-color: $gold;
	}
}

.pricing-features {
	list-style: none;
	margin: 0 0 .2rem 2rem;
	font-size: 1.25rem;

	li {
		padding: 0 0 .75rem 2rem;
		margin: 0;
		position: relative;
	}
	li:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 1.5rem;
		height: 1.9rem;
		background: url(/images/icon-checkmark.svg) no-repeat left center;
	}
}
.pricing-2-options .pricing-features {
	font-size: 1rem;
}
.pricing-features-pro {
	margin-left: 1rem;
}

#pro-demo{
	width:100%;
	height:auto;
	box-shadow: 0 5px 35px 10px rgba(0,0,0,.1);
}
@include media ("screen", "<tablet") {
	
	#pricing-wrapper {
		#content-wrap #pro-intro{
			margin-bottom:25px;
		}
		#pro-cta{
			text-align: center;
		}
	}
}

.pricing-lite {
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
.pricing-2-options {
	align-items: stretch;
	justify-content: center;
	padding-top: 2rem;
}
.pricing-2-options .flex-columns-item {
	flex-basis: 350px;
	max-width: 350px;
	margin: 0 1.25rem;
	display: flex;
	flex-direction: column;
	align-items: stretch;
}
.pricing-lite-spacer {
	height: 4.8rem;
}
@media screen and (max-width: 759px) {
	.pricing-2-options .flex-columns-item {
		margin: 0 auto;
	}
	.pricing-lite-spacer {
		display: none;
	}
}

.pricing-type {
	display: block;
	padding-bottom: 1rem;
	font-size: 1.5625rem;
	font-weight: bold;
}
.pricing-pro .pricing-type {
	color: $gold;
}
.pricing-box .button.disabled:before {
	position: relative;
	top: 10px;
	display: inline-block;
	width: 10px;
	height: 10px;
	content: "\e90e";
	font-family: batchgeotoolbaricons;
	color: #64991e;
	font-size: 36px;
	line-height: 0;
	margin-right: 30px;
	margin-left: -10px;
}

 @media screen and (min-width: 1240px) {
	.pricing-hero .flex-columns-two.pad .flex-columns-item {
		flex-basis: 45.75%;
	}
 }

 
// if index4.php is still used, uncomment this: 
/* #tier-pricing {

	.pricing-header {
		@include flexbox;
		@include justify-content(center);
		@include align-items(center);

		.pro-slide {
			margin: 0;
		}
	}

	.pricing {
		border-bottom: 1px solid #efefef;
		border-radius: 0px 4px 4px 12px;
		margin: 0;
		max-width: 100%;
		margin: 4em 0;

		h2 {
			font-size: 48px;
			color: #585757;
			text-align: center;
			font-weight: 400;
		}

		.threecol {
			margin-left: 0;
			width: 100%;
		}

		.package-rows {
			display: none;

			li {

				font-weight: 600;
				text-align: left;
				border-left: 1px solid #efefef;
				margin-bottom: 0;

				&:first-child {

					background: none;
					border: none;

				}

				&:nth-child(2) {

					border-top: 1px solid #efefef;

				}

				&:last-child {

					background: none;
					color: #585757;
					font-size: inherit;
					text-shadow: none;
					font-weight: 600;
					text-align: left;
					border-color: #efefef;
					border-radius: 0px 0px 0px 12px;
					padding: 12px;

				}

			}

		}

		ul {

			border-right: none;
			width: 100%;
			margin-top: 1em;
			//float: left;

			&:first-child {

				font-weight: 500;
				text-align: left;

			}

		}

		li {

			border-top: #efefef 1px solid;
			padding: 12px;
			text-align: center;
			box-sizing: border-box;
			margin-bottom: 0;

			&:first-child {

				background-color: #333333;
				color: #fff;
				font: 300 30px/50px $sans-serif;
				height: 260px;
				padding:25px 0 0 0;

				h3 {

					font: 400 36px/18px $sans-serif;
					color: #fff;

				}

				p {

					font: 300 16px/18px $sans-serif;
					height:40px;
					@include flexbox;
					@include align-items(center);
					@include justify-content(center);
					margin-bottom:5px;
					margin-top:0;
					padding:0 10px;


				}
				button{
					width:90%;
				}
				span{
					@include flexbox;
					@include align-items(center);
					@include justify-content(center);
					background:#5B5B5B;
					color:#fff;
					font-size:17px;
					line-height:18px;
					font-weight:600;
					width:100%;
					padding:10px 15px;
					height:70px;
				}
			}

		}

		.markers:before {
			content: 'Markers per map ';
		}
		.users:before {
			content: 'Users ';
		}
		.geocodes:before {
			content: 'Geocodes per minute ';
		}
		.export:before {
			content: 'Export options ';
		}
		.security:before {
			content: 'Security and privacy ';
		}
		.sla:before {
			content: 'SLA ';
		}
		.custom:before {
			content: 'Custom markers ';
		}
		.advanced:before {
			content: 'Advanced features ';
		}
		.advertising:before {
			content: 'Advertising ';
		}

	}

}

@media only screen and (min-width: 768px) {
	#tier-pricing {
		.pricing {
			ul {
				border-right: solid 1px #efefef;
				margin: 0;
			}

			.package-rows {
				display: block;
			}

			.threecol {
				margin-left: 0;
				width: 25%;

			}
			.markers:before, .users:before, .geocodes:before, .export:before, .security:before, .sla:before, .custom:before, .advanced:before, .advertising:before {
				display: none;
			}
		}
	}

} */
