/******************************************************************

@import 'map_core';

BatchGeo

Stylesheet: Mapping

******************************************************************/

#generator {
  // box-shadow: inset 0px 2px 8px rgba(0,0,0,0.05);
  border-bottom: 1px solid #eee;
  margin-bottom: 3em;
  padding: 3em 0;
  @media screen and (max-width: 768px) {
    padding: 2.5em 0 2em;
  }
  @media screen and (max-width: 599px) {
    padding: 2em 0 1em;
  }

  .wrap {
    max-width: 1150px;
  }

  .generator_h1 {
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    font-size: 3rem;
  }
  h2 {
    margin-top: 1em;
  }
  hr {
    margin-top: 4em;
  }
  textarea {
    font-size: 11px;
    font-family: "Courier New", Courier, mono;
    border: 1px solid #aaa;
    background-color: #fff;
    overflow-x: hidden;
    @include border-radius(4px);
    resize: vertical;
    width: 100%;
    height: 190px;
    -webkit-box-shadow: inset 0 2px 6px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: inset 0 2px 6px rgba(0, 0, 0, 0.1);
    box-shadow: inset 0 2px 6px rgba(0, 0, 0, 0.1);
  }
}

fieldset,
.fieldset {
  text-align: center;
  font-size: 14px;
  @include border-radius(4px);
}
.fieldset p {
  color: #1d1d1d;
  font-size: 1rem;
  margin-bottom: 1.75rem;
}
legend,
.step {
  color: #369;
  font-weight: bold;
  margin: -38px 0 6px 4px;
  padding: 2px;
  font-size: 14px;
  text-align: left;
}
p.top-buttons {
  margin-top: 3rem;
  margin-bottom: 0.75rem;
}

#sourceWrap {
  cursor: pointer;
  /* display: inline-block; */
  height: 194px;
  margin: 12px auto 1.5rem auto;
  max-width: 1120px;
  padding: 2px 0;
  width: 100%;
}

#sourceWrap.edit {
  width: 100%;
}

#inline_save {
  width: 580px;
  margin: auto;
  padding: 4px 10px;
}
#cboxWrapper #inline_save {
}
#edit_form {
  display: block;
  margin: 0 auto 0 auto;
  padding: 0;
  background-color: #eee;
  .edit-form_breakout {
    width: 1125px;
    max-width: 95%;
    margin: auto;
  }
}
