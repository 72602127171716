.resource-error-modal {
	display: none; /* Hidden by default */
	position: fixed; /* Stay in place */
	z-index: 1; /* Sit on top */
	left: 0;
	top: 0;
	z-index: 99999;
	width: 100%; /* Full width */
	height: 100%; /* Full height */
	overflow: auto; /* Enable scroll if needed */
	background-color: rgb(0,0,0); /* Fallback color */
	background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
	-webkit-transition: opacity 100ms ease-in;
	-moz-transition: opacity 100ms ease-in;
	transition: opacity 100ms ease-in;

	.resource-error-modal-content {
		display: flex;
		flex-direction: column;
		align-items: center;
		max-width: 700px;
		text-align: left;
		padding: 30px 30px 0 30px;
		left: 50%;
		top: 50%;
		position: absolute;
		transform: translate(-50%, -50%) !important;
		border-radius: 7px;
		background: white;
	}

	p {
		margin: 15px 0;
		color: #767676 !important;
	}
}
