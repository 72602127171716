.batchgeo-ui-map-mode-toggle {
	display: flex;
	@media only screen and (max-width: 572px){
		flex-direction:row;
		width: 100%;
	}

	.batchgeo-ui-map-mode-toggle-header {
		display: flex;
		vertical-align: middle;
		align-items: baseline;
	}
	// This makes me sad but because IDs are used (#inline_save) everything needs
	// to be important otherwise it'll always be less specificity.
	.batchgeo-ui-map-mode-toggle-label {
		display: block !important;
		width: 50% !important;
		text-align: left !important;
		line-height: 0 !important;
	}
	.batchgeo-ui-map-mode-toggle-image-wrap {
		display: inline-block;
		box-shadow: rgba(0, 0, 0, 0.25) 0 1px 2px;
		border: 1px solid #d0d0d0;
		border-radius: 2px;
		margin-bottom: 5px;
	}
	img {
		width: 128px;
		height: 95px;
		margin: 10px 7px;
	}
}
