#mobile-page-wrapper {
	#content-wrap {
		/* background: url(/images/signup/signup-background.jpg) no-repeat;
		background-size: cover; */
		padding-bottom: 0;

		.button {
			display: block;
			width: 100%;
			color: #fff;
			border-color: #000;
		}
	}

	.mobile-page-intro {
		padding: 80px 25px 0;
		overflow:hidden;

		h1 {
			color: #fff;
			font-size: 2.4em;
			font-weight: 600;
			margin: 0 0 10px 0;
			text-align: center;
		}

		h2 {
			font-size: 22px;
			color: $orange;
			text-align: center;
			font-style: italic;
			font-weight: 700;
			margin-bottom: 50px;
		}

		p {
			color: #fff;
			font-size: 24px;
			text-align: left;
			margin-bottom: 30px;
		}
		ul {
			margin-left:50px;
			list-style-type: disc;
			li {
				color: #fff;
				font-size: 22px;
				text-align: left;
			}
		}
		p.disclaimer {
			font-size: 13px;
			color: #FFFFFF;
			line-height: 18px;
			a {
				color: #fff;
				text-decoration: underline;
			}
		}
	}
	.mobile-page-image-wrapper {
		position:absolute;
		top:0;
		right: 0;

		@media all and (min-width: 1025px) {
			right: 5%;
		}
	}
	.download-callout {
		background: $color-primary-dark;
		border-radius: 4px;
		margin:10px 0 15px 0;
		padding:15px 25px;
		display: inline-block;
		width:auto;
		@media all and (max-width: 345px) {
			padding: 10px 20px;
		}
		p {
			font-size: 30px;
			color: #FFFFFF;
			font-weight:600;
			letter-spacing: 0.35px;
			margin:0;
			padding:15px 0 0 0;
			@media all and (max-width: 600px) {
				font-size: 24px;
			}
			@media all and (max-width: 600px) {
				font-size: 24px;
			}
		}
		img {
			@media all and (min-width: 600px) {
				max-width:250px;
			}
			@media all and (min-width: 1160px) {
				margin-left:20px;
				margin-top:-15px;
			}
			@media all and (max-width: 600px) {
				max-width: 100%;
			}
		}
	}

}

#pro-features-mobile {
	h3 {
		color: $color-primary
	}
}
.key-feature{
	margin: 1rem 0;
}

#why-go-mobile {
	
	.feature-group {
		@include flexbox;
		@include flex-direction(row);
		@include justify-content(center);
		max-width: 1140px;
		margin: 0 auto;
	}
	.feature-item {
		@include flexbox;
		@include flex(1);
		border:0;
		margin:0 1%;
		text-align:center;
		@include flex-direction(column);
		.feature-content {
			padding:0 25px;
			h3 {
				color: $color-primary;
				margin:0 0 15px 0;
				min-height:40px;
			}
			p {
				margin:0 0 10px 0;
				padding:0;
				font-size: 15px;
				line-height: 1.35;
				&.more {
					color: #36c;
				}
			}
		}
		.featureill {
			height:220px;
			padding:50px 15px;
			@include flexbox;
			@include align-items(center);
			@include justify-content(center);
			img {
				max-width:200px;
				max-height:180px;
			}
		}
	}
}

#getstartedwithbg {

	ol {
		li {
			font-style: italic;
		}
	}
	.wrap {
		max-width: 900px;
		margin-left: auto;
		margin-right: auto;
	}
	h2 {
		margin-bottom:3.5rem;
		@include media ("screen", "<desktop") {
			margin-bottom: 2.5rem;
		}
		@include media ("screen", "<=tablet") {
			margin-bottom: 2rem;
		}
	}
}

#footer {
	margin:0;
}

@media all and (max-width: 1160px) {

	.mobile-page-intro {
		.download-callout {
			width:100%;
			text-align: center;
			p {
				text-align: center;
				margin-bottom: 10px;
				margin-top:0;
				width: 100%;
				@include flex-direction(column);
			}
			a {
				clear: both;
				margin-top:10px;
				display:block;
			}
			img {
				clear: both;
				max-width: 250px;
				margin-left: 0;
				margin-top: 0;
				float:none;
			}
		}
	}

}
@media all and (max-width: 767px) {
	.mobile-page-image-wrapper {
		display:none;
	}
}
@media all and (max-width: 768px) {
	#why-go-mobile {
		.feature-group {
			@include flex-direction(column);
			max-width: 600px;
			margin-right: auto;
			margin-left: auto;
		}
		.feature-item{
			margin-bottom:25px;
		}
	}
}
