/******************************************************************
BatchGeo
Stylesheet: Header Styles - includes styles for the primary and
mobile nav. That includes the container styles that push
content when mobile nav is selected.

Update 2.0: Refactoring of the header stylesheet to standardize
the header element of both the Site and the Map
******************************************************************/


/******************************************************************
 General Header Styles & Utilities
******************************************************************/

$orange: #fb9b39;
$yellow: #ffd449;
$grey-light: #CBCBCB;
$grey-lighter: #CCCCCC;
$grey-even-lighter: #DEDEDE;
$grey-lightest: #909090;
$grey: $color-primary;

.login {
	position: relative;
	padding: 10px 16px 10px 13px;
	right: 0;
	border: 0 none;
	font-size: 13px;
	font-weight: 800;
	@include flexbox();
	@include align-items(center);
	&:link, &:visited {
		color: #979797;
		text-decoration: none;
	}
	&:hover {
		border: none;
	}
	img {
		height: 15px;
		width: 20px;
		margin-right: 4px;
	}
	&.nav-login {
		@include media('<=tablet') {
			display: none;
		}
	}
}

.toggle-icon-wrapper {
	position: relative;
	display: none;
	> a, > button {
		@include flexbox();
		@include align-items(center);
		margin: 0;
		padding: 13px 1rem;
		color: $grey-even-lighter;
		font-size: 45px;
		text-decoration: none;
		&:link,&:visited {
			color: $grey;
		}
		&.active {
			color: $blue;
		}
		&:hover {
			border: none;
			cursor: pointer;
		}
	}
	> button {
		color: $blue;
		border: none;
		background-color: inherit;
	}
	@include media('<=tablet') {
		@include flexbox();
		position: relative;
	}
}

.search-icon-wrapper {
	@extend .toggle-icon-wrapper;
	border: none;

	> a {
		padding: 13px 1rem 13px 0;
		&:link,&:visited {
			color: $grey-lightest;
		}

		&.active {
			color: $blue;
		}
	}
	@include media('<=tablet') {
		@include flexbox();
		position: relative;
	}
}

/******************************************************************
 Map ONLY Header Styles
******************************************************************/

.map-page-container {
	#utility {
		position: relative;
		white-space: nowrap;
	}
	.toggle-icon-wrapper {
		border-left: 1px solid $grey-lighter;
	}
	.search-icon-wrapper {
		border: none;
	}
}


/******************************************************************
 Site Header Styles
******************************************************************/

.site-page-container {
	.login {
		padding: 0 0 0 5px;

		span {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			max-width: 150px;
		}
	}
}

#header {
	padding: 1.1em;
	@include flexbox();
	@include justify-content(space-between);
	@include align-items(center);
	@media all and (max-width: 768px) {
		padding: .5em;
	}
	@media all and (min-width: 1240px) {
		padding-left: 0;
		padding-right: 0;
	}
	#header-inner {
		@include flexbox();
		@include justify-content(flex-end);
		@include align-items(center);
		@include media('<=tablet') {
			width: 100%;
		}

		@media all and (min-width: 769px) {
			flex-basis: 80%;
		}
	}
	nav {
		/* width: 100%; */
		/* background-color: $yellow; */
		display: block;
		/* overflow: hidden; */
		zoom: 1;
		text-align: center;
		position: relative;
		/* margin: 5px 0 21px 0; */
		/* @include css-gradient($nav-color, darken($nav-color, 12%)); */
		/* @include border-radius(3px); */
		ul {
			margin: 0;
			padding: 0 30px 0;
			display: block;
			list-style: none;
			@include flexbox();
			@include flex-direction(row);
			@include align-items(center);
			@include justify-content(space-between);
			@include media('>tablet', '<=1000px') {
				padding: 0 15px;
			}
		}
		li {
			display: block;
			margin: 0 .6rem;
		}
		li:last-of-type {
			margin-right: 0;
		}
		a, a:link {
			white-space: nowrap;
			border-bottom: none;
			display: block;
			font-weight: bold;
			line-height: 32px;
			font-size: 1.25rem;
			padding: 8px .35rem;
			text-decoration: none;
			/* text-transform: lowercase; */
			color: $black;
			margin: 0;
			position: relative;
			text-transform: capitalize;
		}
		a:visited {
			color: $black;
		}
		a:hover/*,
		a.current*/ {
			color: #333;
			border-bottom: none;
		}
		/* a.current {
			&:after {
				border-color: transparent transparent #fff;
				border-style: solid;
				border-width: 10px;
				bottom: -3px;
				content: '';
				height:0;
				left: 40%;
				position: absolute;
				width:0;
			}
		} */
		@include media('<=tablet') {
			display: none;
		}
	}

	.button {
		margin-bottom: 0;
		white-space: nowrap;
		padding: 15px 26px;
	}
}

#logo {
	position: relative;
	@include flexbox();
	@include align-items(center);
	@include justify-content(flex-start);
	a {
		&:hover { border: 0 none; }
		transform: translateY(3px);
		img {
			width: 100%;
			max-width: 100%;
			height: 56px;

			@include media('<=tablet') {
				height: 37px;
			}
		}
	}
}

/*These styles are a quick fix for the support section header*/

#company-support-portal{
	.support-header{
		width:30%;
	}
	#header {
		#header-inner{
			flex-direction:row;
			align-items: center;
			#logo{
				margin-top:-10px;
				max-width:300px;
				min-width: 180px;
			}
		}
		#nav{
			margin: 25px 0 5px 0;
		}
	}
}

@media all and (max-width: 1023px) {
	#company-support-portal {
		.support-header{
			width:40%;
		}
		#header {
			#header-inner {
				.sixcol.last{
					width: 100%;
					display:block;
				}
			}
		}
	}
}

@media all and (max-width: 768px) {
	#company-support-portal {
		.support-header{
			width:100%;
			text-align:center;
		}
		.support-header{
		}
		#header {
			#header-inner {
				flex-direction:column;

			}
		}
	}
}

.signup {
	margin-left: 1.3rem;
}
@media all and (min-width: 900px) and (max-width: 1100px) {
	#header nav a, #header nav a:link {
		font-size: 1rem;
	}
	#header .button {
		font-size: 1rem;
		padding: 11px 16px;
	}
	.signup {
    margin-left: .5rem;
  }
}
@media all and (min-width: 769px) and (max-width: 899px) {
	#header nav a, #header nav a:link {
		font-size: 1rem;
	}
	#header .button {
		font-size: 1rem;
		padding: 8px 10px;
	}
	.signup {
    margin-left: .3rem;
	}
	#header nav li {
    margin: 0;
	}
}
@media all and (max-width: 768px) {
	#logo {
		flex-basis: 100%;

		a {
			width: 50%;
			display: block;
			margin: 0 auto;
		}
	}
	#header {
		@include justify-content(center);

		#header-inner {
			position: absolute;
			left: 5%;
			width: 3rem;
	
			.pro-signin,
			.signup {
				display: none;
			}
		} 
	}

}
